import { GoalStatus } from "@shared/enums/shared.enums";

//English
export const en = {
  Variables: 'Variables',
  Functions: 'Functions',
  pages: {
    overview: 'Overview',
    adsManager: 'Ads Manager',
    kai: 'Keyword Analysis',
    keyOpt: 'Keyword Optimization',
    teamMgmt: 'Team',
    integrations: 'Integrations',
    automationRule: 'Automation Studio',
    campaignGenerator: 'Campaign Generator',
    goals: 'Goals',
    appSettings: 'App Settings',
    kar: 'Keyword analysis requests',
    manualCampaign:'Add manual campaign',
    automatedCampaign:'Add automated campaign',
    support:'Support',
    strategies: 'Strategies',
  },
  menu: {
    settings: 'Settings',
    signOut: 'Sign Out',
    manageSubscription: 'Manage Subscription',
    orgs: 'Orgs'
  },
  buttons: {
    syncData: 'Sync Latest Data',
    createCampaign: 'Create Campaign',
    filter: 'Filter',
    reset: 'Reset',
    apply: 'Apply',
  },
  labels: {
    impShare: "Impression Share",
    charts: 'Charts',
    selectDs: 'Select Datasets',
    totalSummary: 'Total Summary',
    spend: 'Spend',
    search: 'Search',
    searchByKeySearchMsg: 'Type a text and press Enter',
    competeSearchMsg : 'Select or search for an app',
    selectCategoryMsg: 'Select a category',
    selectCategoryType: 'Select type',
    matchType: 'Match Type',
    filterItem: 'Type here to filter below items',
    cusType: 'Customer Types',
    createRule: 'Create Rule For',
    fromSelected: 'From Selected',
    mmYY: 'MM/YYYY',
    enterKey: 'Enter Keywords',
    selectLevel: 'Select Level',
    org: 'Organization(s)',
    app: 'Application(s)',
    camp: 'Campaign(s)',
    adGroup: 'Ad Group(s)',
    key: 'Keyword(s)',
    searchTerm: 'Search Term(s)',
    yourApps: 'Your Apps',
    suggestedApps: 'Suggested Apps',

  },
  dropdowns: {
    noData: 'No data available',
    daily: 'DAILY',
    weekly: 'WEEKLY',
    monthly: 'MONTHLY',
  },
  tabs: {
    Strategies:'Strategies',
    accounts: 'Accounts',
    apps: 'Apps',
    campaigns: 'Campaigns',
    adgroups: 'Ad Groups',
    keywords: 'Keywords',
    creativeSets: 'Creative Sets',
    searchTerms: 'Search Terms',
    negKeywords: 'Negative Keywords',
    ad: 'Ad',
  },
  summary: {
    taps: 'Taps',
    impressions: 'Impressions',
    ttr: 'TTR',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    installs: 'Installs',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  accountHeaders: {
    account: 'Account',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  appsHeaders: {
    appName: 'Application',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstall: 'Attr Install',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRevenue: 'Goal Revenue',
    goalsRoas: 'Goal ROAS',
    goalEventCount: 'Goal Event Count',
    goalEventCountSec: 'Secondary Goal Event Count',
    costPerGoalSec: 'Secondary Cost Per Goal',
    goalArpu: 'Goal ARPU',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    account: 'Account',
    categoryRank: 'Category Rank',
    appId: 'App ID',
    totalCamp: 'Total Campaigns',
    goalAMPU: 'Goal AMPU',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  campaignHeaders: {
    campaign: 'Campaign',
    campaignStatus: 'Campaign Status',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRoas: 'Goals ROAS',
    storefronts: 'Storefronts',
    account: 'Account',
    displayStatus: 'Display Status',
    appName: 'App Name',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    delete: 'Delete',
    totalBudget: 'Total Budget',
    dailyCap: 'Daily Cap',
    supplySource:'Type',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  campaignDraftHeaders: {
    campaignName: 'Campaign Name',
    countries: 'Countries',
    budgetAmount: 'Budget Amount',
    resume: 'Resume',
  },
  adGroupHeaders: {
    adgroup: 'Ad Group',
    adgroupStatus: 'Ad Group Status',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    delete: 'Delete',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRoas: 'Goals ROAS',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    displayStatus: 'Display Status',
    account: 'Account',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Ad Group ID',
    campaign: 'Campaign',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  keyHeaders: {
    keyword: 'Keyword',
    keywordStatus: 'Keyword Status',
    bidAmount: 'Bid Amount',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    delete: 'Delete',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRevenue: 'Goal Revenue',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    adgroupStatus: 'Ad Group Status',
    organicRank: 'Organic Rank',
    appName: 'App Name',
    difficultyScore: 'Difficulty Score',
    popularity: 'Popularity',
    keywordId: 'Keyword Id',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Ad Group ID',
    suggestedMinBid: 'ASA Suggested Min Bid',
    suggestedMaxBid: 'ASA Suggested Max Bid',
    suggestedBidAmount: 'ASA Suggested Bid',
    bidStrength: 'Bid Strength',
    latOff: 'LAT Off Installs',
    latOn: 'LAT On Installs',
    campaign: 'Campaign',
    adgroup: 'Ad Group',
    matchType: 'Match Type',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  adsHeaders: {
    ads: 'Ad',
    creativeType: 'Creative Type',
    creativeSetStatus: 'Status',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    action:'Action',
    delete: 'Delete',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRevenue: 'Goal Revenue',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    adgroupStatus: 'Ad Group Status',
    account: 'Account',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Ad Group ID',
    campaign: 'Campaign',
    adgroup: 'Ad Group',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  creativeHeaders: {
    creativeSet: 'Creative Set',
    creativeSetStatus: 'Status',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    attrInstallRate: 'Attr Install Rate',
    cpi: 'CPI',
    goals: 'Goals',
    costPerGoal: 'Cost Per Goal',
    goalRate: 'Goal Rate',
    revenuePerGoal: 'Revenue Per Goal',
    goalsRevenue: 'Goal Revenue',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    adgroupStatus: 'Ad Group Status',
    account: 'Account',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Ad Group ID',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  searchTermsHeader: {
    searchTerm: 'Search Term Text',
    spend: 'Spend',
    averageCpa: 'Average CPA',
    averageCpt: 'Average CPT',
    impressions: 'Impressions',
    taps: 'Taps',
    installs: 'Installs',
    ttr: 'TTR',
    conversionRate: 'Conversion Rate',
    newDownloads: 'New Downloads',
    reDownloads: 'Re-Downloads',
    storefronts: 'Storefronts',
    campaignStatus: 'Campaign Status',
    adgroupStatus: 'Ad Group Status',
    keyword: 'Keyword',
    keywordId: 'Keyword Id',
    appId: 'App ID',
    campaignId: 'Campaign ID',
    adgroupId: 'Ad Group ID',
    campaign: 'Campaign',
    adgroup: 'Ad Group',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },
  negativeHeaders: {
    keyword: 'Keyword',
    matchType: 'Match Type',
    status: 'Status',
    adgroupId: 'Ad Group ID',
    campaignId: 'Campaign ID',
    deleted: 'Deleted',
    keywordId: 'Keyword Id',
    modificationTime: 'Modification Time',
    campaign: 'Campaign',
    adgroup: 'Ad Group',
  },
  filter: {
    account: 'Select Account',
    app: 'Select App',
    goal: 'Select Goal',
    secGoal: 'Select Secondary Goal',
    campaign: 'Select Campaign',
    adgroup: 'Select Ad Group',
    ad: 'Select Ad'
  },
  subTabs: {
    all:'All',
    live: 'Live',
    paused: 'Paused',
    deleted: 'Deleted',
    draft: 'Draft',
    campaigns: 'Campaigns',
    adgroups: 'Ad Groups',
    keywords: 'Keywords',
    creativeSet: 'Creative Set',
    negKey: 'Negative Keywords',
  },
  popups: {
    add: 'Add',
    integration: 'Integration',
    adgroup: 'Ad Group',
    keywords: 'Keywords',
    creativeSet: 'Creative Set',
    negKey: 'Negative Keywords',
    campaigns:'Campaigns'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: 'Manage Members',
    invite: 'Invite and Manage Team Members',
    add: 'Add Member',
    first: 'First Name',
    last: 'Last Name',
    phone: 'Phone',
    email: 'Email',
    job: 'Job Title',
    allMembers: 'All Members',
    processing: 'Processing...',
    ok: 'Ok',
    inviteStatus: 'Invite Status',
    isDisabled: 'Enable/Disable',
    company: 'Company Name',
    sync: 'Sync',
    role: 'Role',
  },

  //Add Integration Page
  integration: {
    assPart: 'Associated Partners',
    apple: 'Apple Search Ads',
    slack: 'Slack',
    itunes: 'Itunes',
    tenjin: 'Tenjin',
    kochava: 'Kochava',
    adjust: 'Adjust',
    appsFlyer: 'AppsFlyer',
    singular: 'Singular',
    communication:'Communications',
    mmp:'Mobile Measurement Partners',
    completeInt:'Completed Integrations',
    receivedEventType:'Received Event Types',
    waitForEvents:'Waiting For Events',
    integrateWith:'Integrate With',
    intStep:'Integration Steps',
    integrate:'Integrate',
    followIntSteps:'Follow these integration steps',
    //kochava steps
    kochavaStep1:'1.  Access your Kochava apps at https://go.kochava.com/apps.',
    kochavaStep2:'2.  Select Partner Configuration from your app\'s context menu.',
    kochavaStep3:'3.  Click Add a Configuration from the Partner Configuration page.',
    kochavaStep4:'4.  Choose Search Ads Maven - iOS from the Media Partner menu and click Go.',
    kochavaStep5:'5.  Choose Create from each event\'s context menu that you wish to syndicate to Search Ads Maven.',
    kochavaStep6:'6.  Use the API KEY and App Store ID provided here for each postback creation.',
    kochavaStep7:'7.  Allow at least 24 hours for events to be recognized by Search Ads Maven. Note that only events attributed to Apple Search Ads campaigns are recognized.',
    kochavaStep8:'8.  Create a Goal for each event recognized by Search Ads Maven. Once goals have been created, MMP event data such as counts and revenue will be available in the dashboard.',

    //branchSteps
    branchStep:'1. Use the following API key and App Store ID within your Branch dashboard.',

    //customSteps
    customStep:'1. Use the following API key and App Store ID within your postback.',

    apiKey:'API key',
    appStoreId:'App Store ID',
    distinctEventType:'distinct event types attributed to ASA have been delivered by an MMP for this app. These event types can be used in Goal creation.',
    noDistinctEventType:'No events attributed to Apple Search Ads have yet been received. If events have not been received after 24 hours, please double check these integration steps and ensure active Apple Search Ads campaigns are running which would trigger one or more events to be delivered by the MMP.',
    dEventType:'Distinct Event Types',
    noEventFound:'No events found',
    close:'Close',
    note:'NOTE: Apps already integrated with another MMP will not appear in this list.',
    intDeletionInProgress:"Integration deletion is currently in progress, and we'll notify you upon its completion."
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: 'Added Keywords',
    total: 'Total',
    cancel: 'Cancel',
    runAds: 'Run Ads',
    ok: 'Ok',
    totalPaidKey: 'Total Keywords',
    popularity: 'Popularity',
    numOfApps: 'Number of Apps',
    chance: 'Chance',
    appsRunAds: 'Apps Running Ads',
    maxPop: 'Max popularity for yours',
    keyword: 'Keyword',
    clear: 'Clear',
    // clrAllFilter: 'Clear All Filter',
    clrAllFilter: 'Clear All',
    keywords: 'Keywords',
    organicRank: 'Organic Rank',
    totalApps: 'Total Apps',
    app: 'App',
    catName: 'Category Name',
    searchByApp: 'Search by App',
    searchByKey: 'Search by Keyword',
    // TODO also in other languages, remove todo when done
    addAllKey: 'Quick Add Keywords',
    searchForCompetes: 'Suggested Competitors',
    searchAdsRecommends: 'Search Ads Recommendations',
    categoryTop: 'Category Top Apps ',
    compRating: 'Rating',
    compVersion: 'Version',
    compPrice: 'Price',
    compGenres: 'Category Name',
    monthlyDownloads: 'Monthly Downloads',
    free: 'Free', //new
    paid: 'Paid',// new
    country: 'Country',
    lang: 'Language',
    showDataText: 'No data to show',
    quickAddCriteria: 'Quick Add Criteria',
    tooltip: {
      keyText: "Keyword Title",
      organicRank: "Organic Rank refers to the search results of a search engine that cannot be influenced by paid advertising.",
      popularity: "The keyword popularity score of the keyword. A higher search score means a higher search volume on the keyword.",
      totalApps: "Total number of apps using this keyword",
      appDetails: "Application Name",
      organicRankApp: "Organic Rank refers to the search results of a search engine that cannot be influenced by paid advertising.",
      category: "The categories to which the app belongs",
      rating: "Rating of App",
      version: "Version of App",
      price: "Price of App",
      genres: "The categories to which the app belongs",
      chance: "Chance Score is a percentage score based on estimate of your chances of being in the top ten for a particular keyword in your app's specific category",
      appsRunning: "Total number of apps paying for the keyword"
    }

  },

  //Overview Page
  overview: {
    selectGoalFirstSelectApp:'To select a goal, first select an app in the drop down to the left.',
    exportCSV:'Export CSV',
    count:'Goal Event Count',
    period: 'period',
    topKeywords: 'Top 10 Keywords By',
    key: 'Keyword',
    spend: 'Spend',
    impressions: 'Impressions',
    taps: 'Taps',
    goToKey: 'Go to All Keywords',
    topAdgroup: 'Top 10 Ad Groups By',
    adgroup: 'Ad Group',
    goToAdgroup: 'Go to All Ad Groups',
    gender: 'Gender',
    device: 'Device',
    searchApp: 'Search App',
    lastDay: 'Last Day',
    last7Days: 'Last 7 Days',
    last30Days: 'Last 30 Days',
    ttr: 'Tap-through rate (TTR)',
    installs: 'Installs',
    avgCPA: 'Average CPA',
    avgCPT: 'Average CPT',
    conversionRate: 'Conversion Rate (CR)',
    latOnInstalls: 'LAT On Installs',
    latOffInstalls: 'LAT Off Installs',
    reDownloads: 'Re-Downloads',
    newDownloads: 'New Downloads',
    all: 'All',
    tooltip:
      'The increase or decrease over time as a percentage from the previous period based on the selected date range.',
    revenue: 'Revenue',

    ATTI: 'Attributed Installs',
    ATIR: 'Attributed Install Rate',
    ATLI: 'Attributed + LAT on Installs',
    CPRI: 'Cost Per Install',
    GOLS: 'Goals',
    CPRG: 'Cost Per Goal',
    RPRG: 'Revenue Per Goal',
    GREV: 'Goal Revenue',
    GRAS: 'Goal ROAS',
    GETC: 'Goal Event Count',
    ACNV: 'All Conversions',
    CPRC: 'Cost Per Conversion',
    ACNR: 'All Conversion Rate',
    RPRC: 'Revenue Per Conversion',
    ARPU: 'ARPU',
    RVNU: 'Revenue',
    ROAS: 'ROAS',
    AMPU: 'AMPU',

    roi: 'ROI',
    rpi: 'RPI',
    events: 'Events Count',
    clicks: 'Clicks Count',
    unAttrInstalls: 'UnAttributed Installs',
    prevPeriod: 'Prev.Period',
    currPeriod: 'Curr.Period',
    ipad: 'IPAD',
    iphone: 'IPHONE',
    unknown: 'UNKNOWN',
    selectPer: 'Select period',
    tapInstalls: 'Installs (Tap-Through)',
    viewInstalls: 'Installs (View-Through)',
    totalInstalls: 'Installs (Total)',
    tapNewDownloads: 'New Downloads (Tap-Through)',
    viewNewDownloads: 'New Downloads (View-Through)',
    totalNewDownloads: 'New Downloads (Total)',
    tapRedownloads: 'Redownloads (Tap-Through)',
    viewRedownloads: 'Redownloads (View-Through)',
    totalRedownloads: 'Redownloads (Total)',
    tapInstallRate: 'Conversion Rate (Tap-Through)',
    totalInstallRate: 'Conversion Rate (Total)',
    tapInstallCPI: 'Avg CPA (Tap-Through)',
    totalAvgCPI: 'Avg CPA (Total)'
  },

  checklist: {
    title: 'Checklist',
    integrations: 'Integrations',
    searchads: 'Apple Search Ads',
    slack: 'Add Slack Integration',
    kai: 'Become familiar with Keyword Analysis Tools',
    competitorMsg: 'See how your competitors are doing.',
    searchKeywords: 'See Keywords for an App.',
    seeApps: 'See Bidding Apps for a keyword.',
    topCharts: 'See category top charts.',
    campaign: 'Campaign',
    initCampaign: 'Initiate a Campaign.',
    createAdgroup: 'Create Adgroup.',
    addKeywords: 'Add keywords.',
    addCreativeSet: 'Add Creative Set.',
    goLive: 'Go Live.',
    reviewMetrics: 'Review campaign success.',
    automate: 'Automate your campaign.',
    mmpIntegration: 'MMP Integration',
    createMmpGoals: 'Create MMP Goals',
    campGen:'Campaign Generator',
    scheduledReports:'Scheduled Reports'
  },

  //Keyword Optimization Page
  keyOpt: {
    keyBid: 'Keyword Optimization',
    keyToolTip:
      'You can optimize bid amount of the keywords which are not in range of ASA suggested min bid amount and ASA suggested max bid amount.',
    optAll: 'Optimize All',
    opt: 'Optimize',
    optBidAmt: 'Optimize Bid Amount',
    key: 'Keyword',
    optBidForAll: 'Optimize Bid Amount of All Keywords',
    campaign: 'Campaign',
    adgroup: 'Ad Group',
    bidAmt: 'Bid Amount',
    asaMinBid: 'ASA Suggested Min Bid',
    asaMaxBid: 'ASA Suggested Max Bid',
    asaSuggestedBidAmount: 'ASA Suggested Bid',
    action: 'Action',
    noData: 'Apple does not have any suggestions at this time.'
  },

  //ARRules Page
  aRules: {
    createAR: 'Create Automation Rule',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    disable: 'Disable',
    enable: 'Enable',
    name: 'Name',
    createdAt: 'Created At',
    status: 'Status',
    completed: 'Completed At',
    actionFreq: 'Action Frequency',
    rules: 'Rules',
    logs: 'Logs',
    createdFor: 'Created for',
    chckFreq: 'Check Frequency',
    actions: 'Actions',
    enableDisable: 'Enable/Disable',
    sno: 'S.No',
    scriptedRules: 'Automated Rules',
    analytics: 'Analytics',
    scriptedLogs: 'Scripted Logs',
    deleteSAR: 'Are you sure you want to delete this scripted automation rule?',
    disableSAR: 'Are you sure you want to disable this scripted automation rule?',
    enableSAR: 'Are you sure you want to enable this scripted automation rule?',
    saveSAR: 'Are you sure you want to save this scripted automation rule?',
    updateSAR: 'Are you sure you want to update this scripted automation rule?',
    lastRun: 'Last Run'
  },

  //Create Campaign
  //CampaignView
  createCampaign: {
    createCamp: 'Create Campaign',
    h2: 'Choose an app to start creating campaign',
    para:
      'Campaigns have budgets and Ad Groups that focus on a common theme or strategy for promoting your app.',
    select: 'Select where ads will run',
    searchResults: 'Search Results',
    srData:
      'Campaigns have budgets and Ad Groups that focus on a common theme or strategy for promoting your app.',
    searchTab: 'Search Tab',
    stData:
      'Promote your app at the top of the suggested apps list when users visit the Search tab.',
    todayTab: 'Today Tab',
    todayData:'Reach users when they first come to the App Store to discover apps. Your ad will appear prominently on the front page.',

    productPageTab: 'Product Pages',
    productPageData: 'Reach users when they are browsing apps. Your ad will appear at the top. You might also like to list to users who have scrolled to the bottom of relevant product pages.',
    cancel: 'Cancel',
    continue: 'Continue',

    //Search Results and Search Tab Campaign
    countAndReg: 'Countries and Regions',
    adgroupSettings: 'Ad Group Settings',
    searchMatch: 'Search Match',
    key: 'Keyword',
    audience: 'Audience',
    crSets: 'Creative Sets',
    chooseCampGroup: 'Choose a Campaign Group',
    chooseApp: 'Choose an App',
    next: 'Next',
    countPara:
      'Your app will be promoted on the App Store in the eligible countries and regions you choose from the list.',
    chooseCount: 'Choose one or more countries or regions',
    campName: 'Campaign Name',
    budget: 'Budget',
    dailyCap: 'Daily Cap',
    adgroupPara: 'Ad Groups contain pricing goals and settings that determine who to show your ad to.',
    adgroupName: 'Ad Group Name',
    devices: 'Devices',
    startTime: 'Start Time',
    adScheduling: 'Ad Scheduling',
    endTime: 'End Time',
    dayParting: 'Dayparting (Optional)',
    ok: 'OK',
    defaultMax: 'Default Max CPT Bid',
    cpaGoal: 'CPA Goal(Optional)',
    saveDraft: 'Save as draft',
    appleSearchMatch: 'Apple Search Match',
    searchMatchPara:
      'Apple Search Match is the easiest way to get your ads up and running. Search Ads will automatically match your ad to users who are searching for apps like yours.',
    searchMatchPara1: 'Automatically match my ad to relevant searches',
    keywords: 'Keywords',
    keyPara:
      'Keywords are relevant words or terms customers may use when searching for an app like yours. Keywords allow searchers to quickly find your app, helping to drive app installs.',
    upload: 'Upload',
    suppFormat: 'Supported formats: Excel, CSV',
    keyManually: 'Or add keywords manually',
    targKey: 'Targeting Keywords',
    clear: 'Clear',
    adgroupNegKey: 'Ad Group Negative Keywords',
    optional: 'Optional',
    negKey: 'Negative Keywords',
    audPara:
      ' Refine the audience for your Ad Group using optional advanced features.',
    custType: 'Customer Type',
    demo: 'Demographics',
    gender: 'Gender',
    location: 'Locations (Search)',
    crSetPara:
      'Ads are automatically created using the metadata, screenshots and app previews provided on the App Store product page. These also appear in organic search results on the App Store. The ads shown here are default ad examples.',
    crOptional: 'Creative Set (Optional)',
    noCrSet: 'App does not fulfill the requirement of minimum Ad',
    campCreated: 'Campaign Created',
    campCreatedPara: 'Now, you can see your campaigns on Search Ads dashboard',
    close: 'Close',
    matchType: 'Match Type',
    amount: 'Amount',
    currency: 'Currency',
    allUsers: 'All users',
    newUsers: 'New Users',
    appUsers: 'Users of my other apps',
    existingUsers: 'Existing users',
    all: 'All',
    male: 'Male',
    female: 'Female',
    chooseCamp: 'Campaign Group',
    selectApp: 'Search app',
    countries: 'Countries',
    ageRange: 'Age Range',
    selectDev: 'Select Devices',
    appRules: 'Please select an app!',
    adsOpt: 'Ad (Optional)',
    default: 'Default',
    defaultAdPara: 'Uses assets from your default App store product page.',
    custom: 'Custom',
    customPara: 'Uses assets from your default App Store Connect and takes users to that page when they tap the ad.',
    pagesInfo: 'Custom Product pages are available',
    createAdPara: 'Click the Create Ad button to select from your custom product page options and see a preview of your ad',
    createAdBtn: 'Create Ad',
    createAds: 'Create Ads',
    selectCustomPara: 'Select a custom product page to have its assets used to create your ad. When users tap your ad in search results, they’ll be taken to the page you select.',
    adName: 'Ad name',
    search: 'Search',
    available: 'Available',
    sortBy: 'Sort By',
    save: 'Save',
    customAds: 'Custom Ads',
    edit: 'Edit',
    previous: 'Previous'
  },

  //Change Password
  changePass: {
    backSett: 'Back To Settings',
    chngPass: 'Setup Password',
    newPass: 'New Password',
    confirmPass: 'Confirm Password',
    submit: 'Submit',
    h4: 'The Kochava Marketers Operating System™',
    para:
      'The Marketers Operating System (m/OS) seamlessly integrates omni-channel marketing solutions for advertisers and publishers in one operational platform.',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Forgot Password',
    typeEmail: 'Type the Email Address',
    emailAdd: 'Email Address',
    submit: 'Submit',
    chckEmail: 'Please Check Your Email',
    para: 'To set a new password please check your email',
    close: 'Close',
    alreadyAcc: 'Already have an account?',
    signIn: 'Sign In',
  },

  //Onboard
  onboard: {
    welcome: 'Welcome to Search Ads Maven',
    personal: 'Sign Up',
    start: 'Start',
    integration: 'Integration',
    next: 'Next',
    trial: 'Start Your Trial',
    scheduleDemo: 'Or schedule demo with us and discover together',
    addInt: 'Add an integration.',
    signInApple:
      'Sign in with Apple to add an Apple Search Ads integration. Please note:',
    step1:
      '1. It is required to have admin rights to grant access to Apple Search Ads account.',
    step2:
      '2. In order to manage your campaigns from SearchAdsNinja, please select Read & Write option before granting access.',
    skip: 'Skip',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Add Ad Group',
    para:
      'You have not selected your campaign yet. You can choose it from below box.',
    camGroup: 'Campaign Group',
    campaign: 'Campaign',
    adgroupSett: 'Ad Group Settings',
    para1:
      'Ad Groups contain pricing goals and settings that determine who to show your ad to.',
    adgroupName: 'Ad Group Name',
    devices: 'Devices',
    adScheduling: 'Ad Scheduling',
    startTime: 'Start Time',
    endTime: 'End Time',
    dayparting: 'Dayparting (Optional)',
    defaultMax: 'Default Max CPT Bid',
    cptTool:
      'This is the maximum amount you willing to pay for a tap on your add.This bid will apply to all keywords in this Ad Group unless you apply an individual keyword bid.',
    cpaGoal: 'CPA Goal(Optional)',
    cpaTool:
      'The average you have paid for each download. This is calculated by dividing the total spent to the number of downloads.',
    searchMatch: 'Search Match',
    searchTool:
      'Search Match is the easiest way to get your ads up and running. Search Ads will automatically match your ad to users who are searching for apps like yours.',
    searchPara: 'Automatically match my ad to relevant searches',
    key: 'Keywords',
    keyPara:
      'Keywords are relevant words or terms customers may use when searching for an app like yours. Keywords allow searchers to quickly find your app, helping to drive app installs.',
    inputCpt: 'Input Default Max CPT Bid to activate keywords section',
    addKeyPara:
      'Add keywords relevant to your app and genre. We’ll help by sharing additional recommendations.',
    upload: 'Upload',
    addKeyManually: 'Or add keywords manually',
    negKey: 'Ad Group Negative Keywords(Optional)',
    supp: 'supported formats: Excel, CSV',
    demAge: 'Demographics Age, Gender',
    demPara: "Target your ad based upon your user's age or gender",
    ageRange: 'Age Range',
    cancel: 'Cancel',
    apply: 'Apply',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Edit Ad Group',
    adgroup: 'Ad Group',
    advance: 'Advance Settings',
    viewEdit: 'View and Edit',
    general: 'General',
    settings: 'Settings',
    schedule: 'Schedule',
    custPara: 'Select the app customers you want to reach',
    selectAge: 'Select Age Range',
    save: 'Save',
  },

  //Create AR
  createAR: {
    rule:'Rules',
    addRule: 'Add Rule',
    selectDateRange:'Select Date Range',
    createAutoRule: 'Create Standard Automation Rule',
    editAutoRule: 'Edit Automation Rule',
    choose: 'Choose',
    chooseAutoRule: 'Please choose automation level and items.',
    conditions: 'Conditions',
    actions: 'Actions',
    settings: 'Settings',
    condition: 'Condition',
    addCondition: 'Add Condition',
    andCondition:
      'Choose whether all above conditions must match or only one of them should match',
    checkCond: 'Check conditions for',
    action: 'Action',
    selectAll: 'Select All',
    enterEmail: 'Please enter proper email ids',
    addTo: 'Add To',
    selectAdgroups: 'Select Ad Groups',
    selectCampaigns: 'Select Campaigns',
    cptBid: 'CPT Bid',
    customBid: 'Custom Bid',
    matchType: 'Match Type',
    addAction: 'Add Action',
    chckFreq: 'Check Frequency',
    ruleName: 'Rule Name',
    cancel: 'Cancel',
    step2: 'Step 2',
    step3: 'Step 3',
    andOrTypesText1: 'All must match',
    andOrTypesText2: 'Only one should match',
    addToModelListNegText1: 'All Selected Ad Group(s)',
    addToModelListNegText2: 'All Ad Groups of Selected Campaign(s)',
    addToModelListNegText3: 'All Selected Campaign(s)',
    matchTypesText1: 'Broad Match',
    matchTypesText2: 'Exact Match',
    cptBidItemsText1: 'Set To Custom',
    cptBidItemsText2: 'Ad Group Default Bid',
    actionFreqsText1: 'Hourly',
    actionFreqsText4hrs: 'Every 4 hours',
    actionFreqsText2: 'Every 6 hours',
    actionFreqsText8hrs: 'Every 8 hours',
    actionFreqsText3: 'Every 12 hours',
    actionFreqsText4: 'Every day',
    actionFreqsText5: 'Custom hours',
    limitActionFreqsText1: 'Every 2 hours',
    limitActionFreqsText2: 'Every 3 hours',
    limitActionFreqsText3: 'Every 2 days',
    limitActionFreqsText4: 'Every 3 days',
    limitActionFreqsText5: 'Every week',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
    ciTimeRangesText1: 'Last 1 hour',
    ciTimeRangesText2: 'Last 1 day',
    ciTimeRangesText3: 'Last 7 days',
    ciTimeRangesText4: 'Last 30 days',
    ciTimeRangesText5: 'Last month',
    ciTimeRangesText6: 'Custom hour',
    ciTimeRangesText7: 'Date Range',
    allConOperationText1: 'Greater Than',
    allConOperationText2: 'Less Than',
    allConOperationText3: 'Range',
    allConOperationText4: 'Not In Range',
    amount: '$ Amount',
    budget: '% Percent of Budget',
    incBy: 'Increase by',
    decBy: 'Decrease by',
    setToCPA: 'Set to Average CPA',
    setToCPT: 'Set to Average CPT',
    value: '$ Value',
    change: '% Change',
    between: 'Between',
    equalTo: 'Equals to',
    appsSelected:'Apps selected',
    campaignsSelected:'Campaigns selected',
    adgroupsSelected:'AdGroups selected',
    KeywordsSelected:'Keywords selected',
    selectField:'Select Field',
    selectOne:'Select One',
    pleaseSelect:'Please Select',
    pleaseEnteraValue:'Please enter a value',
    fromValue:'From Value',
    toValue:'To Value',
    mustBeLesserThanToValue:'Must be lesser than To value',
    mustBeHigherThanFromValue:'Must be higher than from value',
    cannotBeZero:'Cannot be 0',
    ruleAlreadyExist:'Rule name already exists',
    emailNotification:'Email Notification',
    slackNotification: 'Slack Notification',
    pause:'Pause',
    resume:'Resume',
    changeDailyCap:'Change Daily Cap',
    changeBid:'Change Bid',
    changeBudget:'Change Budget',
    addAsNegativeKeyword:'Add As Negative Keyword',
    createActionFor: 'Create Action For',
    selectAction:'Select Action',
    selectEmail:'Select Email',
    addOtherEmail:'Add Other Email(s)',
    enterAnotherEmail:'Enter an Email Id and press enter to add more',
    giveAName:'Give a name for this rule',
    searchLogs: 'Filter the Logs by Rule',
    details: 'Details',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    CREATED: 'CREATED',
    DELETED: 'DELETED',
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    REACTIVATED: 'RE ACTIVATED',
    UPDATED: 'UPDATED',
    CONDITIONMATCHED : 'CONDITION MATCHED',
    CONDITIONNOTMATCHED:'CONDITION NOT MATCHED',
    ASACONDITIONNOTMATCHED:'ASA CONDITION NOT MATCHED',
    MMPCONDITIONNOTMATCHED:'MMP CONDITION NOT MATCHED',
    NOCAMPAIGNSFOUND:'NO CAMPAIGNS FOUND TO PROCESS',
    RUNFAILED: 'LAST RUN FAILED',
    runIndefinite:'Run Indefinitely',
    indefiniteNote:'NOTE: When selected, this automation rule will continue to be applied at the specified interval and will remain in effect regardless of how many times it is triggered.',
    sureToSave:'Are you Sure, You want to save the Automation rule?',
    update:'Update',
    selectChannel:'Select Channel',
    noDataAvailable:'No Data Available',
    sureToDelete:'You want to delete the Automation rule?',
    sharedNone:'None',
    sharedKeywordMatch:'Keyword Matches Term Exactly',
    sharedVoiceLabel:'Share of Voice Attribution',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Choose Account',
    cancel: 'Cancel',
    apply: 'Apply',
    clearAll: 'Clear All',
    accounts: 'Accounts',
    accountSelected: 'Account(s) selected',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'Choose App',
    cancel: 'Cancel',
    apply: 'Apply',
    apps: 'Apps',
    appSelected: 'App(s) selected',
    clearAll: 'Clear All',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: 'Please keep only one Ad-Group selected to continue.',
    close: 'Close',
    chooseKeywords: 'Choose Keywords',
    cancel: 'Cancel',
    apply: 'Apply',
    campaigns: 'Campaigns',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    adGroups: 'Ad Groups',
    keywords: 'Keywords',
    keywordSelected: 'keyword(s) selected',
    clearAll: 'Clear All',
    excludePausedKeywords:'Exclude Paused Keywords',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Add Creative Set',
    setup: 'Setup',
    para:
      'Choose a name that describes the theme of your Creative Set. Select the language of the screenshots and previews you’d like to include from your App Store product page.',
    createSetName: 'Creative Set Name ',
    localLang: 'Localization Language',
    assetSelection: 'Asset Selection',
    assetSelectionPararaph1:
      'Creative Sets consist of screenshots and app previews that appear on the App Store product page.',
    assetSelectionPararaph2: 'For each iPhone display size,',
    assetSelectionPararaph3:
      'select at least one landscape or three portrait assets.',
    assetSelectionPararaph4: 'For each iPad display size,',
    assetSelectionPararaph5:
      'select at least one landscape or two portrait assets.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Please select at least',
    screenshotsPara2: 'one landscape',
    screenshotsPara3: 'or',
    screenshotsPara4: 'two portrait',
    screenshotsPara5: 'screenshots!',
    screenshotsPara6: 'Please select at least',
    screenshotsPara7: 'one landscape',
    screenshotsPara8: 'or',
    screenshotsPara9: 'three portrait',
    screenshotsPara10: 'screenshots!',
    cancel: 'Cancel',
    save: 'Save',
  },
  strategy:{
    descriptionValid:"Description is required",
    textMax:"Description should not exceed 250 characters"
  },
   //DeleteStrategy
   deleteStrategy: {
    deleteCamp: 'Delete stategy',
    deleteTimeline: 'Delete Timeline',
    timeline:'Are you sure you want to delete the timeline?',
    para: 'Are you sure you want to delete the stategy?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Delete campaign',
    para: 'Are you sure you want to delete the campaign?',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  //Delete Ad
  deleteAds: {
    deleteAd: 'Delete ad',
    para: 'Are you sure you want to delete the Ad?',
    cancel: 'Cancel',
    delete: 'Delete',
    ok:'OK'
  },

  switchAccount: {
    title: 'Switch Account',
    msg: 'Are you sure you want to login into this user account?',
    cancel: 'Cancel',
    delete: 'Login',
    ok:'OK',
    switching: 'Switching user...'
  },

  deleteAdgroup: {
    deleteCamp: 'Delete Ad Group',
    para: 'Are you sure you want to delete the ad group?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  deleteKeyword: {
    deleteCamp: 'Delete Keyword',
    para: 'Are you sure you want to delete the keyword?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Campaign Edit Wizard',
    campName: 'Campaign Name',
    app: 'App',
    countriesOrRegionsPara: 'Choose one or more countries or regions',
    budget: 'Budget',
    dailyCap: 'Daily Cap',
    cancel: 'Cancel',
    save: 'Save',
  },
   //EditAds
   editAd: {
    editAdWizard: 'Ad Edit Wizard',
    adName: 'Ad Name',
    cancel: 'Cancel',
    save: 'Save',
  },
  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Choose Campaign',
    noSelectedCamp: 'No Selected Camapign',
    campaigns: 'Campaigns',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    selectedCampaigns: 'Selected Campaigns',
    clearAll: 'clear All',
    cancel: 'Cancel',
    apply: 'Apply',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Edit credit Card',
    cardNumber: 'Card Number',
    expiryDate: 'Expiry Date',
    cvv: 'CVV',
    saveCard: 'Save Card',
    updateExpiryPara: '* You can only update Expiry Date',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Edit Creative Set',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Setup',
    editCreativePara1:
      'Choose a name that describes the theme of your Creative Set. Select the language of the screenshots and previews you’d like to include from your App Store product page.',
    creativeSetName: 'Creative Set Name',
    localLang: 'Localization Language',
    assetSelection: 'Asset Selection',
    assetSelectionPararaph1:
      'Creative Sets consist of screenshots and app previews that appear on the App Store product page.',
    assetSelectionPararaph2: 'For each iPhone display size,',
    assetSelectionPararaph3:
      'select at least one landscape or three portrait assets.',
    assetSelectionPararaph4: 'For each iPad display size,',
    assetSelectionPararaph5:
      'select at least one landscape or two portrait assets.',
    viewDevice: 'View Device Display Sizes',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Display',
    default: 'Default',
    cancel: 'Cancel',
    save: 'Save',
  },

  addIntegration: {
    invalid: 'Invalid integration, Please add again.'
  },

  navbar: {
    syncProgress: 'Data sync is in progress.',
    bannerErr1: 'Unable to sync data due to an invalid integration. Please check',
    bannerErr2: 'integrations page',
    bannerErr3: 'for more details.',
    infoBanner1: 'You are logged in as',
    infoBanner2: 'Return to admin',
  },

  messages: {
    onHold: 'Campaign On Hold.',
    reasons: 'Reasons'
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Delete Integration',
    cardText: 'Are you sure you want to delete the integration?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Add Keywords',
    keywordSuggestionsFor: 'Keyword Suggestions for',
    on: 'on',
    addedKeywords: 'Added Keywords',
    total: 'Total',
    add: 'Add',
    cancel: 'Cancel',
    apply: 'Apply',
    matchExact: 'EXACT',
    matchBroad: 'BROAD',
    searchHere: 'Search Here',

  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Add Keywords',
    selectTarget: 'Select Target',
    h4Camp: 'Campaigns:',
    selected: 'Selected',
    change: 'Change',
    h4Adgroups: 'Ad Groups:',
    maxCPTBid: 'Max CPT Bid:',
    keywordSuggestionsFor: 'Keyword Suggestions for',
    on: 'on',
    recommendedKeywords: 'Recommended Keywords',
    addedKeywords: 'Added Keywords',
    add: 'Add',
    total: 'Total',
    cancel: 'Cancel',
    apply: 'Apply',
    ok: 'Ok',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Add Keywords',
    selectTarget: 'Select Target',
    h4Camp: 'Campaigns:',
    selected: 'Selected',
    change: 'Change',
    h4Adgroups: 'Ad Groups:',
    maxCPTBid: 'Max CPT Bid:',
    keywordSuggestionsFor: 'Keyword Suggestions for',
    on: 'on',
    recommendedKeywords: 'Recommended Keywords',
    addedKeywords: 'Added Keywords',
    add: 'Add',
    total: 'Total',
    cancel: 'Cancel',
    apply: 'Apply',
    ok: 'Ok',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Add Negative Keywords',
    add: 'Add',
    total: 'Total',
    cancel: 'Cancel',
    save: 'Save',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Bid Amount',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Please keep only one Ad-Group selected to continue.',
    close: 'Close',
    chooseAdGroup: 'Choose Ad Group',
    cancel: 'Cancel',
    apply: 'Apply',
    campaigns : 'Campaigns',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    adGroups : 'Ad Groups',
    adGroupSelected : 'Ad Group(s) selected',
    clearAll : 'Clear All'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Add Negative Keywords',
    selectTarget : '1. Select Target',
    addNegKeyword : 'Add Negative Keyword',
    paragraph1 : 'Negative keywords can be added to Campaigns Or Ad Groups. Please choose one of them from below box.',
    paragraph2 : 'Which Campaign do you want to upload keywords?',
    selectCampaign : 'Select Campaign',
    paragraph3 : 'Which Ad Group do you want to upload keywords?',
    selectAdgroup : 'Select Ad Group',
    cancel: 'Cancel',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Campaigns:',
    selected : 'Selected',
    change: 'Change',
    paragraph4 : ' Ad group negative keywords apply to this ad group only. If you want negative keywords to apply to all ad groups, set them at the campaign level.',
    paragraph5 : ' Consider giving campaigns time to run before analyzing ad performance to learn which keywords may not work for you. Adding too many negative keywords may limit your ad exposure.',
    addedKeywords : 'Added Keywords',
    add: 'Add',
    total: 'Total',
    save: 'Save',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Add Slack Channel',
    sno: 'S.No'
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Cancel',
ok : 'Ok',
confirm:'Confirm'
  },

  //HelloWorld
  helloWorld : {
welcome : 'Welcome to Vuetify',
para1 : 'For help and collaboration with other Vuetify developers,',
para2 : 'please join our online',
discord : 'Discord Community',
h2 : "What's next?",
impLinks : 'Important Links',
ecosystem : 'Ecosystem',
  },

  //InfoAlert
  infoAlert : {
ok : 'Ok',
  },

  successMsg: {
    campaignUpdate: 'Campaign updated.',
    campaignMovedMsgPaused: 'Status Changed. Campaign moved to Paused Campaigns',
    campaignMovedMsgLive: 'Status Changed. Campaign moved to Live Campaigns',
    campaignMoveFailMsg: "Couldn't change campaign status.",
    adgroupUpdateFailMsg: "Couldn't update Ad Group status.",
    addgroupUpdatedMsgPaused: 'Status changed. Ad Group moved to Paused Adgroups',
    addgroupUpdatedMsgLive: 'Status changed. Ad Group moved to Live Adgroups',
    keywordMovedMsgPaused: 'Status changed. Keyword moved to Paused Keywords',
    keywordMovedMsgLive: 'Status changed. Keyword moved to Live Keywords',
    keywordMoveFailMsg: "Couldn't update keyword status.",
    creativeAddedMsg: 'Creative set added.',
    campaignSavedMsg: 'Campaign saved as a draft.',
    campaignSaveFailMsg: 'Unable to save campaign draft at the moment. Please try again!',
    keyAddedMsg: 'Keywords Added.',
    signinFailMsg: 'Unable to sign in. Please try again!',
    deleteMsg: 'Deleted Successfully',
    deleteFailMsg: 'Unable to delete. Please try again!',
    dataUpdateMsg : 'Data Updated',
    dataUpdateFailMsg: 'Error while updating data',
    AutoDeleteMsg: 'Automation rule deleted successfully',
    requestFailMsg: 'Unable to process your request. Please try later',
    AutoRuleDisSuccessMsg: 'Automation rule disabled successfully',
    SCAutoRuleDisSuccessMsg: 'Scripted rule disabled successfully',
    AutoRuleEnableMsg: 'Automation rule enabled successfully',
    SCAutoRuleEnableMsg: 'Scripted rule enabled successfully',
    bidChangeSuccessMsg: 'Bid amount changed successfully.',
    wentWrongMsg: 'Something went wrong.',
    integrateSuccessMsg : 'Integration Added Successfully',
    userCreateMsg: 'User created successfully',
    emailExistMsg: 'Email already exists',
    errorCreateUser: 'Error while creating user',
    inviteFailMsg: 'Error while sending invitation link to email',
    failTryLaterMsg: 'Something is wrong. Please try later',
    adgroupAddedMsg: 'Ad Group added.',
    adgroupUpdatedMsg: 'Ad Group updated.',
    ruleUpdatedMsg: 'Rule updated successfully',
    ruleUpdateFailMsg: 'Rule update failed. Please try later',
    rCreatedMsg: 'Rule created successfully',
    rCreationFailMsg: 'Rule creation failed. Please try later',
    assetDataFailMsg: 'Unable to fetch asset details. Please try again!',
    deleteCampFailMsg: 'Error while deleting campaign',
    deleteAdgFailMsg: 'Error while deleting ad group',
    deleteKwFailMsg: 'Error while deleting keyword',
    CampDeletedMsg: 'Campaign Deleted',
    AdgDeletedMsg: 'Ad group Deleted',
    deleteAdFailMsg: 'Error while deleting ad',
    AdDeletedMsg: 'Ad Deleted',
    kwDeletedMsg: 'Keyword Deleted',
    creativeUpdated: 'Creative set updated.',
    keyAdded: 'Keywords added.',
    negaKeyAddedMsg: 'Negative Keywords added to Ad Group(s).',
    negKeyAddCamp: 'Negative keywords added to campaign.',
    errorDeleteKey: 'Error while deleting keyword',
    keyDeleteS: 'Keyword Deleted. Keyword moved to deleted keywords',
    syncStarted: 'Sync started.',
    firstSyncStarted:'Apple Search Ads integration complete! <br> <br> <p style="text-align: justify; color: inherit;">Please wait a few minutes while ASA data is synced for the first time. Your apps and ASA data will populate as soon as this first sync completes. Follow the sync progress in the upper right edge of the page.<p>',
    integrationWarning:'A synced Apple Search Ads integration is required before integrating with this MMP. Please add an Apple Search Ads integration above and wait for the first sync to complete before integrating with this MMP.',
    adUpdate: 'Ad updated',
    StrategyDeletedMsg: 'Strategy Deleted',
    deleteStrategyFailMsg: 'Error while deleting strategy',
    TimelineDeletedMsg: 'Timeline Deleted',
    deleteTimelineFailMsg: 'Error while deleting timeline',
  },
createAdRules: {
    adRequired: 'Ad name required!',
    adNameSize: 'Your Ad name must be less than 200 characters',
},

  createCampaignRules: {
    selectCampGrp: 'Please select campaign group!',
    selectApp: 'Please select an app!',
    selectCountry: 'Please select country!',
    campRequired: 'Campaign name required!',
    campNameSize: 'Your campaign name must be less than 200 characters',
    adGroupSize: 'Your ad group name must be less than 200 characters',
    keywordsize: 'Keywords can\'t be longer than 80 characters',
    budgetRequired: 'Budget amount is required!',
    validBudget: 'Please enter a valid budget!',
    groupRequired: 'Ad Group name required!',
    defMaxCptRequired: 'Default Max CPT Bid is required!',
    validCptBid: 'Please enter a valid CPT Bid!',
    validDailyCap: 'Please enter a valid daily cap!',
    validCpaGoal: 'Please enter a valid CPA goal!',
    selectDevices: 'Please select devices!',
    fNamereq: 'First Name is required',
    fNameGrt: 'First Name must not be greater than 12 characters',
    textMax: 'Must not be greater than 50 characters',
    textMin: 'Must not be less than 2 characters',
    fNameValid: 'Please enter valid first name!',
    lNameGrt: 'Last Name must not be greater than 12 characters',
    lNameValid: 'Please enter valid name!',
    phoneReq: 'Phone is required',
    cmpniNameReq: 'Company Name is required',
    jobTitleReq: 'Job Title is required',
    emailReq: 'Email is required',
    emailValid: 'E-mail must be valid',
    selectCamp: 'Select Campaign',
    cardNoReq: 'Card Number is required',
    cardNo16Digit: 'Card Number must be numerical and 16 digits long',
    expiryMonthReq: 'Expiry Month and Year is required',
    expiryMMYY: 'Expiry date must be MM/YYYY - ex. 07/2023',
    invalidExpiry: 'Invalid expiry month',
    invalidMonth: 'Invalid expiry year',
    cvvReq: 'Card CVV is required',
    cvvNum3Digit: 'Card CVV must be numerical and 3 digits long',
    specialCharNotAllowed:'Special Characters are not allowed',
    onlySinglespaceAllowed:' Single whitespace is only allowed',
    invalidPhoneFormat:'Invalid phone number format',
  },

  settings: {
    profileInfo: 'Profile Information',
    updateProf: 'Update Profile',
    payMethod: 'Payment Method',
    addCredit: 'Add credit Card',
    cardNo: 'Card Number',
    invoiceHistory: 'Invoice History',
    invoiceId: 'Invoice_Id',
    dueDate: 'Due_Date',
    paidAt: 'Paid_At',
    recure: 'Recurring',
    total: 'Total',
    status: 'Status',
    active: 'Action',
    cardId: 'Card_Id',
    type: 'Type',
    expiryMonth: 'Expiry Month',
    expiry: 'Expiry Year',
    action: 'Action',
    addCard: 'Add Card',
    settings: 'Settings',
    accountSetting: 'Account Settings',
    payment: 'Payment',
    primaryPayment: 'Make this card as Primary payment source',
    successTitle: "Success!!!",
    successMsg:"Change to Billing information successfully updated",
    NoSuccessTitle: "No Changes Detected!!!",
    NoSuccessMsg:"No changes found with existing billing information."
  },

  dataRequest: {
    yourDataRequest: 'Your Data Requests',
    remain: 'Remaining',
    complete: 'Completed',
    Fetching: 'Fetching',
    Fetched: 'Fetched',
    appForKeyword: ' Apps for keyword - ',
    keywordsForApp: ' Keywords for App - ',
    appsForCategory: ' top Apps for category - ',
    competitorsForApp: ' suggested competitors for app - ',
  },

  users: {
    manageComp: 'Manage Companies',
    comp: 'Companies',
    addDisManageComp: 'Add, disable, manage all Companies',
    createCompAdmin: 'Create New Company Admin',
    enterEmail: 'Enter Email',
    allCompAdmin: 'All Companies Admin',
    rowPerPages:"Keywords"

  },

  manageSubscription: {
    trialPeriod: "You're in Trial Period",
    trialPeriodExpire: "Your trial will be expired on",
    updateCard: "Update your card",
    subscriptionDetail: "Subscription Details",
    p1: "Next Billing on",
    p2: "Manage billing address, payment history and other details from here",
    planListing: 'Plan Listing',
    furtherPlan: 'Select plan to proceed further',
    generalPlan: 'GENERAL',
    month: 'month',
    tryIt: 'Try it as long as you like',
    createCampaign: 'Create Campaign',
    keyAnalysis: 'Keyword Analysis',
    automationRule: 'Automation Rules',
    keyOpt: 'Keyword Optimization',
  },

  billingDetails: {
    billInfo: 'Billing Information',
    compName: 'Company Name',
    addLine: 'Address Line',
    city: 'City',
    zip: 'Zip',
    vat: 'VAT Number',
    updateBillAdd: 'Update Billing Address'
  },

  dateRangePresets: {
    today: 'Today',
    yesterday: 'Yesterday',
    todayAndYeserday: 'Today and Yesterday',
    weekToDate: 'Week to Date',
    lastWeek:'Last Week',
    lastSevenDays:'Last 7 Days',
    monthToDate:'Month to Date',
    lastMonth:'Last Month',
    lastThirtyDays:'Last 30 Days'
  },

  chartHeaders: {
    accounts: 'Accounts',
    apps: 'Apps',
    campaign: 'Campaigns',
    adgroup: 'Ad Groups',
    keyword: 'Keywords',
    creativeset: 'Creative Sets',
    searchterm: 'Search Terms',
  },

  goals: {
    selectGoal: 'Select Goal',
    selectEventType: 'Select Event type',
    selectGoalRule: 'Please select goal type',
    selectEventRule: 'Please select event type',
    goalNameRule: 'Please enter goal name',
    manageGoals: 'Manage Goals',
    goalDesc: 'You can manage your goals from here',
    createGoal: 'Create Goal',
    editGoal: 'Edit Goal',
    chooseGoal: 'Choose a Goal Type',
    chooseEvent: 'Choose Event(s)',
    goalName: 'Goal Name',
    close: 'Close',
    update: 'Update',
    create: 'Create',
    name: 'Name',
    app: 'App',
    appId: 'App Id',
    type: "Type",
    events: "Events",
    createdOn: "Created On",
    createdBy: "Created By",
    action: "Action",
    revenue: "Revenue",
    purchase: "Purchase",
    subscribe: "Subscribe",
    userEngagement: "User Engagement",
    levelComplete: "Level Complete",
    regComp: "Registration Complete",
    tutComp: "Tutorial Complete",
    startTrial: "Start Trial",
    appLaunch: "App Launch",
    userAction: "User Action",
    addToCart: "Add to Cart",
    addToWishlist: "Add to Wishlist",
    checkoutStart: "Checkout Start"
   },

  campaignGenerator: {
    decisionMessage: 'Are you sure you want to delete the template?',
    addKeyPlaceholder: 'Type comma seperated text and press enter or click add',
    showDraft: 'Show Draft',
    pageHeading : 'Campaign Generator',
    createCampaignHeading: 'Automated campaign setup',
    Segmentation: 'Segmentation',
    Categories: 'Categories',
    Brand: 'Brand',
    Category: 'Category',
    Discovery: 'Discovery',
    Competitor: 'Competitor',
    Custom: 'Custom',
    Criteria: 'Criteria',
    pleaseSelectCriteria: 'Please Select Criteria',
    pleaseEnterValue: 'Please Enter Value',
    Value: 'Value',
    To: 'To',
    From: 'From',
    FromTo10: 'Please enter value with difference greater than 10 from first value',
    customSlugHeading: 'Custom Campaign Slug:',
    customSlugPH: 'Enter a slug name',
    customSlugAdd: 'Add',
    customSlugDup: 'Duplicate slug name',
    SegmentationRules: 'Segmentation Rules',
    SegmentationDesc: 'Campaigns have budgets and ad groups that focus on a common theme or strategy for promoting your app.',
    age: 'Age',
    geo: 'Location',
    selectCustomerType: 'Please select Customer type',
    selectGender: 'Please select Gender',
    selectAge: 'Please select Age',
    keyManually: 'Add keywords manually',
    Summary: 'Summary',
    SummaryText: 'Check summary of campaigns and adgroups',
    TotalCampaigns: 'Total Campaigns',
    TotalAdgroups: 'Total Adgroups',
    CampaignTitle: 'Campaign Title',
    keysReady: 'keywords are ready',
    custom: 'custom',
    keysFetch: 'Fetching keywords for',
    Campaignsarecreated: 'Campaigns are created',
    Creatingcampaigns: 'Creating campaigns',
    Viewallitems: 'View all items',
    Adgroupsarecreated: 'Adgroups are created',
    Creatingadgroups: 'Creating adgroups',
    Keywordsarecreated: 'Keywords are created',
    Creatingkeywords: 'Creating keywords',
    ready: 'Ready',
    CreatedBy: 'Created By',
    CreatedOn: 'Created On',
    Campaigngenerationdetails: 'Campaign generation details',
    footerProps: {
      itemsPerPageText: 'Rows per page',
      itemsPerPageAllText: 'All',
      showFirstLastPage: true,
    },
    generateCampaigns: 'Generate Campaigns',
    setDefaultValues: 'Set Default Values',
    setDefaultValuesSub: 'Please provide default budget settings for the targeted locations.',
    nokeyPara: 'No keyword entry is necessary for Category, Discovery, or Competitor campaigns as keywords will be automatically generated using our Keyword Analysis technology.',
    waitingToSync: 'Waiting to Sync',
    campSyncHour:'Campaigns will be synced to Apple within the hour.',
    //syncInProgress: 'Sync in Progress',
    syncInProgress: 'Syncing',
    syncInProgressFewMin: 'Syncing is in progress and should be completed within a few minutes..',
    syncComplete: 'Sync Complete',
    allSyncCompleted: 'All campaigns have been synced successfully.',
    campNameUniq: 'Please enter unique Campaign names',
    campNamExists: 'A campaign with same name already exists',
    adNameUniq: 'Please enter unique Ad Group names',
    saveAsDraft: 'Save as draft',
    draftHeading: 'Campaign Template Draft',
    draftSearch: 'Search',
    headerOrgName: 'Org Name',
    headerAppName: 'App Name',
    headerResume: 'Resume'
  },

  appSetting: {
    manage: 'Manage',
    setDefaultValues: 'Set Default Values',
    edit: 'Edit',
    update:'Update',
    noRecords: 'No matching records found',
    searchByCountry:'Search by Country'
  },

  sr: {
    pageHeading: 'Scheduled Reports',
    createScheduleReport: 'Create Scheduled Report',
    srDesc: 'Sheduled reports lets you mail or slack reports at your own defined time and day',
    createSR: 'Schedule New Report',
    for: 'Report For',
    type: 'Repeat',
    dataDays: 'Data For Last',
    reportNameExists: 'Name already exists!',
    enterReportName: 'Enter a name',
    repeatMode: 'Repeat Type',
    pleaseSelectFor: 'Please select a report level',
    pleaseSelectType: 'Please select a repeat Type',
    pleaseSelectDay: 'Please select day(s)',
    pleaseSelectTime: 'Please select a time',
    noRepeat: 'Do Not Repeat',
    custom: 'Custom',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    selectOrg: 'Please select an org',
    selectGoal: 'Please select a goal',
    goalsSelected: 'Goals Selected',
    orgsSelected: 'Orgs selected',
    scheduleReport: 'scheduled report?',
    scheduleReports:'Schedule Reports',
    reportOf:'Report of',
    selectTime:'Select Time',
    downloadReport:'Download Report',
    addMore:'Add More',
    scheduleReport1:'Schedule Report',
    selectSlackChannel:'Select Slack Channel',
    emailNoSpaces:'Email cannot contain spaces',
    emailWarning:'Please select atleast one email or enter atleast one external email Id below',
    errorDuringSave:'Error while saving Scheduled Report',
    scheduleSuccess:'Scheduled successfully',
    scheduleSaveWarning:'Are you sure you want to schedule this report?'
   },

  common : {
    save: 'Save',
    cancel: 'Cancel',
    noDataText: 'No data to show',
    days: 'Day(s)',
    time: 'Time',
    na: 'NA',
    goal: 'Goal(s)',
    deleteConfirmText: 'Are you sure to delete this ',
    createConfirmText: 'Proceed to create this new ',
    updateConfirmText: 'Are you sure to update this ',
    dataTableFooterProps: {
      itemsPerPageText: 'Rows per page',
      itemsPerPageAllText: 'All',
      showFirstLastPage: true,
    },
  },

  welcomeOnboard: {
    yes: 'YES',
    no: 'NO',
    continue: 'Continue',

    welcome: 'WELCOME TO SEARCH ADS MAVEN',
    description: 'Welcome to Search Ads Maven. Let’s begin by setting up your integration. You will need your apple id and if your ASA utilizes dual factor authentication, you will need the associated device.',
    getStart: 'Get Started',
    setupLater: 'Go to application and setup later',
    setup: 'Setup',
    createIntegration: 'Integrate with Search Ads Maven',
    createIntDescription: 'Enter your Apple ID to begin synchronizing your data between Apple Search Ads and Search Ads Maven. After synchronizing your data you will be able to set up any 3rd party MMP integrations as well.',
    enterAppleId: 'INTEGRATE YOUR APPLE ID',
    success: 'Success',
    stepper: {
      title: 'Synchronization In Progress!',
      descreption1: 'We\'re importing your historical data from Apple Search Ads. Depending on the amount of data, it could take a few hours to complete. You can check the progress indicator in the upper right at any time during this synchronization and once synchronization has completed, visit the Integrations page.',
      descreption2: 'In the meantime, let\'s get familiar with the platform.',
    },
    stepperTwo: {
    title: 'MMP Integration',
    descreption:' MMPs, are third-party measurement tools that allow you to measure and compare the effectiveness of your ad spend across many different  marketing channels and partners on full-funnel app engagement data. The biggest MMP feature that will help us here is analyzing and optimizing our ASA performance against events that happen after the customer leaves the app store and starts using the app.',
    successfullIntegrations:'Successfull Integrations',
    receivedTypes: ' Received Event Types'
    },
    startGuide: 'Start Guide',
    syncMsg: {
      your: 'Your',
      syncWasLess: 'sync was less than',
      moreTime: 'ago. Please allow more time before syncing again.'
    },
    goToApplication: 'Go to application',
    overview: {
      title: 'Welcome to Overview ',
      description: 'Here you will find a summary of your current campaign performance, associated metrics and more. You can customize this view to suit your needs.',
      popupSecond: {
        title: 'Thanks for exploring Search Ads Maven',
        description: 'If you have any questions or issues with integration, please reach out to your support team any time at '
      }
    },
    adsManager: {
      title: 'Welcome to Ads Manager',
      description: 'Here you can view granular analytics and reporting for your applications, campaigns, ad groups, keywords and more. Also, create campaigns manually to take the guesswork out of the process.  The Ads Manager section is also where you have the ability to create campaigns, ad groups, keywords and negative keywords. '
    },
    keywordAnalysis: {
      title: 'Welcome to Keyword Analysis ',
      description: 'Here you can gauge the overall effectiveness of your campaigns keywords and see suggested competitors to your app, and conquest your competitor’s keywords.You can also quickly add supplemental keywords to your campaigns.'
    },
    keywordOptimization:{
      title: 'Welcome to Keyword Optimization',
      description: 'Here you can quickly and effectively optimize your bid amounts for keywords not in the range of ASA suggested minimum and maximum bids. Find goal metrics based on your app’s events included alongside the ASA recommendations.'
    },
    integrations: {
      title: 'Welcome to Integration',
      description: 'This is where you can add ASA integrations, define 3rd party MMP integrations and set up communication channels.'
    },
    automation: {
      title: 'Welcome to Automation Studio',
      description: 'Here you can create automation rules for your organizations, application, campaigns, Ad groups and keywords.'
    },
    goals: {
      title: ' Welcome to Goals',
      description: 'Here you can define how 3rd party MMP events are used to create campaign goals based on revenue, engagement and user activities. '
    },
    strategies: {
      title: ' Welcome to strategies',
      description: 'strategies'
    },
    campaignGenerator: {
      title: ' Welcome to Campaign Generator',
      description: 'Here you can quickly create campaigns manually or use our automated campaign generator to take the guesswork out of the process.'
    },
    strategicView: {
      title: 'Welcome to Strategic View',
      description: 'Conquer Apple Search Ads complexity with Search Ads Maven Strategies. Group campaigns, ad groups, and keywords into streamlined entities, powered by automation and clear milestones. Build, execute, and measure success—all within your grasp. Simplify, optimize, win.'
    },
    thirdParty: {
      popupOne: {
        title: 'Would you like to integrate your third party event data? ',
        description: ''
      },
      popupTwo: {
        title: '',
        description: 'Third party event data cannot be imported until after your ASA sync is complete. Please return to this page when your sync has reached 100% '
      },
      popupThree: {
        title: '',
        description: 'Your ASA information will take some time to populate within Search Ads Maven, so in the meantime, lets gets familiar with the platform.'
      },


    }
  },
  strategies:{
    manageStrategies :'Manage Strategies',
    desc: 'You can manage your strategies from here'
  }
}

