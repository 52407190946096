//Japanese

export const ja = {
  pages: {
    overview: '概要概要',
    adsManager: 'アドマネージャー',
    kai: 'キーワード分析',
    keyOpt: 'キーワードの最適化',
    teamMgmt: 'チーム',
    integrations: '統合',
    automationRule: 'オートメーション',
    campaignGenerator: 'キャンペーン ジェネレーター',
    goals: '目標',
    appSettings: 'アプリの設定',
    kar: 'キーワード分析リクエスト',
    manualCampaign:'手動キャンペーンを追加',
    automatedCampaign:'自動キャンペーンを追加',
  },
  menu: {
    settings: '設定',
    signOut: 'サインアウト',
    manageSubscription: "サブスクリプションの管理",
    orgs: '組織'
  },
  buttons: {
    syncData: '最新のデータを同期する',
    createCampaign: 'キャンペーンを作成する',
    filter: 'フィルタ',
    reset: 'リセット',
    apply: '適用する'
  },
  labels: {
    charts: 'チャート',
    selectDs: 'データセットを選択',
    totalSummary: 'まとめ',
    spend: '費やす',
    search: '検索',
    searchByKeySearchMsg: 'テキストを入力してEnterキーを押します',
    competeSearchMsg : 'アプリを選択または検索する',
    selectCategoryMsg: 'カテゴリーを選ぶ',
    selectCategoryType: 'タイプを選択',
    matchType: 'マッチタイプ',
    filterItem: 'ここに入力して、以下のアイテムをフィルタリングします',
    cusType: '顧客タイプ',
    createRule: 'ルールを作成する',
    fromSelected: '選択から',
    mmYY: 'MM/YYYY' ,
    enterKey: 'キーワードを入力します' ,
    selectLevel: 'レベルを選択',
    org: '組織',
    app: 'アプリケーション',
    camp: 'キャンペーン',
    adGroup: '広告グループ',
    key: 'キーワード',
    searchTerm: '検索ワード）',
    yourApps: 'あなたのアプリ',
    suggestedApps: '推奨アプリ',
  },
  dropdowns: {
    noData: 'データなし',
    daily: '毎日',
    weekly: '毎週',
    monthly: '毎月'
  },
  tabs: {
    accounts: 'アカウント',
    apps: 'アプリ',
    campaigns: 'キャンペーン',
    adgroups: '広告グループ',
    keywords: 'キーワード',
    creativeSets: 'クリエイティブセット',
    searchTerms: '検索ワード',
    negKeywords: '除外キーワード',
    ad: '広告'
  },
  summary: {
    taps: 'タップ',
    impressions: '印象',
    ttr: 'TTR',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    installs: 'インストール',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  accountHeaders: {
    account: 'アカウント',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    ttr: 'TTR',
    conversionRate: '変換速度',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  appsHeaders: {
    appName: '応用',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstall: 'Attrインストール',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRevenue: '目標収益',
    goalsRoas: 'ゴールROAS',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    account: 'アカウント',
    categoryRank: 'カテゴリランク',
    appId: 'アプリID',
    totalCamp: '総キャンペーン',
    goalAMPU: '目標AMPU',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  campaignHeaders: {
    campaign: '運動',
    campaignStatus: 'キャンペーンステータス',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRoas: 'ゴールROAS',
    storefronts: '店先',
    account: 'Account',
    displayStatus: 'ステータスの表示',
    appName: 'アプリ名',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    delete: '削除',
    totalBudget: '総予算',
    dailyCap: 'デイリーキャップ',
    supplySource:'タイプ',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  campaignDraftHeaders: {
    campaignName: 'キャンペーン名',
    countries: '国',
    budgetAmount: '予算額',
    resume: '履歴書',
  },
  adGroupHeaders: {
    adgroup: '広告グループ',
    adgroupStatus: '広告グループのステータス',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRoas: 'ゴールROAS',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    displayStatus: 'ステータスの表示',
    account: 'アカウント',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  keyHeaders: {
    keyword: 'キーワード',
    keywordStatus: 'キーワードステータス',
    bidAmount: '入札額',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRevenue: '目標収益',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    adgroupStatus: '広告グループのステータス',
    organicRank: 'オーガニックランク',
    appName: 'アプリ名',
    difficultyScore: '難易度スコア',
    popularity: '人気',
    keywordId: 'キーワードID',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID',
    suggestedMinBid: 'ASAが提案する最小入札額',
    suggestedMaxBid: 'ASAが推奨する最大入札額',
    suggestedBidAmount: 'ASA 推奨入札額',
    bidStrength: '入札の強さ',
    latOff: 'LATオフインストール',
    latOn: 'インストール時のLAT',
    matchType: 'マッチタイプ',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  adsHeaders: {
    ads: '広告',
    creativeType: 'クリエイティブタイプ',
    creativeSetStatus: '状態',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRevenue: '目標収益',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    adgroupStatus: '広告グループのステータス',
    account: 'アカウント',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID',
    action:'アクション',
    delete: '消去',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  creativeHeaders: {
    creativeSet: 'クリエイティブセット',
    creativeSetStatus: '状態',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    ttr: 'TTR',
    conversionRate: '変換速度',
    attrInstallRate: 'Attrインストール率',
    cpi: 'CPI',
    goals: '目標',
    costPerGoal: '目標あたりのコスト',
    goalRate: '目標率',
    revenuePerGoal: '目標あたりの収益',
    goalsRevenue: '目標収益',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    adgroupStatus: '広告グループのステータス',
    account: 'アカウント',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  searchTermsHeader: {
    searchTerm: '検索用語テキスト',
    spend: '費やす',
    averageCpa: '平均CPA',
    averageCpt: '平均CPT',
    impressions: '印象',
    taps: 'タップ',
    installs: 'インストール',
    ttr: 'TTR',
    conversionRate: '変換速度',
    newDownloads: '新しいダウンロード',
    reDownloads: '再ダウンロード',
    storefronts: '店先',
    campaignStatus: 'キャンペーンステータス',
    adgroupStatus: '広告グループのステータス',
    keyword: 'キーワード',
    keywordId: 'キーワードID',
    appId: 'アプリID',
    campaignId: 'キャンペーンID',
    adgroupId: 'アドグループID',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },
  negativeHeaders: {
    keyword: 'キーワード',
    matchType: 'マッチタイプ',
    status: '状態',
    adgroupId: 'アドグループID',
    campaignId: 'キャンペーンID',
    deleted: '削除されました',
    keywordId: 'キーワードID',
    modificationTime: '変更時間'
  },
  filter: {
    account: 'アカウントを選択',
    app: 'アプリを選択',
    goal: '目標を選択',
    campaign: 'キャンペーンを選択',
    adgroup: '広告グループを選択',
    ad: "広告を選択"
  },
  subTabs: {
    all:'全て',
    live: '住む',
    paused: '一時停止',
    deleted: '削除されました',
    draft: 'ドラフト',
    campaigns: 'キャンペーン',
    adgroups: 'アドグループ',
    keywords: 'キーワード',
    creativeSet: 'クリエイティブセット',
    negKey: '除外キーワード'
  },
  popups: {
    add: '追加',
    integration: '統合',
    adgroup: '広告グループ',
    keywords: 'キーワード',
    creativeSet: 'クリエイティブセット',
    negKey: '除外キーワード',
    campaigns:'キャンペーン'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: 'メンバーを管理する',
    invite: 'チームメンバーの招待と管理',
    add: 'メンバーを追加',
    first: 'ファーストネーム',
    last: '苗字',
    phone: '電話',
    email: 'Eメール',
    job: '職名',
    allMembers: '全員',
    processing: '処理...',
    ok: 'OK',
    inviteStatus: 'ステータスを招待',
    isDisabled: '無効になっています',
    company: '会社名'
  },

  //Add Integration Page
  integration: {
    assPart: '関連パートナー',
    apple: 'Apple検索広告',
    slack: 'スラック',
    itunes: 'iTunes',
    tenjin: '天神',
    kochava: 'コチャバ',
    adjust: '調整する',
    appsFlyer: 'AppsFlyer',
    singular: '特異な',
    communication:'コミュニケーション',
    mmp:'モバイル測定パートナー',
    completeInt:'完了した統合',
    receivedEventType:'受信したイベントタイプ',
    waitForEvents:'イベントを待っています',
    integrateWith:'と統合する',
    intStep:'統合手順',
    integrate:'統合',
    followIntSteps:'これらの統合手順に従ってください',
    //kochava steps
    kochavaStep1:'ステップ1. https://go.kochava.com/appsでKochavaアプリにアクセスします。',
    kochavaStep2:'手順2. アプリのコンテキストメニューから[パートナーの構成]を選択します。',
    kochavaStep3:'手順3. [パートナーの構成]ページから[構成の追加]をクリックします。',
    kochavaStep4:'ステップ4. [メディアパートナー]メニューから[検索広告Maven-iOS]を選択し、[移動]をクリックします。',
    kochavaStep5:'ステップ5. SearchAdsMavenにシンジケートする各イベントのコンテキストメニューから[作成]を選択します。',
    kochavaStep6:'ステップ6. ポストバックの作成ごとにここで提供されるAPIKEYとAppStoreIDを使用します。',
    kochavaStep7:'ステップ7. イベントがSearchAdsMavenによって認識されるまで少なくとも24時間待ちます。 AppleSearchAdsキャンペーンに起因するイベントのみが認識されることに注意してください。',
    kochavaStep8:'ステップ8. SearchAdsMavenによって認識された各イベントの目標を作成します。目標が作成されると、カウントや収益などのMMPイベントデータがダッシュボードに表示されます。',

    //branchSteps
    branchStep:'1. ブランチダッシュボード内で次のAPIキーとAppStoreIDを使用します。',

    //customSteps
    customStep:'1. ポストバック内で次のAPIキーとAppStoreIDを使用します。',

    apiKey:'APIキー',
    appStoreId:'App Store ID',
    distinctEventType:'ASAに起因する個別のイベントタイプは、このアプリのMMPによって配信されています。これらのイベントタイプは、目標の作成に使用できます。',
    noDistinctEventType:'AppleSearchAdsに起因するイベントはまだ受信されていません。 24時間経過してもイベントが受信されない場合は、これらの統合手順を再確認し、アクティブなApple Search Adsキャンペーンが実行されていることを確認してください。これにより、MMPによって1つ以上のイベントが配信されます。',
    dEventType:'個別のイベントタイプ',
    noEventFound:'イベントが見つかりません',
    close:'近い',
    note:'注：すでに別のMMPと統合されているアプリは、このリストに表示されません。',
    intDeletionInProgress:"統合の削除は現在進行中です。完了したら通知されます。"
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: 'キーワードを追加',
    total: '合計',
    cancel: 'キャンセル',
    runAds: '広告を実行する',
    ok: 'OK',
    totalPaidKey: '総有料キーワード',
    popularity: '人気',
    numOfApps: 'アプリの数',
    chance: '機会',
    appsRunAds: '広告を実行しているアプリ',
    maxPop: 'あなたのための最大の人気',
    keyword: 'キーワード',
    clear: '晴れ',
    clrAllFilter: 'すべてのフィルターをクリア',
    keywords: 'キーワード',
    organicRank: 'オーガニックランク',
    totalApps: '総アプリ',
    app: 'アプリ',
    catName: '種別名',
    searchByApp: 'アプリで検索',
    searchByKey: 'キーワードで探す',
    addAllKey: 'すべてのキーワードを追加',
    country: '国',
    lang: '言語',
    searchForCompetes: '提案された競合他社',
    searchAdsRecommends: '検索広告の推奨事項',
    categoryTop: 'カテゴリトップアプリ',
    compRating: '評価',
    compVersion: 'バージョン',
    compPrice: '価格',
    compGenres: 'ジャンル',
    monthlyDownloads: '毎月のダウンロード',
    showDataText: '表示するデータがありません',
    free: '無料',
    paid: '有料',
    quickAddCriteria: 'クイック追加基準',
    tooltip: {
      keyText: "キーワードタイトル",
      organicRank: "オーガニックランクとは、有料広告の影響を受けない検索エンジンの検索結果を指します。",
      popularity: "キーワードのキーワード人気スコア。検索スコアが高いほど、キーワードの検索ボリュームが高くなります。",
      totalApps: "このキーワードを使用しているアプリの総数",
      appDetails: "アプリケーション名",
      organicRankApp: "オーガニックランクとは、有料広告の影響を受けない検索エンジンの検索結果を指します。",
      category: "アプリが属するカテゴリ",
      rating: "アプリの評価",
      version: "アプリのバージョン",
      price: "アプリの価格",
      genres: "アプリのジャンル",
      chance: "チャンススコアは、アプリの特定のカテゴリの特定のキーワードでトップ10に入る可能性の推定に基づくパーセンテージスコアです。",
      appsRunning: "キーワードに支払っているアプリの総数"
    }

  },

  //Overview Page
  overview: {
    selectGoalFirstSelectApp:'目標を選択するには、まず左側のドロップダウンメニューからアプリを選択してください。',
    count:'ゴールイベント数 ',
    exportCSV:'CSVエクスポート',
    period: '限目',
    topKeywords: 'トップ10キーワード',
    key: 'キーワード',
    spend: '費やす',
    impressions: '印象',
    taps: 'タップ',
    goToKey: 'すべてのキーワードに移動',
    topAdgroup: '上位10の 広告グループ',
    adgroup: '広告グループ',
    goToAdgroup: 'すべての広告グループに移動',
    gender: '性別',
    device: '端末',
    searchApp: '検索アプリ',
    lastDay: '最終日',
    last7Days: '過去7日間',
    last30Days: '過去30日間',
    ttr: 'タップスルー率（TTR）',
    installs: 'インストール',
    avgCPA: '平均CPA',
    avgCPT: '平均CPT',
    conversionRate: '変換速度 (CR)',
    latOnInstalls: 'インストール時のLAT',
    latOffInstalls: 'LATオフインストール',
    reDownloads: '再ダウンロード',
    newDownloads: '新しいダウンロード',
    all: 'すべて',
    tooltip: '選択した日付範囲に基づいた、前の期間からのパーセンテージとしての時間の経過に伴う増加または減少。',
    selectPer: '期間を選択',
    prevPeriod: '前の期間',
    currPeriod: '当期',

    GOLS: '目標',
    CPRG: '目標あたりのコスト',
    RPRG: '目標あたりの収益',
    GREV: '目標収益',
    GRAS: '目標 ROAS',
    GETC: '目標 イベント数',
    tapInstalls: 'インストール (タップスルー)',
    viewInstalls: 'インストール (ビュースルー)',
    totalInstalls: 'インストール数 (合計)',
    tapNewDownloads: '新規ダウンロード (タップスルー)',
    viewNewDownloads: '新規ダウンロード (ビュースルー)',
    totalNewDownloads: '新規ダウンロード (合計)',
    tapRedownloads: '再ダウンロード (タップスルー)',
    viewRedownloads: '再ダウンロード(ビュースルー)',
    totalRedownloads: '再ダウンロード (合計)',
    tapInstallRate: 'コンバージョン率（タップスルー）',
    totalInstallRate: 'コンバージョン率（合計）',
    tapInstallCPI: '平均 CPA (タップスルー)',
    totalAvgCPI: '平均 CPA (合計)'
  },

  checklist: {
    title: 'チェックリスト',
    integrations: '統合',
    searchads: 'Apple検索広告',
    slack: 'Slack統合を追加する',
    kai: 'キーワード分析ツールに精通する',
    competitorMsg: '競合他社の状況を確認する',
    searchKeywords: 'アプリのキーワードを参照してください',
    seeApps: 'キーワードについては入札アプリをご覧ください',
    topCharts: 'カテゴリトップチャートを見る',
    campaign: '運動',
    initCampaign: 'キャンペーンを開始する',
    createAdgroup: '広告グループを作成する',
    addKeywords: 'キーワードを追加する',
    addCreativeSet: 'クリエイティブセットを追加',
    goLive: 'ライブ配信',
    reviewMetrics: 'キャンペーンの成功を確認する',
    automate: 'キャンペーンを自動化する',
    mmpIntegration: 'MMP 統合',
    createMmpGoals: 'MMP 目標の作成',
    campGen:'キャンペーン ジェネレーター',
    scheduledReports:'定期レポート'
  },

  //Keyword Optimization Page
 keyOpt: {
  keyBid: 'キーワードの最適化',
  keyToolTip: 'ASAの推奨最小入札額とASAの推奨最大入札額の範囲外のキーワードの入札額を最適化できます。',
  optAll: 'すべてを最適化',
  opt: '最適化',
  optBidAmt: '入札額の最適化',
  key: 'キーワード',
  optBidForAll: 'すべてのキーワードの入札額を最適化する',
  campaign: '運動',
  adgroup: '広告グループ',
  bidAmt: '入札額',
  asaMinBid: 'ASAが提案する最小入札額',
  asaMaxBid: 'ASAが推奨する最大入札額',
  asaSuggestedBidAmount: 'ASA 推奨入札額',
  action: 'アクション',
  noData: "現時点では、Appleからの提案はありません。"
  },

  //ARRules Page
  aRules: {
  createAR: '自動化ルールの作成',
  view: '見る',
  edit: '編集',
  delete: '削除',
  disable: '無効にする',
  enable: '有効にする',
  name: '名前',
  createdAt: '作成場所',
  status: '状態',
  completed: 'で完了',
  actionFreq: 'アクション頻度',
  rules: 'ルール',
  logs: 'ログ',
  createdFor: 'のために作成されました',
  chckFreq: '頻度を確認する',
  actions: '行動',
  enableDisable: '有効/無効',
  sno: 'S.No'
  },

  //Create Campaign
  //CampaignView
  createCampaign: {
  createCamp: 'キャンペーンを作成する',
  h2: 'キャンペーンの作成を開始するアプリを選択してください',
  para: 'キャンペーンには、アプリを宣伝するための共通のテーマや戦略に焦点を当てた予算と広告グループがあります。',
  select: '広告を掲載する場所を選択する',
  searchResults: 'の検索結果',
  srData: 'キャンペーンには、アプリを宣伝するための共通のテーマや戦略に焦点を当てた予算と広告グループがあります。',
  searchTab: '検索タブ',
  stData: 'ユーザーが[検索]タブにアクセスしたときに、おすすめのアプリリストの上部にアプリを宣伝します。',
  todayTab: '今日タブ',
  todayData:'初めてアプリ ストアにアクセスしてアプリを見つけたユーザーにリーチします。あなたの広告はホームページに目立つように表示されます。',
  productPageTab: '製品ページ',
  productPageData: 'アプリを閲覧しているユーザーにリーチします。上部に広告が表示されます。また、関連する製品ページの一番下までスクロールしたユーザーを一覧表示することもできます。',
  cancel: 'キャンセル',
  continue: '継続する',

  //Search Results and Search Tab Campaign
  countAndReg: '国と地域',
  adgroupSettings: '広告グループの設定',
  searchMatch: '検索一致',
  key: 'キーワード',
  audience: '聴衆',
  crSets: 'クリエイティブセット',
  chooseCampGroup: 'キャンペーングループを選択する',
  chooseApp: 'アプリを選択してください',
  next: '次',
  countPara: 'アプリは、リストから選択した対象の国と地域のAppStoreで宣伝されます。',
  chooseCount: '1つ以上の国または地域を選択してください',
  campName: 'キャンペーン名',
  budget: '予算',
  dailyCap: '1日あたりの上限（オプション）',
  adgroupPara: '広告グループには、広告を表示する相手を決定する価格設定の目標と設定が含まれています。',
  adgroupName: '広告グループ名',
  devices: 'デバイス',
  startTime: '始まる時間',
  adScheduling: '広告のスケジューリング',
  endTime: '終了時間',
  dayParting: '昼間（オプション）',
  ok: 'OK',
  defaultMax: 'デフォルトの最大CPT入札',
  cpaGoal: 'CPAの目標（オプション）',
  saveDraft: '下書きとして保存',
  appleSearchMatch: 'アップルサーチマッチ',
  searchMatchPara: 'Apple Search Matchは、広告を掲載するための最も簡単な方法です。 検索広告は、あなたのようなアプリを検索しているユーザーに広告を自動的に照合します。',
  searchMatchPara1: '広告を関連する検索に自動的に一致させる',
  keywords: 'キーワード',
  keyPara: 'キーワードは、顧客があなたのようなアプリを検索するときに使用する可能性のある関連する単語または用語です。 キーワードを使用すると、検索者はアプリをすばやく見つけることができ、アプリのインストールを促進できます。',
  upload: 'アップロード',
  suppFormat: 'サポートされている形式：Excel、CSV',
  keyManually: 'または手動でキーワードを追加します',
  targKey: 'ターゲティングキーワード',
  clear: '晴れ',
  adgroupNegKey: '広告グループの除外キーワード',
  optional: 'オプション',
  negKey: '除外キーワード',
  audPara: ' オプションの高度な機能を使用して、広告グループのオーディエンスを絞り込みます。',
  custType: '顧客タイプ',
  demo: '人口統計',
  gender: '性別',
  location: 'ロケーション (検索)',
  crSetPara: '広告は、App Storeの製品ページで提供されるメタデータ、スクリーンショット、アプリのプレビューを使用して自動的に作成されます。 これらは、AppStoreのオーガニック検索結果にも表示されます。 ここに表示される広告は、デフォルトの広告例です。',
  crOptional: 'クリエイティブセット（オプション）',
  noCrSet: 'アプリが最小クリエイティブセットの要件を満たしていません',
  campCreated: '作成されたキャンペーン',
  campCreatedPara: 'これで、検索広告ダッシュボードにキャンペーンを表示できます',
  close: '閉じる',
  matchType: 'マッチタイプ',
  amount: '量',
  currency: '通貨',
  allUsers: '全てのユーザー',
  newUsers: '新規ユーザー',
  appUsers: '他のアプリのユーザー',
  existingUsers: '既存のユーザー',
  all: 'すべて',
  male: '男性',
  female: '女性',
  selectApp:'検索アプリ',
  chooseCamp: 'キャンペーングループ',
  countries: '国',
  ageRange: '年齢層',
  selectDev: 'デバイスの選択',
  adsOpt: '広告（オプション）',
  default: 'デフォルト',
  defaultAdPara: 'デフォルトのAppStore製品ページのアセットを使用します。',
  custom: 'カスタム',
  customPara: 'デフォルトのAppStoreConnectのアセットを使用し、ユーザーが広告をタップするとそのページに移動します。',
  pagesInfo: 'カスタム製品ページが利用可能です',
  createAdPara: '[広告の作成]ボタンをクリックして、カスタム製品ページのオプションから選択し、広告のプレビューを表示します',
  createAdBtn: '広告を作成する',
  createAds: '広告を作成する',
  selectCustomPara: 'カスタム商品ページを選択して、そのアセットを広告の作成に使用します。ユーザーが検索結果で広告をタップすると、選択したページに移動します。',
  adName: '広告名',
  search: '探す',
  available: '利用可能',
  sortBy: '並び替え',
  save: '保存',
  customAds: 'カスタム広告',
  edit: '編集',
  previous: '前'
  },

  //Change Password
  changePass: {
    backSett: '設定に戻る',
    chngPass: 'セットアップパスワード',
    newPass: '新しいパスワード',
    confirmPass: 'パスワードを認証する',
    submit: '参加する',
    h4: 'KochavaMarketersオペレーティングシステム™',
    para:
      'マーケターオペレーティングシステム（m / OS）は、広告主とパブリッシャー向けのオムニチャネルマーケティングソリューションを1つの運用プラットフォームにシームレスに統合します。',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'パスワードをお忘れですか',
    typeEmail: 'メールアドレスを入力してください',
    emailAdd: '電子メールアドレス',
    submit: '参加する',
    chckEmail: 'メールを確認してください',
    para: '新しいパスワードを設定するには、メールアドレスを確認してください',
    close: '閉じる',
    alreadyAcc: 'すでにアカウントをお持ちですか？',
    signIn: 'サインイン',
  },

  //Onboard
  onboard: {
    welcome: 'ようこそ SearchAds Maven',
    personal: 'サインアップ',
    start: '開始',
    integration: '統合',
    next: '次',
    trial: 'トライアルを開始する',
    scheduleDemo: 'または、デモをスケジュールして一緒に発見してください',
    addInt: '統合を追加します。',
    signInApple:
      'Appleにサインインして、Apple SearchAds統合を追加します。 ご注意ください：',
    step1:
      '1. Apple Search Adsアカウントへのアクセスを許可するには、管理者権限が必要です。',
    step2:
      '2. SearchAdsNinjaからキャンペーンを管理するには、アクセスを許可する前に[読み取りと書き込み]オプションを選択してください。',
    skip: 'スキップ',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: ' 広告グループ を追加する',
    para:
      'キャンペーンをまだ選択していません。 下のボックスからお選びいただけます。',
    camGroup: 'キャンペーングループ',
    campaign: '運動',
    adgroupSett: '広告グループの設定',
    para1:
      '広告グループには、広告を表示する相手を決定する価格設定の目標と設定が含まれています。',
    adgroupName: '広告グループ名',
    devices: 'デバイス',
    adScheduling: '広告のスケジューリング',
    startTime: '始まる時間',
    endTime: '終了時間',
    dayparting: '昼間（オプション）',
    defaultMax: 'デフォルトの最大CPT入札',
    cptTool:
      'これは、追加をタップするために支払うことができる最大金額です。この入札は、個別のキーワード入札を適用しない限り、この広告グループのすべてのキーワードに適用されます。',
    cpaGoal: 'CPAの目標（オプション）',
    cpaTool:
      'ダウンロードごとに支払った平均。 これは、費やされた合計をダウンロード数で割ることによって計算されます。',
    searchMatch: '検索一致',
    searchTool:
      '検索一致は、広告を掲載するための最も簡単な方法です。 検索広告は、あなたのようなアプリを検索しているユーザーに広告を自動的に照合します。',
    searchPara: '広告を関連する検索に自動的に一致させる',
    key: 'キーワード',
    keyPara:
      'キーワードは、顧客があなたのようなアプリを検索するときに使用する可能性のある関連する単語または用語です。 キーワードを使用すると、検索者はアプリをすばやく見つけることができ、アプリのインストールを促進できます。',
    inputCpt: 'キーワードセクションを有効にするには、デフォルトの最大CPT入札単価を入力します',
    addKeyPara:
      'アプリとジャンルに関連するキーワードを追加します。 追加の推奨事項を共有することで支援します。',
    upload: 'アップロード',
    addKeyManually: 'または手動でキーワードを追加します',
    negKey: '広告グループの除外キーワード（オプション）',
    supp: 'サポートされている形式：Excel、CSV',
    demAge: '人口統計年齢、性別',
    demPara: "ユーザーの年齢や性別に基づいて広告をターゲティングする",
    ageRange: '年齢層',
    cancel: 'キャンセル',
    apply: '適用する',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: '広告グループを編集する',
    adgroup: 'アドグループ',
    advance: '詳細設定',
    viewEdit: '表示と編集',
    general: '一般',
    settings: '設定',
    schedule: 'スケジュール',
    custPara: 'リーチしたいアプリの顧客を選択します',
    selectAge: '年齢範囲を選択',
    save: 'セーブ',
  },

  //Create AR
  createAR: {
    rule:"ルール",
    addRule: 'ルールを追加',
    selectDateRange:'日付範囲を選択',
    createAutoRule: '自動化ルールの作成',
    editAutoRule: '自動化ルールの編集',
    choose: '選択',
    chooseAutoRule: '自動化レベルと項目を選択してください。',
    conditions: '条件',
    actions: '行動',
    settings: '設定',
    condition: '状態',
    addCondition: '条件を追加',
    andCondition:
      '上記のすべての条件が一致する必要があるか、そのうちの1つだけが一致する必要があるかを選択します',
    checkCond: 'の条件を確認してください',
    action: 'アクション',
    selectAll: 'すべて選択',
    enterEmail: '適切なメールIDを入力してください',
    addTo: '追加',
    selectAdgroups: '広告グループ を選択します',
    selectCampaigns: 'キャンペーンを選択',
    cptBid: 'CPT入札',
    customBid: 'カスタム入札',
    matchType: 'マッチタイプ',
    addAction: 'アクションを追加',
    chckFreq: '頻度を確認する',
    ruleName: 'ルール名',
    cancel: 'キャンセル',
    step2: 'ステップ2',
    step3: 'ステップ3',
    andOrTypesText1: 'すべて一致する必要があります',
    andOrTypesText2: '1つだけ一致する必要があります',
    addToModelListNegText1: '選択したすべての広告グループ',
    addToModelListNegText2: '選択したキャンペーンのすべての広告グループ）',
    addToModelListNegText3: '選択したすべてのキャンペーン',
    matchTypesText1: '部分一致',
    matchTypesText2: '完全に一致',
    cptBidItemsText1: 'カスタムに設定',
    cptBidItemsText2: '広告グループ のデフォルトの入札',
    actionFreqsText1: '毎時',
    actionFreqsText2: '6時間ごと',
    actionFreqsText3: '12時間ごと',
    actionFreqsText4: '毎日',
    actionFreqsText5: 'カスタム時間',
    limitActionFreqsText1: '2時間ごと',
    limitActionFreqsText2: '3時間ごと',
    limitActionFreqsText3: '2日ごと',
    limitActionFreqsText4: '3日ごと',
    limitActionFreqsText5: '毎週',
    mon: '月曜',
    tue: '火曜日',
    wed: '水曜日',
    thu: '木曜日',
    fri: '金曜日',
    sat: '土曜日',
    sun: '日曜日',
    ciTimeRangesText1: '過去1時間',
    ciTimeRangesText2: '過去1日',
    ciTimeRangesText3: '過去7日間',
    ciTimeRangesText4: '過去30日間',
    ciTimeRangesText5: '先月',
    ciTimeRangesText6: 'カスタムアワー',
    ciTimeRangesText7: '日付範囲',
    allConOperationText1: 'より大きい',
    allConOperationText2: '未満',
    allConOperationText3: '範囲',
    allConOperationText4: '範囲外',
    amount: '$金額',
    budget: '予算の％パーセント',
    incBy: '増加する',
    decBy: 'によって減少します',
    setToCPA: '平均CPAに設定',
    setToCPT: '平均CPTに設定',
    value: '$値',
    change: '％ 変化する',
    between: '間',
    equalTo: 'に等しい',
    appsSelected:'選択したアプリ',
    campaignsSelected:'選択されたキャンペーン',
    adgroupsSelected:'選択された広告グループ',
    KeywordsSelected:'選択されたキーワード',
    selectField:'フィールドを選択',
    selectOne:'一つ選択してください',
    pleaseSelect:'選んでください',
    pleaseEnteraValue:'値を入力してください',
    fromValue:'値から',
    toValue:'貴ぶ',
    mustBeLesserThanToValue:'終了値より小さくなければなりません',
    mustBeHigherThanFromValue:'from 値より高くする必要があります',
    cannotBeZero:'0 にすることはできません',
    ruleAlreadyExist:'ルール名はすでに存在します',
    emailNotification:'電子メール通知',
    slackNotification: 'Slack通知',
    pause:'一時停止',
    resume:'履歴書',
    changeDailyCap:'デイリーキャップの変更',
    changeBid:'入札単価の変更',
    changeBudget:'予算の変更',
    addAsNegativeKeyword:'除外キーワードとして追加',
    createActionFor: 'アクションを作成する',
    selectAction:'アクションを選択',
    selectEmail:'メールを選択',
    addOtherEmail:'他のメールアドレスを追加',
    enterAnotherEmail:'メールIDを入力し、Enterキーを押してさらに追加します',
    giveAName:'このルールに名前を付けます',
    searchLogs: 'ルールによるログのフィルタリング',
    details: '詳細',
    PENDING: '保留中',
    SUCCESS: '成功',
    FAILED: '失敗した',
    CREATED: '作成した',
    DELETED: '削除しました',
    ENABLED: '有効',
    DISABLED: '無効',
    REACTIVATED: '再活性化',
    UPDATED: '更新しました',
    CONDITIONMATCHED : '条件が一致しました',
    CONDITIONNOTMATCHED:'条件が一致しません',
    runIndefinite:'無期限に実行',
    indefiniteNote:'注: 選択すると、この自動化ルールは指定された間隔で引き続き適用され、トリガーされた回数に関係なく有効になります。',
    sureToSave:'自動化ルールを保存しますか?',
    update:'アップデート',
    selectChannel:'チャンネルを選択',
    noDataAvailable:'データなし',
    sureToDelete:'自動化ルールを削除しますか?',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'アカウントを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    clearAll: 'すべてクリア',
    accounts: 'アカウント',
    accountSelected: '選択したアカウント',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'アプリを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    apps: 'アプリ',
    appSelected: '選択したアプリ',
    clearAll: 'すべてクリア',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: '続行するには、1つの広告グループのみを選択したままにしてください。',
    close: '閉じる',
    chooseKeywords: 'キーワードを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    campaigns: 'キャンペーン',
    expandAll: 'すべて展開',
    collapseAll: 'すべて折りたたむ',
    adGroups: '広告グループ',
    keywords: 'キーワード',
    keywordSelected: '選択したキーワード',
    clearAll: 'すべてクリア',
    excludePausedKeywords:'一時停止中のキーワードを除外',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'クリエイティブセットを追加',
    setup: 'セットアップ',
    para:
      'クリエイティブセットのテーマを説明する名前を選択してください。 App Storeの製品ページから、含めるスクリーンショットとプレビューの言語を選択します。',
    createSetName: 'クリエイティブセット名 ',
    localLang: 'ローカリゼーション言語',
    assetSelection: '資産の選択',
    assetSelectionPararaph1:
      'クリエイティブセットは、AppStoreの製品ページに表示されるスクリーンショットとアプリのプレビューで構成されています。',
    assetSelectionPararaph2: 'iPhoneのディスプレイサイズごとに、',
    assetSelectionPararaph3:
      '少なくとも1つの横向きまたは3つの縦向きのアセットを選択します。',
    assetSelectionPararaph4: 'iPadのディスプレイサイズごとに、',
    assetSelectionPararaph5:
      '少なくとも1つの横向きまたは2つの縦向きのアセットを選択します。',
    title1: 'iPhone/iPod',
    screenshotsPara1: '少なくとも選択してください',
    screenshotsPara2: '1つの風景',
    screenshotsPara3: 'または',
    screenshotsPara4: '2つの肖像画',
    screenshotsPara5: 'スクリーンショット！',
    screenshotsPara6: '少なくとも選択してください',
    screenshotsPara7: '1つの風景',
    screenshotsPara8: 'または',
    screenshotsPara9: '3つの肖像画',
    screenshotsPara10: 'スクリーンショット！',
    cancel: 'キャンセル',
    save: 'セーブ',
  },
  strategy:{
    descriptionValid:"説明は必須です",
    alphaNumeric:"英数字のみが使用できます",
    textMax:"説明は 250 文字を超えてはなりません"
  },
//DeleteStrategy
deleteStrategy: {
  deleteCamp: '戦略の削除',
  para: 'ステートジーを削除してもよろしいですか?',
  cancel: 'キャンセル',
  delete: '削除',
},
  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'キャンペーンを削除する',
    para: 'キャンペーンを削除してもよろしいですか？',
    cancel: 'キャンセル',
    delete: '削除',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'キャンペーン編集ウィザード',
    campName: 'キャンペーン名',
    app: 'アプリ',
    countriesOrRegionsPara: '1つ以上の国または地域を選択してください',
    budget: '予算',
    dailyCap: 'デイリーキャップ',
    cancel: 'キャンセル',
    save: 'セーブ',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'キャンペーンを選択',
    noSelectedCamp: '選択されたキャンペーンはありません',
    campaigns: 'キャンペーン',
    expandAll: 'すべて展開',
    collapseAll: 'すべて折りたたむ',
    selectedCampaigns: '選択したキャンペーン',
    clearAll: 'すべてクリア',
    cancel: 'キャンセル',
    apply: '適用する',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'クレジットカードを編集する',
    cardNumber: 'カード番号',
    expiryDate: '有効期限',
    cvv: 'CVV',
    saveCard: 'セーブ カード',
    updateExpiryPara: '*有効期限のみ更新できます',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'クリエイティブセットの編集',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'セットアップ',
    editCreativePara1:
      'クリエイティブセットのテーマを説明する名前を選択してください。 App Storeの製品ページから、含めるスクリーンショットとプレビューの言語を選択します。',
    creativeSetName: 'クリエイティブセット名',
    localLang: 'ローカリゼーション言語',
    assetSelection: '資産の選択',
    assetSelectionPararaph1:
      'クリエイティブセットは、AppStoreの製品ページに表示されるスクリーンショットとアプリのプレビューで構成されています。',
    assetSelectionPararaph2: 'iPhoneのディスプレイサイズごとに、',
    assetSelectionPararaph3:
      '少なくとも1つの横向きまたは3つの縦向きのアセットを選択します。',
    assetSelectionPararaph4: 'iPadのディスプレイサイズごとに、',
    assetSelectionPararaph5:
      '少なくとも1つの横向きまたは2つの縦向きのアセットを選択します。',
    viewDevice: 'デバイスの表示サイズを表示する',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: '表示',
    default: 'デフォルト',
    cancel: 'キャンセル',
    save: 'セーブ',
  },

  addIntegration: {
    invalid: '統合が無効です。削除してからもう一度追加してください'
  },

  navbar: {
    syncProgress: 'データ同期が進行中です。'
  },

  messages: {
    onHold: 'キャンペーン保留中',
    reasons: '理由'
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: '統合の削除',
    cardText: '統合を削除してもよろしいですか？',
    cancel: 'キャンセル',
    delete: '削除',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'キーワードを追加',
    keywordSuggestionsFor: 'のキーワード提案',
    on: 'オン',
    addedKeywords: 'キーワードを追加',
    total: '合計',
    add: '追加',
    cancel: 'キャンセル',
    apply: '適用する',
    matchExact: 'ちょうど',
    matchBroad: 'ブロード',
    searchHere: '検索',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'キーワードを追加',
    selectTarget: 'ターゲットを選択',
    h4Camp: 'キャンペーン：',
    selected: '選択済み',
    change: '変化する',
    h4Adgroups: '広告グループ:',
    maxCPTBid: '最大CPT入札：',
    keywordSuggestionsFor: 'のキーワード提案',
    on: 'オン',
    recommendedKeywords: 'おすすめのキーワード',
    addedKeywords: 'キーワードを追加',
    add: '追加',
    total: '合計',
    cancel: 'キャンセル',
    apply: '適用する',
    ok: 'OK',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'キーワードを追加',
    selectTarget: 'ターゲットを選択',
    h4Camp: 'キャンペーン：',
    selected: '選択済み',
    change: '変化する',
    h4Adgroups: '広告グループ:',
    maxCPTBid: '最大CPT入札：',
    keywordSuggestionsFor: 'のキーワード提案',
    on: 'オン',
    recommendedKeywords: 'おすすめのキーワード',
    addedKeywords: 'キーワードを追加',
    add: '追加',
    total: '合計',
    cancel: 'キャンセル',
    apply: '適用する',
    ok: 'OK',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: '除外キーワードを追加する',
    add: '追加',
    total: '合計',
    cancel: 'キャンセル',
    save: 'セーブ',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: '入札額',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: '続行するには、1つの広告グループのみを選択したままにしてください。',
    close: '閉じる',
    chooseAdGroup: '広告グループを選択',
    cancel: 'キャンセル',
    apply: '適用する',
    campaigns : 'キャンペーン',
    expandAll: 'すべて展開',
    collapseAll: 'すべて折りたたむ',
    adGroups : '広告グループ',
    adGroupSelected : '選択された 広告グループ',
    clearAll : 'すべてクリア'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : '除外キーワードを追加する',
    selectTarget : '1. ターゲットを選択',
    addNegKeyword : '除外キーワードを追加する',
    paragraph1 : '除外キーワードは、キャンペーンまたは広告グループに追加できます。 下のボックスからお選びください。',
    paragraph2 : 'どのキャンペーンにキーワードをアップロードしますか？',
    selectCampaign : 'キャンペーンを選択',
    paragraph3 : 'どの広告グループにキーワードをアップロードしますか？',
    selectAdgroup : '広告グループを選択',
    cancel: 'キャンセル',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'キャンペーン：',
    selected : '選択済み',
    change: '変化する',
    paragraph4 : ' 広告グループの除外キーワードは、この広告グループにのみ適用されます。 除外キーワードをすべての広告グループに適用する場合は、キャンペーンレベルで設定します。',
    paragraph5 : ' 広告の掲載結果を分析する前にキャンペーンを実行する時間を与えて、どのキーワードが効果的でないかを確認することを検討してください。 追加する除外キーワードが多すぎると、広告の露出が制限される可能性があります。',
    addedKeywords : 'キーワードを追加',
    add: '追加',
    total: '合計',
    save: 'セーブ',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Slackチャネルを追加する',
    sno: 'S.No'
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'キャンセル',
ok : 'OK',
confirm:'確認'
  },

  //HelloWorld
  helloWorld : {
welcome : 'Vuetifyへようこそ',
para1 : '他のVuetify開発者とのヘルプとコラボレーションについては、',
para2 : 'オンラインでご参加ください',
discord : '不和コミュニティ',
h2 : "次は何ですか？",
impLinks : '重要なリンク',
ecosystem : '生態系',
  },

  //InfoAlert
  infoAlert : {
ok : 'OK',
  },

  successMsg: {
    campaignUpdate: 'キャンペーンが更新されました。',
    campaignMovedMsg: 'ステータスが変更されました。キャンペーンは他のタブに移動しました',
    campaignMoveFailMsg: "キャンペーンのステータスを変更できませんでした。",
    adgroupUpdateFailMsg: "広告グループのステータスを更新できませんでした。",
    addgroupUpdatedMsg: 'ステータスが変更されました。広告グループが他のタブに移動しました',
    keywordMovedMsg: 'ステータスが変更されました。キーワードが他のタブに移動しました',
    keywordMoveFailMsg: "キーワードのステータスを更新できませんでした。",
    creativeAddedMsg: 'クリエイティブセットを追加しました。',
    campaignSavedMsg: 'キャンペーンはドラフトとして保存されました。',
    campaignSaveFailMsg: '現在、キャンペーンのドラフトを保存できません。もう一度やり直してください！',
    keyAddedMsg: 'キーワードを追加しました。',
    signinFailMsg: 'サインインできません。もう一度やり直してください。',
    deleteMsg: '正常に削除されました',
    deleteFailMsg: '削除できません。もう一度やり直してください！',
    dataUpdateMsg : '更新されたデータ',
    dataUpdateFailMsg: 'データの更新中にエラーが発生しました',
    AutoDeleteMsg: '自動化ルールが正常に削除されました',
    requestFailMsg: 'リクエストを処理できません。後でお試しください',
    AutoRuleDisSuccessMsg: '自動化ルールが正常に無効化されました',
    AutoRuleEnableMsg: '自動化ルールが正常に有効化されました',
    SCAutoRuleDisSuccessMsg: 'スクリプト化されたルールが正常に無効になりました',
    SCAutoRuleEnableMsg: 'スクリプト化されたルールが正常に有効になりました',
    bidChangeSuccessMsg: '入札金額が正常に変更されました。',
    wentWrongMsg: 'Something went wrong.',
    integrateSuccessMsg : '統合が正常に追加されました',
    userCreateMsg: 'ユーザーが正常に作成されました',
    emailExistMsg: 'メールは既に存在します',
    errorCreateUser: 'ユーザーの作成中にエラーが発生しました',
    inviteFailMsg: '招待リンクを電子メールに送信中にエラーが発生しました',
    failTryLaterMsg: '何かが間違っている。後でお試しください',
    adgroupAddedMsg: '広告グループ が追加されました。',
    adgroupUpdatedMsg: '広告グループ が更新されました。',
    ruleUpdatedMsg: 'ルールが正常に更新されました',
    ruleUpdateFailMsg: 'ルールの更新に失敗しました。後でお試しください',
    rCreatedMsg: 'ルールが正常に作成されました',
    rCreationFailMsg: 'ルールの作成に失敗しました。後でお試しください',
    assetDataFailMsg: 'アセットの詳細を取得できません。もう一度やり直してください！',
    deleteCampFailMsg: 'キャンペーンの削除中にエラーが発生しました',
    CampDeletedMsg: 'キャンペーンが削除されました',
    creativeUpdated: 'クリエイティブセットを更新しました。',
    keyAdded: 'キーワードを追加しました。',
    negaKeyAddedMsg: '除外キーワードが広告グループに追加されました。',
    negKeyAddCamp: 'キャンペーンに除外キーワードが追加されました。',
    errorDeleteKey: 'キーワードの削除中にエラーが発生しました',
    keyDeleteS: 'キーワードが削除されました。キーワードが削除されたキーワードに移動しました',
    syncStarted: '同期が開始されました。',
    firstSyncStarted:'Apple Search Adsの統合が完了しました！<br> <br> <p style="text-align: justify; color: inherit;">ASAデータが初めて同期されるまで、数分お待ちください。この最初の同期が完了するとすぐに、アプリとASAデータが入力されます。ページの右上端で同期の進行状況を確認します。<p>',
    integrationWarning:'このMMPと統合する前に、同期されたAppleSearchAds統合が必要です。上記のAppleSearchAds統合を追加し、最初の同期が完了するのを待ってから、このMMPと統合してください',
    AdDeletedMsg: '広告を削除しました',
    adUpdate: '広告を更新しました',
    deleteAdFailMsg: '広告の削除中にエラーが発生しました',
    deleteStrategyFailMsg: '戦略の削除中にエラーが発生しました',
    StrategyDeletedMsg: '戦略が削除されました',
  },

  createCampaignRules: {
    selectCampGrp: 'キャンペーングループを選択してください！',
    selectApp: 'アプリを選択してください！',
    selectCountry: '国を選択してください！',
    campRequired: 'キャンペーン名が必要です！',
    campNameSize: 'キャンペーン名は200文字未満である必要があります',
    adGroupSize: '広告グループ名は200文字未満である必要があります',
    keywordsize: 'キーワードは80文字を超えることはできません',
    budgetRequired: '予算額が必要です！',
    validBudget: '有効な予算を入力してください！',
    groupRequired: '広告グループ名が必要です！',
    defMaxCptRequired: 'デフォルトの最大CPT入札が必要です！',
    validCptBid: '有効なCPT入札を入力してください！',
    validDailyCap: '有効な1日あたりの上限を入力してください。',
    validCpaGoal: '有効なCPA目標を入力してください。',
    selectDevices: 'デバイスを選択してください！',
    fNamereq: '名が必要です',
    fNameGrt: '名は12文字を超えてはなりません',
    textMax: '50文字を超えてはなりません',
    textMin: '2文字以上である必要があります',
    fNameValid: '有効な名を入力してください！',
    lNameGrt: '姓は12文字を超えてはなりません',
    lNameValid: '有効な名前を入力してください！',
    phoneReq: '電話が必要です',
    cmpniNameReq: '会社名が必要です',
    jobTitleReq: '役職が必要です',
    emailReq: 'メールが必要です',
    emailValid: '電子メールは有効である必要があります',
    selectCamp: 'キャンペーンを選択',
    cardNoReq: 'カード番号が必要です',
    cardNo16Digit: 'カード番号は数字で16桁の長さである必要があります',
    expiryMonthReq: '有効期限の月と年が必要です',
    expiryMMYY: '有効期限はMM / YYYYである必要があります-例： 2023年7月',
    invalidExpiry: '有効期限が無効です',
    invalidMonth: '無効な有効期限',
    cvvReq: 'カードCVVが必要です',
    cvvNum3Digit: 'カードのCVVは数値で、長さが3桁である必要があります',
    specialCharNotAllowed:'特殊文字は使用できません',
    onlySinglespaceAllowed:'単一の空白のみが許可されます',
    invalidPhoneFormat:'電話番号の形式が無効です'
  },

  settings: {
    profileInfo: 'プロファイル情報',
    updateProf: 'プロファイルの更新',
    payMethod: '支払方法',
    addCredit: 'クレジットカードを追加する',
    cardNo: 'カード番号',
    invoiceHistory: '請求書の履歴',
    invoiceId: 'Invoice_Id',
    dueDate: '期日',
    paidAt: 'Paid_At',
    recure: '繰り返し',
    total: '合計',
    status: '状態',
    active: 'アクション',
    cardId: 'Card_Id',
    type: 'タイプ',
    expiryMonth: '有効期限月',
    expiry: '有効期限',
    action: 'アクション',
    addCard: '支払い',
    settings: '設定',
    accountSetting: 'アカウント設定',
    payment: '支払い',
    primaryPayment: 'このカードを主な支払い元として作成します',
    successTitle: "成功！！！",
    successMsg:"お支払い情報への変更が正常に更新されました",
    NoSuccessTitle: "変化は検出されませんでした!!!",
    NoSuccessMsg:"既存の請求情報に変更は見つかりませんでした。"

  },

  dataRequest: {
    yourDataRequest: 'あなたのデータリクエスト',
    remain: '残り',
    complete: '完了',
    Fetching: 'フェッチング',
    Fetched: 'フェッチ済み',
    appForKeyword: ' キーワードのアプリ- ',
    keywordsForApp: ' アプリのキーワード - ',
    appsForCategory: ' カテゴリのトップアプリ - ',
    competitorsForApp: ' アプリの推奨競合他社 - ',

  },

  users: {
    manageComp: '会社を管理する',
    comp: '企業',
    addDisManageComp: 'すべての会社を追加、無効化、管理する',
    createCompAdmin: '新しい会社の管理者を作成する',
    enterEmail: 'メールアドレスを入力して',
    allCompAdmin: 'すべての会社の管理者',
    rowPerPages:"キーワード"
  },

  manageSubscription: {
    trialPeriod: "あなたは試用期間中です",
    trialPeriodExpire: "試用期間は",
    updateCard: "カードを更新する",
    subscriptionDetail: "サブスクリプションの詳細",
    p1: "次の請求",
    p2: "ここから請求先住所、支払い履歴、その他の詳細を管理します",
    planListing: 'プランリスト',
    furtherPlan: '計画を選択してさらに先に進む',
    generalPlan: '全般的',
    month: '月',
    tryIt: '好きなだけ試してみてください',
    createCampaign: 'キャンペーンを作成する',
    keyAnalysis: 'キーワード分析',
    automationRule: '自動化ルール',
    keyOpt: 'キーワードの最適化',
  },

  billingDetails: {
    billInfo: '課金情報',
    compName: '会社名',
    addLine: '住所欄',
    city: '市',
    zip: 'ジップ',
    vat: 'バット 番号',
    updateBillAdd: '請求先住所を更新する'
  },

  dateRangePresets: {
    today: '今日',
    yesterday: '昨日',
    weekToDate: '週から現在まで',
    lastWeek:'先週',
    lastSevenDays:'過去7日間',
    monthToDate:'過去1カ月間',
    lastMonth:'先月',
    lastThirtyDays:'過去30日間'
  },

  chartHeaders: {
    accounts: 'アカウント',
    apps: 'アプリ',
    campaign: 'キャンペーン',
    adgroup: '広告グループ',
    keyword: 'キーワード',
    creativeset: 'クリエイティブセット',
    searchterm: '検索ワード',
  },

  goals: {
    selectGoal: '目標を選択',
    selectEventType: 'イベントの種類を選択',
    selectGoalRule: '目標タイプを選択してください',
    selectEventRule: 'イベントの種類を選択してください',
    goalNameRule: '目標名を入力してください',
    manageGoals: '目標を管理する',
    goalDesc: 'あなたはここからあなたの目標を管理することができます',
    createGoal: '目標を作成する',
    editGoal: '目標の編集',
    chooseGoal: '目標タイプを選択する',
    chooseEvent: 'イベントを選択',
    goalName: '目標名',
    close: '近い',
    update: 'アップデート',
    create: '作成',
    name: '名前',
    app: 'アプリ',
    appId: 'アプリ',
    type: "タイプ",
    events: "イベント",
    createdOn: "作成日",
    createdBy: "によって作成された",
    action: "アクション",
    revenue: "収益",
    purchase: "購入",
    subscribe: "申し込む",
    userEngagement: "ユーザーエンゲージメント",
    levelComplete: "レベル完了",
    regComp: "登録完了",
    tutComp: "チュートリアル完了",
    startTrial: "トライアルを開始",
    appLaunch: "アプリの起動",
    userAction: "ユーザーアクション",
    addToCart: "カートに追加",
    addToWishlist: "ほしい物リストに追加する",
    checkoutStart: "チェックアウト開始"
  },
  
  campaignGenerator: {
    decisionMessage: 'テンプレートを削除してもよろしいですか?',
    addKeyPlaceholder: 'カンマ区切りのテキストを入力し、Enter キーを押すか、[追加] をクリックします',
    showDraft: '下書きを表示',
    pageHeading : 'キャンペーンジェネレータ',
    createCampaignHeading: '自動キャンペーン構成',
    Segmentation: 'セグメンテーション',
    Categories: 'カテゴリ',
    Brand: 'ブランド',
    Category: 'カテゴリ',
    Discovery: 'ディスカバリー',
    Competitor: '競合他社',
    Custom: 'カスタム',
    Criteria: '基準',
    pleaseSelectCriteria: '基準を選択してください',
    pleaseEnterValue: '値を入力してください',
    Value: '値',
    To: '終わり',
    From: '始める',
    FromTo10: '最初の値との差が10より大きい値を入力してください',
    customSlugHeading: 'カスタムキャンペーンスラッグ：',
    customSlugPH: 'スラッグ名を入力してください',
    customSlugAdd: '追加',
    customSlugDup: '重複したスラッグ名',
    SegmentationRules: 'セグメンテーションルール',
    SegmentationDesc: 'キャンペーンには、アプリを宣伝するための共通のテーマまたは戦略に焦点を当てた予算と広告グループがあります。',
    age: '年齢',
    geo: '場所',
    selectCustomerType: '顧客タイプを選択してください',
    selectGender: '性別を選択してください',
    selectAge: '年齢を選択してください',
    keyManually: 'キーワードを手動で追加',
    Summary: '要約',
    SummaryText: 'キャンペーンと広告グループの要約を確認してください',
    TotalCampaigns: '合計キャンペーン',
    TotalAdgroups: '合計広告グループ',
    CampaignTitle: 'キャンペーンタイトル',
    keysReady: 'キーワードの準備ができました',
    custom: 'カスタム',
    keysFetch: 'キーワードのフェッチ',
    Campaignsarecreated: 'キャンペーンが作成されました',
    Creatingcampaigns: 'キャンペーンの作成',
    Viewallitems: 'すべてのアイテムを表示',
    Adgroupsarecreated: '広告グループが作成されます',
    Creatingadgroups: '広告グループの作成',
    Keywordsarecreated: 'キーワードが作成されます',
    Creatingkeywords: 'キーワードの作成',
    ready: '準備完了',
    CreatedBy: '作成者',
    CreatedOn: '作成日',
    Campaigngenerationdetails: 'キャンペーン生成の詳細',
    footerProps: {
      itemsPerPageText: 'ページあたりの行数',
      itemsPerPageAllText: 'すべて',
      showFirstLastPage: true,
    },
    generateCampaigns: 'キャンペーンを生成する',
    setDefaultValues: 'デフォルト値の設定',
    setDefaultValuesSub: '対象の場所のデフォルトの予算設定を入力してください。',
    nokeyPara: 'キーワードはキーワード分析テクノロジーを使用して自動的に生成されるため、カテゴリ、ディスカバリー、または競合他社のキャンペーンにはキーワード入力は必要ありません。',
    waitingToSync: '同期を待っています',
    campSyncHour:'キャンペーンは1時間以内にAppleに同期されます。',
    //syncInProgress: '同期中',
    syncInProgress: '同期中',
    syncInProgressFewMin: '同期は進行中であり、数分以内に完了する必要があります。',
    syncComplete: '同期完了',
    allSyncCompleted: 'すべてのキャンペーンが正常に同期されました。',
    campNameUniq: '固有のキャンペーン名を入力してください',
    campNamExists: '同じ名前のキャンペーンが既に存在します',
    adNameUniq: '固有の広告グループ名を入力してください',
    saveAsDraft: '下書きとして保存',
    draftHeading: 'キャンペーン テンプレート ドラフト',
    draftSearch: '探す',
    headerOrgName: '組織名',
    headerAppName: 'アプリ名',
    headerResume: '履歴書'
  },

  appSetting: {
    manage: '管理',
    setDefaultValues: 'デフォルト値を設定する',
    edit: '編集',
    update:'アップデート',
    noRecords: '該当する記録は見つかりません',
    searchByCountry:'国で検索'
  },

  sr: {
    pageHeading: '定期レポート',
    createScheduleReport: '定期レポートの作成',
    srDesc: '予定レポートを使用すると、自分で定義した日時にレポートをメールまたはスラックできます',
    createSR: '新しいレポートのスケジュール',
    for: 'レポート',
    type: '繰り返し',
    dataDays: '最後のデータ',
    reportNameExists: '名前は既に存在します!',
    enterReportName: '名前を入力してください',
    repeatMode: 'リピートタイプ',
    pleaseSelectFor: 'レポート レベルを選択してください',
    pleaseSelectType: 'リピートタイプを選択してください',
    pleaseSelectDay: '曜日を選択してください',
    pleaseSelectTime: '時間を選択してください',
    noRepeat: '繰り返さない',
    custom: 'カスタム',
    daily: '毎日',
    weekly: '毎週',
    monthly: '毎月',
    selectOrg: '組織を選択してください',
    selectGoal: '目標を選択してください',
    goalsSelected: '選択された目標',
    orgsSelected: '組織が選択されました',
    scheduleReport: '定期レポート?',
    scheduleReports:'レポートのスケジュール',
    reportOf:'の報告',
    selectTime:'時間を選択',
    downloadReport:'レポートをダウンロード',
    addMore:'さらに追加',
    scheduleReport1:'スケジュール レポート',
    selectSlackChannel:'Slack チャンネルを選択',
    emailNoSpaces:'メールにスペースを含めることはできません',
    emailWarning:'少なくとも 1 つの電子メールを選択するか、少なくとも 1 つの外部電子メール ID を下に入力してください',
    errorDuringSave:'定期レポートの保存中にエラーが発生しました',
    scheduleSuccess:'正常にスケジュールされました',
    scheduleSaveWarning:'このレポートをスケジュールしてもよろしいですか?'
  },
  //Delete Ad
  deleteAds: {
    deleteAd: '広告を削除',
    para: '広告を削除してもよろしいですか?',
    cancel: 'キャンセル',
    delete: '消去',
    ok:'わかった'
  },
   //EditAds
   editAd: {
    editAdWizard: '広告編集ウィザード',
    adName: '広告名',
    cancel: 'キャンセル',
    save: '保存'
  },
  createAdRules: {
    adRequired: '広告名が必要です!',
    adNameSize: '広告名は 200 文字未満にする必要があります'
},
  common : {
    save: '保存',
    cancel: 'キャンセル',
    noDataText: '表示するデータがありません',
    days: '日',
    time: '時間',
    na: 'な',
    goal: 'ゴール',
    deleteConfirmText: 'これを削除してもよろしいですか ',
    createConfirmText: 'この新しいの作成に進みます ',
    updateConfirmText: 'これを更新してもよろしいですか ',
    dataTableFooterProps: {
      itemsPerPageText: 'ページあたりの行数',
      itemsPerPageAllText: 'すべて',
      showFirstLastPage: true,
    },
  },

  welcomeOnboard: {
    yes: 'はい',
    no: '番号',
    continue: '継続する',
    welcome: '検索広告メイブンへようこそ',
    description: '検索広告 Maven へようこそ。統合を設定することから始めましょう。 Apple ID が必要です。ASA が 2 要素認証を使用している場合は、関連付けられたデバイスが必要です。',
    getStart: 'スタートを切る',
    setupLater: 'アプリケーションに移動して後でセットアップする',
    setup: '設定',
    createIntegration: '統合の作成',
    createIntDescription: ' Apple ID を入力して、Search Ads Maven と Apple Search Ads 間の双方向同期を作成します。 MMP パートナーがいる場合は、これらの統合もここで設定できます。まず、Apple Search Ads データをインポートすることから始めましょう"',
    enterAppleId: 'Apple ID を統合する',
    success: '成功',
    stepper: {
      title: '同期が進行中です!',
      descreption1: 'Apple Search Ads から履歴データをインポートしています。データの量によっては、完了するまでに数時間かかる場合があります。この同期中はいつでも右上の進行状況インジケーターを確認できます。同期が完了したら、統合ページにアクセスしてください。',   
      descreption2: 'それまでの間、プラットフォームに慣れましょう。',
    },
    stepperTwo: {      
      title: 'MMP 統合',
      descreption:'MMP は、フルファネルのアプリ エンゲージメント データで、さまざまなマーケティング チャネルやパートナーの広告費の効果を測定および比較できるサードパーティの測定ツールです。ここで役立つ MMP の最大の機能は、顧客がアプリ ストアを離れてアプリの使用を開始した後に発生するイベントに対して、ASA のパフォーマンスを分析および最適化することです。',
      successfullIntegrations:'統合の成功',
      receivedTypes: '受信イベントの種類'
      },
    startGuide: 'スタートガイド',
    syncMsg: {
      your: '君の',
      syncWasLess: '同期は未満でした',
      moreTime: '前に。もう一度同期するまで、しばらくお待ちください。'
    },
    goToApplication: 'アプリケーションに移動',   
    overview: {
      title: '概要へようこそ',
      description: '検索広告の達人へようこそ!統合を設定することから始めましょう。 Apple ID が必要です。ASA がデュアル ファクタ認証を使用している場合は、関連付けられたデバイスが必要です。',
      popupSecond: {
        title: 'Search Ads Maven をご利用いただきありがとうございます',
        description: '統合に関してご質問や問題がある場合は、いつでもサポート チームにお問い合わせください。' 
      }
    },
    adsManager: {
      title: '広告マネージャへようこそ',
      description: 'ここでは、アプリケーション、キャンペーン、広告グループ、キーワードなどの詳細な分析とレポートを確認できます。また、キャンペーンを手動で作成して、プロセスから当て推量を取り除きます。 [広告マネージャ]セクションでは、キャンペーン、広告グループ、キーワード、除外キーワードを作成することもできます。'
    },
    keywordAnalysis: {
      title: ' キーワード分析へようこそ',
      description: 'ここでは、キャンペーン キーワードの全体的な効果を評価し、アプリに提案された競合他社を確認して、競合他社のキーワードを征服することができます。また、キャンペーンに補助キーワードをすばやく追加することもできます。'
    },
    keywordOptimization:{
      title: 'キーワード最適化へようこそ',
      description: 'ここでは、ASA が推奨する最小入札単価と最大入札単価の範囲にないキーワードの入札額を迅速かつ効果的に最適化できます。 ASA の推奨事項とともに含まれるアプリのイベントに基づいて、目標の指標を見つけます。'
    },
    integrations: {
      title: '統合へようこそ',
      description: 'ここで、ASA 統合を追加し、サードパーティの MMP 統合を定義し、通信チャネルを設定できます。'
    },
    automation: {
      title: ' 自動化へようこそ',
      description: 'ここでは、組織、アプリケーション、キャンペーン、広告グループ、およびキーワードの自動化ルールを作成できます。'
    },
    goals: {
      title: 'ゴールへようこそ ',
      description: 'ここでは、サードパーティの MMP イベントを使用して、収益、エンゲージメント、およびユーザー アクティビティに基づいてキャンペーンの目標を作成する方法を定義できます。 '
    },
    campaignGenerator: {
      title: 'キャンペーン ジェネレーターへようこそ',
      description: 'ここでは、手動でキャンペーンをすばやく作成するか、自動化されたキャンペーン ジェネレーターを使用してプロセスから当て推量を取り除くことができます。'
    },
    strategicView: {
      title: '戦略的ビューへようこそ',
      description: 'Search Ads Maven 戦略を使用して Apple Search Ads の複雑さを克服します。自動化と明確なマイルストーンを利用して、キャンペーン、広告グループ、キーワードを合理化されたエンティティにグループ化します。構築、実行、成功の測定はすべて自分で行うことができます。簡素化、最適化、勝利。'
    },
    thirdParty: {
      popupOne: {
        title: 'サードパーティのイベント データを統合しますか? ',
        description: ''
      },
      popupTwo: {
        title: '',
        description: 'サード パーティのイベント データは、ASA の同期が完了するまでインポートできません。同期が 100% に達したら、このページに戻ってください。 '
      },
      popupThree: {
        title: '',
        description: 'ASA 情報が Search Ads Maven 内に入力されるまでに時間がかかるため、それまでの間、プラットフォームに慣れましょう'
      },    
    }
  }
}