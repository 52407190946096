//German
export const de = {
  pages: {
    overview: 'Überblick',
    adsManager: 'Anzeigenmanager',
    kai: 'Keyword-Analyse',
    keyOpt: 'Stichwort-Optimierung',
    teamMgmt: 'Team',
    integrations: 'Integrationen',
    automationRule: 'Automatisierung',
    goals: 'Ziele',
    campaignGenerator: 'Kampagnengenerator',
    appSettings: 'App-Einstellungen',
    kar: 'Anfragen zur Keyword-Analyse',
    manualCampaign:'Manuelle Kampagne hinzufügen',
    automatedCampaign:'Automatisierte Kampagne hinzufügen',
  },
  menu: {
    settings: 'die Einstellungen',
    signOut: 'Ausloggen',
    manageSubscription: 'Abonnement verwalten',
    orgs: 'Organisationen'
  },
  buttons: {
    syncData: 'Neueste Daten Synchronisieren',
    createCampaign: 'Kampagne erstellen',
    filter: 'Filter',
    reset: 'Zurücksetzen',
    apply: 'Anwenden'
  },
  labels: {
    charts: 'Diagramme',
    selectDs: 'Datensätze auswählen',
    totalSummary: 'Gesamtzusammenfassung',
    spend: 'Verbringen',
    search: 'Suche',
    searchByKeySearchMsg: 'Geben Sie einen Text ein und drücken Sie die Eingabetaste',
    competeSearchMsg : 'Wählen Sie eine App aus oder suchen Sie danach',
    selectCategoryMsg: 'Wählen Sie eine Kategorie aus',
    selectCategoryType: 'Art auswählen',
    matchType: 'Übereinstimmungstyp',
    filterItem: 'Geben Sie hier ein, um die folgenden Elemente zu filtern',
    cusType: 'Kundentypen',     //labels both
    createRule: 'Regel erstellen für',
    fromSelected: 'Von Ausgewählt',
    mmYY: 'MM/JJJJ'  ,
    enterKey: 'Schlüsselwörter eingeben',
    selectLevel: 'Stufe auswählen',
    org: 'Organisation(en)',
    app: 'Anwendung(en)',
    camp: 'Kampagne(n)',
    adGroup: 'Anzeigengruppe(n)',
    key: 'Schlüsselwörter)',
    searchTerm: 'Suchbegriff(e)',
    yourApps: 'Ihre Apps',
    suggestedApps: 'Vorgeschlagene Apps',

  },
  dropdowns: {
    noData: 'Keine Daten verfügbar',
    daily: 'TÄGLICH',
    weekly: 'WÖCHENTLICH',
    monthly: 'MONATLICH'
  },
  tabs: {
    accounts: 'Konten',
    apps: 'Anwendungen',
    campaigns: 'Kampagnen',
    adgroups: 'Anzeigengruppen',
    keywords: 'Schlüsselwörter',
    creativeSets: 'Kreativsets',
    searchTerms: 'Suchbegriffe',
    negKeywords: 'Negative Schlüsselwörter',
    ad: 'Anzeige'
  },
  summary: {
    taps: 'Wasserhähne',
    impressions: 'Impressionen',
    ttr: 'TTR',
    newDownloads: 'Neue Downloads',
    reDownloads: 'Re-Downloads',
    installs: 'Installiert',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  accountHeaders: {
    account: 'Konto',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  appsHeaders: {
    appName: 'Anwendung',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstall: 'Attr Installieren',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRevenue: 'Tore Einnahmen',
    goalsRoas: 'Tore ROAS',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    account: 'Konto',
    categoryRank: 'Kategorie Rang',
    appId: 'Anwendung ICH WÜRDE',
    goalAMPU: 'Tore AMPU',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  campaignHeaders: {
    campaign: 'Kampagne',
    campaignStatus: 'Kampagne Status',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRoas: 'Tore ROAS',
    storefronts: 'Schaufenster',
    account: 'Konto',
    displayStatus: 'Anzeige Status',
    appName: 'Anwendung Name',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    delete: 'Löschen',
    totalBudget: 'Gesamtetat',
    dailyCap: 'Tagesobergrenze',
    supplySource:'Typ',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  campaignDraftHeaders: {
    campaignName: 'Kampagne Name',
    countries: 'Länder',
    budgetAmount: 'Budget Menge',
    resume: 'Fortsetzen',
  },
  adGroupHeaders: {
    adgroup: 'Anzeigengruppe',
    adgroupStatus: 'Anzeigengruppe Status',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRoas: 'Tore ROAS',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    displayStatus: 'Anzeige Status',
    account: 'Konto',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  keyHeaders: {
    keyword: 'Stichwort',
    keywordStatus: 'Stichwort Status',
    bidAmount: 'Bieten Menge',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRevenue: 'Tore Einnahmen',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    adgroupStatus: 'Anzeige Status',
    organicRank: 'Organischer Rang',
    appName: 'Anwendung Name',
    difficultyScore: 'Schwierigkeit Ergebnis',
    popularity: 'Popularität',
    keywordId: 'Stichwort ICH WÜRDE',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    suggestedMinBid: 'ASA Empfohlen Minimum Bieten',
    suggestedMaxBid: 'ASA Empfohlen Maximal Bieten',
    suggestedBidAmount: 'ASA-Gebotsvorschlag',
    bidStrength: 'Bieten Stärke',
    latOff: 'LAT Off-Installationen',
    latOn: 'LAT bei Installationen',
    matchType: 'Übereinstimmungstyp',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  adsHeaders: {
    ads: 'Anzeige',
    creativeType: 'Creative-Typ',
    creativeSetStatus: 'Status',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRevenue: 'Tore Einnahmen',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    adgroupStatus: 'Anzeige Status',
    account: 'Konto',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    action:'Aktion',
    delete: 'Löschen',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  creativeHeaders: {
    creativeSet: 'Kreativsets',
    creativeSetStatus: 'Status',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    attrInstallRate: 'Attr Installieren Bewertung',
    cpi: 'CPI',
    goals: 'Tore',
    costPerGoal: 'Kosten Pro Tor',
    goalRate: 'Tor Bewertung',
    revenuePerGoal: 'Einnahmen Pro Tor',
    goalsRevenue: 'Tore Einnahmen',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    adgroupStatus: 'Anzeige Status',
    account: 'Konto',
    appId: 'Anwendung ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  searchTermsHeader: {
    searchTerm: 'Suchbegriff Text',
    spend: 'Verbringen',
    averageCpa: 'Durchschnittlich CPA',
    averageCpt: 'Durchschnittlich CPT',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    installs: 'Installiert',
    ttr: 'TTR',
    conversionRate: 'Umwandlung Bewertung',
    newDownloads: 'Neue Herunterladen',
    reDownloads: 'Re-Herunterladen',
    storefronts: 'Schaufenster',
    campaignStatus: 'Kampagne Status',
    adgroupStatus: 'Anzeige Status',
    keyword: 'Stichwort',
    keywordId: 'Stichwort ICH WÜRDE',
    appId: 'Anwendung ID',
    campaignId: 'Kampagne ICH WÜRDE',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },
  negativeHeaders: {
    keyword: 'Stichwort',
    matchType: 'Spiel Art',
    status: 'Status',
    adgroupId: 'Anzeigengruppe ICH WÜRDE',
    campaignId: 'Kampagne ICH WÜRDE',
    deleted: 'Gelöscht',
    keywordId: 'Stichwort ICH WÜRDE',
    modificationTime: 'Änderung Zeit'
  },
  filter: {
    account: 'Wählen Konto',
    app: 'Wählen Anwendung',
    goal: 'Wählen Sie Ziel aus',
    campaign: 'Wählen Kampagne',
    adgroup: 'Wählen Anzeigengruppe',
    ad: 'Wählen Sie Anzeige aus'
  },
  subTabs: {
    all:'Alle',
    live: 'Wohnen',
    paused: 'Angehalten',
    deleted: 'Gelöscht',
    draft: 'Entwurf',
    campaigns: 'Kampagnen',
    adgroups: 'Anzeigengruppen',
    keywords: 'Schlüsselwörter',
    creativeSet: 'Kreativsets',
    negKey: 'Negativ Schlüsselwörter'
  },
  popups: {
    add: 'Hinzufügen',
    integration: 'Integration',
    adgroup: 'Anzeigengruppe',
    keywords: 'Schlüsselwörter',
    creativeSet: 'Kreativsets',
    negKey: 'Negativ Schlüsselwörter',
    campaigns:'Kampagnen'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: 'Mitglieder verwalten',
    invite: 'Einladen und Verwalten von Teammitgliedern',
    add: 'Mitglied hinzufügen',
    first: 'Vorname',
    last: 'Nachname',
    phone: 'Telefon',
    email: 'Email',
    job: 'Berufsbezeichnung',
    allMembers: 'Alle Mitglieder',
    processing: 'wird bearbeitet...',
    ok: 'OK',
    inviteStatus: 'Einladungsstatus',
    isDisabled: 'Ist behindert',
    company: 'Name der Firma'
  },

  //Add Integration Page
  integration: {
    assPart: 'Assoziierte Partner',
    apple: 'Apple Search Ads',
    slack: 'Slack',
    itunes: 'Itunes',
    tenjin: 'Tenjin',
    kochava: 'Kochava',
    adjust: 'Adjust',
    appsFlyer: 'AppsFlyer',
    singular: 'Singular',
    communication:'Kommunikation',
    mmp:'Mobile Messpartner',
    completeInt:'Abgeschlossene Integrationen',
    receivedEventType:'Empfangene Ereignistypen',
    waitForEvents:'Warten auf Ereignisse',
    integrateWith:'Integrieren mit',
    intStep:'Integrationsschritte',
    integrate:'Integrieren',
    followIntSteps:'Befolgen Sie diese Integrationsschritte',
    //kochava steps
    kochavaStep1:'1. Greifen Sie unter https://go.kochava.com/apps auf Ihre Kochava-Apps zu.',
    kochavaStep2:'2. Wählen Sie Partnerkonfiguration aus dem Kontextmenü Ihrer App.',
    kochavaStep3:'3. Klicken Sie auf der Seite Partnerkonfiguration auf Konfiguration hinzufügen.',
    kochavaStep4:'4. Wählen Sie „Search Ads Maven – iOS“ im Menü „Medienpartner“ und klicken Sie auf „Los“.',
    kochavaStep5:'5. Wählen Sie Erstellen aus dem Kontextmenü jedes Ereignisses, das Sie an Search Ads Maven syndizieren möchten.',
    kochavaStep6:'6. Verwenden Sie den hier bereitgestellten API-SCHLÜSSEL und die App Store-ID für jede Postback-Erstellung.',
    kochavaStep7:'7. Warten Sie mindestens 24 Stunden, bis Ereignisse von Search Ads Maven erkannt werden. Beachten Sie, dass nur Ereignisse erkannt werden, die Apple Search Ads-Kampagnen zugeordnet sind.',
    kochavaStep8:'8. Erstellen Sie ein Ziel für jedes von Search Ads Maven erkannte Ereignis. Sobald Ziele erstellt wurden, sind MMP-Ereignisdaten wie Anzahl und Umsatz im Dashboard verfügbar.',

    //branchSteps
    branchStep:'1. Verwenden Sie den folgenden API-Schlüssel und die App Store-ID in Ihrem Branch-Dashboard.',

    //customSteps
    customStep:'1. Verwenden Sie den folgenden API-Schlüssel und die folgende App Store-ID in Ihrem Postback.',

    apiKey:'API-Schlüssel',
    appStoreId:'App-Store-ID',
    distinctEventType:'unterschiedliche Ereignistypen, die ASA zugeordnet sind, wurden von einem MMP für diese App bereitgestellt. Diese Ereignistypen können bei der Zielerstellung verwendet werden.',
    noDistinctEventType:'Es wurden noch keine Ereignisse empfangen, die Apple Search Ads zugeschrieben werden. Wenn nach 24 Stunden keine Ereignisse eingegangen sind, überprüfen Sie bitte diese Integrationsschritte noch einmal und stellen Sie sicher, dass aktive Apple Search Ads-Kampagnen laufen, die ein oder mehrere Ereignisse auslösen würden, die vom MMP geliefert werden.',
    dEventType:'Unterschiedliche Ereignistypen',
    noEventFound:'Keine Veranstaltungen gefunden',
    close:'Nah dran',
    note:'HINWEIS: Apps, die bereits mit einem anderen MMP integriert sind, erscheinen nicht in dieser Liste.',
    intDeletionInProgress:"Die Integrationslöschung wird derzeit durchgeführt und wir benachrichtigen Sie, sobald sie abgeschlossen ist."

  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: 'Schlagwörter hinzugefügt',
    total: 'Gesamt',
    cancel: 'Stornieren',
    runAds: 'Anzeigen ausführen',
    ok: 'OK',
    totalPaidKey: 'Bezahlte Keywords insgesamt',
    popularity: 'Popularität',
    numOfApps: 'Anzahl Apps',
    chance: 'Chance',
    appsRunAds: 'Anwendungen, die Anzeigen ausführen',
    maxPop: 'Maximale Popularität für dich',
    keyword: 'Stichwort',
    clear: 'klar',
    clrAllFilter: 'Alle Filter löschen',
    keywords: 'KeywoSchlüsselwörterrds',
    organicRank: 'Organischer Rang',
    totalApps: 'Gesamtzahl der Bewerbungen',
    app: 'Anwendung',
    catName: 'Kategoriename',
    searchByApp: 'Suche nach Anwendung',
    searchByKey: 'über Schlüsselwort suchen',
    addAllKey: 'Alle Keywords hinzufügen',
    searchForCompetes: 'Vorgeschlagene Wettbewerber',
    searchAdsRecommends: 'Empfehlungen für Suchanzeigen',
    categoryTop: 'Kategorie Top Apps',
    compRating: 'Bewertung',
    compVersion: 'Ausführung',
    compPrice: 'Preis',
    compGenres: 'Genres',
    monthlyDownloads: 'Monatliche Downloads',
    free: 'Kostenlos',
    paid: 'Bezahlt',
    country: 'Land',
    lang: 'Sprache',
    showDataText: 'Keine Daten zum Anzeigen',
    quickAddCriteria: 'Kriterien für das schnelle Hinzufügen',
    tooltip: {
      keyText: "Keyword-Titel",
      organicRank: "Organic Rank bezeichnet die Suchergebnisse einer Suchmaschine, die nicht durch bezahlte Werbung beeinflusst werden können.",
      popularity: "Der Keyword-Beliebtheitswert des Keywords. Ein höherer Suchscore bedeutet ein höheres Suchvolumen für das Keyword.",
      totalApps: "Gesamtzahl der Apps, die dieses Keyword verwenden",
      appDetails: "Anwendungsname",
      organicRankApp: "Organic Rank bezeichnet die Suchergebnisse einer Suchmaschine, die nicht durch bezahlte Werbung beeinflusst werden können.",
      category: "Die Kategorien, zu denen die App gehört",
      rating: "Bewertung der App",
      version: "Version der App",
      price: "Preis der App",
      genres: "Genre der App",
      chance: "Der Chance Score ist ein prozentualer Wert, der auf der Schätzung Ihrer Chancen basiert, für ein bestimmtes Keyword in der spezifischen Kategorie Ihrer App unter den Top Ten zu sein",
      appsRunning: "Gesamtzahl der Apps, die für das Keyword bezahlen"
    }
  },

  //Overview Page
  overview: {
    selectGoalFirstSelectApp:'Um ein Ziel auszuwählen, wählen Sie zuerst eine App aus dem Dropdown-Menü auf der linken Seite aus.',
    count:'Anzahl der Zielereignisse',
    exportCSV:'CSV exportieren',
    period: 'Zeitraum',
    topKeywords: 'oben 10 Schlüsselwörter von',
    key: 'Stichwort',
    spend: 'Verbringen',
    impressions: 'Impressionen',
    taps: 'Wasserhähne',
    goToKey: 'Gehe zu Alle Schlüsselwörter',
    topAdgroup: 'oben 10 Anzeigengruppen von',
    adgroup: 'Anzeigengruppen',
    goToAdgroup: 'Gehe zu Alle Anzeigengruppen',
    gender: 'Geschlecht',
    device: 'Gerät',
    searchApp: 'Anwendung suchen',
    lastDay: 'Letzter Tag',
    last7Days: 'Letzten 7 Tage',
    last30Days: 'Letzten 30 Tage',
    ttr: 'Durchgriff Bewertung',
    installs: 'Installiert',
    avgCPA: 'Durchschnittlich CPA',
    avgCPT: 'Durchschnittlich CPT',
    conversionRate: 'Wechselkurs',
    latOnInstalls: 'LAT Bei Installationen',
    latOffInstalls: 'LAT Aus Installationen',
    reDownloads: 'Erneute Downloads',
    newDownloads: 'Neue Downloads',
    all: 'Alle',
    tooltip: 'Die Zunahme oder Abnahme im Laufe der Zeit als Prozentsatz gegenüber dem vorherigen Zeitraum basierend auf dem ausgewählten Datumsbereich.',
    prevPeriod: 'Vorperiode',
    currPeriod: 'Laufzeit',
    ipad: 'IPAD',
    iphone: 'IPHONE',
    unknown: 'Unbekannt',
    selectPer: 'Zeitraum auswählen',
    GOLS: 'Ziele',
    CPRG: 'Kosten pro Ziel',
    RPRG: 'Einnahmen pro Ziel',
    GREV: 'Einnahmen aus Zielen',
    GRAS: 'Ziel ROAS',
    GETC: 'Ziel Ereignisanzahl',
    tapInstalls: 'Installationen (Tap-Through)',
    viewInstalls: 'Installationen (View-Through)',
    totalInstalls: 'Installationen (insgesamt)',
    tapNewDownloads: 'Neue Downloads (Tap-Through)',
    viewNewDownloads: 'Neue Downloads (View-Through)',
    totalNewDownloads: 'Neue Downloads (Gesamt)',
    tapRedownloads: 'Erneute Downloads (Tap-Through)',
    viewRedownloads: 'Erneute Downloads (View-Through)',
    totalRedownloads: 'Redownloads (Gesamt)',
    tapInstallRate: 'Conversion-Rate (Tap-Through)',
    totalInstallRate: 'Conversion-Rate (Gesamt)',
    tapInstallCPI: 'Durchschnittlicher CPA (Tap-Through)',
    totalAvgCPI: 'Durchschnittlicher CPA (Gesamt)'
  },

  checklist: {
    title: 'Checkliste',
    integrations: 'Integrationen',
    searchads: 'Apple Search-Anzeigen',
    slack: 'Slack-Integration hinzufügen',
    kai: 'Machen Sie sich mit Keyword-Analyse-Tools vertraut',
    competitorMsg: 'Sehen Sie, wie es Ihren Konkurrenten geht.',
    searchKeywords: 'Siehe Schlüsselwörter für eine App.',
    seeApps: 'Siehe Bieten von Apps für ein Schlüsselwort.',
    topCharts: 'Siehe Kategorie-Top-Charts.',
    campaign: 'Kampagne',
    initCampaign: 'Starten Sie eine Kampagne.',
    createAdgroup: 'Anzeigengruppe erstellen.',
    addKeywords: 'Schlüsselwörter hinzufügen.',
    addCreativeSet: 'Creative-Satz hinzufügen.',
    goLive: 'Geh Leben.',
    reviewMetrics: 'Überprüfen Sie den Erfolg der Kampagne.',
    automate: 'Automatisieren Sie Ihre Kampagne.',
    mmpIntegration: 'MMP-Integration',
    createMmpGoals: 'Erstellen Sie MMP-Ziele',
    campGen:'Kampagnen-Generator',
    scheduledReports:'Geplante Berichte'
  },

  //Keyword Optimization Page
  keyOpt: {
    keyBid: 'Keyword-Optimierung',
    keyToolTip: 'Sie können den Gebotsbetrag der Keywords optimieren, die nicht im Bereich des von ASA vorgeschlagenen Mindestgebotsbetrags und des von ASA vorgeschlagenen Höchstgebotsbetrags liegen.',
    optAll: 'Alles optimieren',
    opt: 'Optimieren',
    optBidAmt: 'Gebotsbetrag optimieren',
    key: 'Stichwort',
    optBidForAll: 'Gebotsbetrag aller Keywords optimieren',
    campaign: 'Kampagne',
    adgroup: 'Anzeigengruppe',
    bidAmt: 'Gebotsbetrag',
    asaMinBid: 'Von ASA empfohlenes Mindestgebot',
    asaMaxBid: 'Von ASA empfohlenes Höchstgebot',
    asaSuggestedBidAmount: 'ASA-Gebotsvorschlag',
    action: 'Aktion',
    noData: 'Apple hat derzeit keine Vorschläge.'
  },

  //ARRules Page
  aRules: {
    createAR: 'Automatisierungsregel erstellen',
    view: 'Aussicht',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    disable: 'Deaktivieren',
    enable: 'Aktivieren',
    name: 'Name',
    createdAt: 'Hergestellt in',
    status: 'Status',
    completed: 'Abgeschlossen At',
    actionFreq: 'Aktionshäufigkeit',
    rules: 'Regeln',
    logs: 'Protokolle',
    createdFor: 'Hergestellt für',
    chckFreq: 'Häufigkeit prüfen',
    actions: 'Aktionen',
    enableDisable: 'Aktivieren deaktivieren',
    sno: 'S.No'
  },

  //Create Campaign
  //CampaignView
  createCampaign: {
    createCamp: 'Kampagne erstellen',
    h2: 'Wählen Sie eine App aus, um eine Kampagne zu erstellen',
    para: 'Kampagnen haben Budgets und Anzeigengruppen, die sich auf ein gemeinsames Thema oder eine gemeinsame Strategie zur Bewerbung Ihrer App konzentrieren.',
    select: 'Wählen Sie aus, wo Anzeigen geschaltet werden sollen',
    searchResults: 'Suchergebnisse',
    srData: 'Kampagnen haben Budgets und Anzeigengruppen, die sich auf ein gemeinsames Thema oder eine gemeinsame Strategie zur Bewerbung Ihrer App konzentrieren.',
    searchTab: 'Registerkarte "Suchen"',
    stData: 'Bewerben Sie Ihre App ganz oben in der Liste der vorgeschlagenen Apps, wenn Nutzer den Tab "Suchen" aufrufen.',
    todayTab: 'Heute Tab',
    todayData:'Erreichen Sie Benutzer, wenn sie zum ersten Mal in den App Store kommen, um Apps zu entdecken. Ihre Anzeige erscheint prominent auf der Titelseite.',

    productPageTab: 'Produktseiten',
    productPageData: 'Erreichen Sie Benutzer, wenn sie Apps durchsuchen. Ihre Anzeige erscheint oben. Vielleicht möchten Sie auch Benutzer auflisten, die zum Ende der relevanten Produktseiten gescrollt haben.',
    cancel: 'Stornieren',
    continue: 'Fortsetzen',

    //Search Results and Search Tab Campaign
    countAndReg: 'Länder und Regionen',
    adgroupSettings: 'Einstellungen für Anzeigengruppe',
    searchMatch: 'Suchtreffer',
    key: 'Stichwort',
    audience: 'Publikum',
    crSets: 'Kreativsets',
    chooseCampGroup: 'Wähle eine Kampagnengruppe',
    chooseApp: 'Wähle eine App',
    next: 'Nächster',
    countPara: 'Ihre App wird im App Store in den berechtigten Ländern und Regionen beworben, die Sie aus der Liste auswählen.',
    chooseCount: 'Wählen Sie ein oder mehrere Länder oder Regionen aus',
    campName: 'Kampagnenname',
    budget: 'Budget',
    dailyCap: 'Tagesobergrenze (optional)',
    adgroupPara: 'Anzeigengruppen enthalten Preisziele und Einstellungen, die bestimmen, für wen Ihre Anzeige geschaltet werden soll.',
    adgroupName: 'Anzeigengruppenname',
    devices: 'Geräte',
    startTime: 'Startzeit',
    adScheduling: 'Anzeigenplanung',
    endTime: 'Endzeit',
    dayParting: 'Tagesausflug (Optional)',
    ok: 'OK',
    defaultMax: 'Standardmäßiges maximales CPT-Gebot',
    cpaGoal: 'CPA-Ziel(Optional)',
    saveDraft: 'Als Entwurf speichern',
    appleSearchMatch: 'Apple-Suchabgleich',
    searchMatchPara: 'Apple Search Match ist der einfachste Weg, um Ihre Anzeigen zum Laufen zu bringen. Search Ads ordnet Ihre Anzeige automatisch Nutzern zu, die nach Apps wie Ihrer suchen.',
    searchMatchPara1: 'Meine Anzeige automatisch relevanten Suchanfragen zuordnen',
    keywords: 'Schlüsselwörter',
    keyPara: 'Keywords sind relevante Wörter oder Begriffe, die Kunden verwenden können, wenn sie nach einer App wie Ihrer suchen. Mithilfe von Schlüsselwörtern können Suchende Ihre App schnell finden und so die App-Installationen steigern.',
    upload: 'Hochladen',
    suppFormat: 'unterstützte Formate: Excel, CSV',
    keyManually: 'Oder fügen Sie Keywords manuell hinzu',
    targKey: 'Targeting-Keywords',
    clear: 'klar',
    adgroupNegKey: 'Ausschließende Keywords für Anzeigengruppe',
    optional: 'Optional',
    negKey: 'Ausschließende Keywords',
    audPara: ' Verfeinern Sie die Zielgruppe für Ihre Anzeigengruppe mit optionalen erweiterten Funktionen.',
    custType: 'Kundentyp',
    demo: 'Demografie',
    gender: 'Geschlecht',
    location: 'Ort (Suchen)',
    crSetPara: 'Anzeigen werden automatisch mit den Metadaten, Screenshots und App-Vorschauen erstellt, die auf der App Store-Produktseite bereitgestellt werden. Diese erscheinen auch in den organischen Suchergebnissen im App Store. Die hier gezeigten Anzeigen sind Beispiele für Standardanzeigen.',
    crOptional: 'Kreativset (Optional)',
    noCrSet: 'App erfüllt nicht die Anforderungen des minimalen Ad',
    campCreated: 'Kampagne erstellt',
    campCreatedPara: 'Jetzt können Sie Ihre Kampagnen im Search Ads-Dashboard sehen',
    close: 'Schließen',
    matchType: 'Übereinstimmungstyp',
    amount: 'Menge',
    currency: 'Währung',
    allUsers: 'Alle Nutzer',
    newUsers: 'Neue Nutzer',
    appUsers: 'Nutzer meiner anderen Apps',
    existingUsers: 'Existierende Benutzer',
    all: 'Alle',
    male: 'Männlich',
    female: 'Weiblich',
    chooseCamp: 'Kampagnengruppe',
    selectApp: 'Such-App',
    countries: 'Länder',
    ageRange: 'Altersspanne',
    selectDev: 'Geräte auswählen',
    appRules: 'Bitte wählen Sie eine App!',
    adsOpt: 'Anzeige (optional)',
    default: 'Standard',
    defaultAdPara: 'Verwendet Assets von Ihrer Standardproduktseite im App Store.',
    custom: 'Brauch',
    customPara: 'Verwendet Assets aus Ihrem standardmäßigen App Store Connect und führt Benutzer zu dieser Seite, wenn sie auf die Anzeige tippen.',
    pagesInfo: 'Benutzerdefinierte Produktseiten sind verfügbar',
    createAdPara: 'Klicken Sie auf die Schaltfläche Anzeige erstellen, um aus Ihren benutzerdefinierten Produktseitenoptionen auszuwählen und eine Vorschau Ihrer Anzeige anzuzeigen',
    createAdBtn: 'Anzeige erstellen',
    createAds: 'Anzeigen erstellen',
    selectCustomPara: 'Wählen Sie eine benutzerdefinierte Produktseite aus, deren Assets zum Erstellen Ihrer Anzeige verwendet werden sollen. Wenn Nutzer in den Suchergebnissen auf Ihre Anzeige tippen, werden sie auf die von Ihnen ausgewählte Seite weitergeleitet.',
    adName: 'Anzeigenname',
    search: 'Suche',
    available: 'Verfügbar',
    sortBy: 'Sortieren nach',
    save: 'Speichern',
    customAds: 'Benutzerdefinierte Anzeigen',
    edit: 'Bearbeiten',
    previous: 'Vorherige'
  },

  //Change Password
  changePass: {
    backSett: 'Zurück zu den Einstellungen',
    chngPass: 'Passwort einrichten',
    newPass: 'Neues Kennwort',
    confirmPass: 'Kennwort bestätigen',
    submit: 'einreichen',
    h4: 'The Kochava Marketers Operating System™',
    para: 'The Kochava Marketers Operating System (m/OS) integriert nahtlos Omnichannel-Marketinglösungen für Werbetreibende und Publisher in einer operativen Plattform.',
    ok: 'OK'
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Passwort vergessen',
    typeEmail: 'Geben Sie die E-Mail-Adresse ein',
    emailAdd: 'E-Mail-Addresse',
    submit: 'einreichen',
    chckEmail: 'Bitte überprüfen Sie Ihre E-Mail',
    para: 'Um ein neues Passwort festzulegen, überprüfen Sie bitte Ihre E-Mail',
    close: 'Schließen',
    alreadyAcc: 'Sie haben bereits ein Konto?',
    signIn: 'Einloggen',
  },

  //Onboard
  onboard: {
    welcome: 'Willkommen in SearchAds Maven',
    personal: "Anmeldung",
    start: 'Start',
    integration: 'Integration',
    next: 'Nächster',
    trial: 'Starten Sie Ihre Testversion',
    scheduleDemo: 'Oder vereinbaren Sie mit uns eine Demo und entdecken Sie gemeinsam',
    addInt: 'Fügen Sie eine Integration hinzu.',
    signInApple: 'Melden Sie sich bei Apple an, um eine Apple Search Ads-Integration hinzuzufügen. Bitte beachten Sie:',
    step1: '1. Es ist erforderlich, über Administratorrechte zu verfügen, um Zugriff auf das Apple Search Ads-Konto zu gewähren.',
    step2: '2. Um Ihre Kampagnen von SearchAdsNinja aus zu verwalten, wählen Sie bitte die Option Lesen & Schreiben, bevor Sie den Zugriff gewähren.',
    skip: 'Überspringen'
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Anzeigengruppe hinzufügen',
    para: 'Sie haben Ihre Kampagne noch nicht ausgewählt. Sie können es aus dem unteren Feld auswählen.',
    camGroup: 'Kampagnengruppe',
    campaign: 'Kampagne',
    adgroupSett: 'Einstellungen für Anzeigengruppe',
    para1: 'Anzeigengruppen enthalten Preisziele und Einstellungen, die bestimmen, für wen Ihre Anzeige geschaltet werden soll.',
    adgroupName: 'Anzeigengruppenname',
    devices: 'Geräte',
    adScheduling: 'Anzeigenplanung',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    dayparting: 'Tagesausflug (Optional)',
    defaultMax: 'Standardmäßiges maximales CPT-Gebot',
    cptTool: 'Dies ist der Höchstbetrag, den Sie für ein Tippen auf Ihre Anzeige zu zahlen bereit sind. Dieses Gebot gilt für alle Keywords in dieser Anzeigengruppe, es sei denn, Sie geben ein einzelnes Keyword-Gebot an.',
    cpaGoal: 'CPA-Ziel(Optional)',
    cpaTool: 'Der Durchschnitt, den Sie für jeden Download bezahlt haben. Dies wird berechnet, indem die Gesamtausgaben durch die Anzahl der Downloads geteilt werden.',
    searchMatch: 'Suchtreffer',
    searchTool: 'Search Match ist der einfachste Weg, um Ihre Anzeigen zu schalten. Search Ads ordnet Ihre Anzeige automatisch Nutzern zu, die nach Apps wie Ihrer suchen.',
    searchPara: 'Meine Anzeige automatisch relevanten Suchanfragen zuordnen',
    key: 'Schlüsselwörter',
    keyPara: 'Schlüsselwörter sind relevante Wörter oder Begriffe, die Kunden verwenden können, wenn sie nach einer App wie Ihrer suchen. Mithilfe von Schlüsselwörtern können Suchende Ihre App schnell finden und so die App-Installationen steigern',
    inputCpt: 'Geben Sie das standardmäßige maximale CPT-Gebot ein, um den Abschnitt mit den Keywords zu aktivieren',
    addKeyPara: 'Fügen Sie Keywords hinzu, die für Ihre App und Ihr Genre relevant sind. Wir helfen Ihnen, indem wir zusätzliche Empfehlungen teilen.',
    upload: 'Hochladen',
    addKeyManually: 'Oder fügen Sie Keywords manuell hinzu',
    negKey: 'Ausschließende Keywords der Anzeigengruppe (optional)',
    supp: 'unterstützte Formate: Excel, CSV',
    demAge: 'Demografie Alter, Geschlecht',
    demPara: 'Richten Sie Ihre Anzeige auf das Alter oder Geschlecht Ihres Nutzers aus',
    ageRange: 'Altersspanne',
    cancel: 'Stornieren',
    apply: 'Anwenden'
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Anzeigengruppe bearbeiten',
    adgroup: 'Anzeigengruppe',
    advance: 'Erweiterte Einstellungen',
    viewEdit: 'Anzeigen und Bearbeiten',
    general: 'Allgemeines',
    settings: 'die Einstellungen',
    schedule: 'Zeitplan',
    custPara: 'Wählen Sie die App-Kunden aus, die Sie erreichen möchten',
    selectAge: 'Altersgruppe auswählen',
    save: 'speichern'
  },

  //Create AR
  createAR: {
    rule:"Regel",
    addRule: 'Regel hinzufügen',
    selectDateRange:'Wählen Sie Datumsbereich aus',
    createAutoRule: 'Automatisierungsregel erstellen',
    editAutoRule: 'Automatisierungsregel bearbeiten',
    choose: 'Wählen',
    chooseAutoRule: 'Bitte wählen Sie Automatisierungsstufe und Elemente aus.',
    conditions: 'Bedingungen',
    actions: 'Aktionen',
    settings: 'die Einstellungen',
    condition: 'Bedingung',
    addCondition: 'Bedingung hinzufügen',
    andCondition: 'Wählen Sie aus, ob alle oben genannten Bedingungen zutreffen müssen oder nur eine von ihnen zutreffen soll',
    checkCond: 'Bedingungen prüfen für conditions',
    action: 'Aktion',
    selectAll: 'Wählen Sie Alle',
    enterEmail: 'Bitte geben Sie die richtigen E-Mail-IDs ein',
    addTo: 'Ergänzen',
    selectAdgroups: 'Wählen Sie Anzeigengruppen',
    selectCampaigns: 'Kampagnen auswählen',
    cptBid: 'CPT-Gebot',
    customBid: 'Benutzerdefiniertes Gebot',
    matchType: 'Übereinstimmungstyp',
    addAction: 'Aktion hinzufügen',
    chckFreq: 'Häufigkeit prüfen',
    ruleName: 'Regelname',
    cancel: 'Stornieren',
    step2: 'Schritt 2',
    step3: 'Schritt 3',
    andOrTypesText1: 'Alle müssen übereinstimmen',
    andOrTypesText2: 'Nur einer sollte passen',
    addToModelListNegText1: 'Alle ausgewählten Anzeigengruppe(n)',
    addToModelListNegText2: 'Alle Anzeigengruppen der ausgewählten Kampagne(n)',
    addToModelListNegText3: 'Alle ausgewählten Kampagne(n)',
    matchTypesText1: 'Weite Übereinstimmung',
    matchTypesText2: 'Genaue Übereinstimmung',
    cptBidItemsText1: 'Auf Benutzerdefiniert einstellen',
    cptBidItemsText2: 'Standardgebot der Anzeigengruppe',
    actionFreqsText1: 'Stündlich',
    actionFreqsText2: 'Alle 6 Stunden',
    actionFreqsText3: 'Alle 12 Stunden',
    actionFreqsText4: 'Täglich',
    actionFreqsText5: 'Benutzerdefinierte Öffnungszeiten',
    limitActionFreqsText1: 'Alle 2 Stunden',
    limitActionFreqsText2: 'Alle 3 Stunden',
    limitActionFreqsText3: 'Alle 2 Tage',
    limitActionFreqsText4: 'Alle 3 Tage',
    limitActionFreqsText5: 'Jede Woche',
    mon: 'Montag',
    tue: 'Dienstag',
    wed: 'Mittwoch',
    thu: 'Donnerstag',
    fri: 'Freitag',
    sat: 'Samstag',
    sun: 'Sonntag',
    ciTimeRangesText1: 'Letzte 1 Stunde',
    ciTimeRangesText2: 'Letzter 1 Tag',
    ciTimeRangesText3: 'Letzten 7 Tage',
    ciTimeRangesText4: 'Letzte 30 Tage',
    ciTimeRangesText5: 'Letzten Monat',
    ciTimeRangesText6: 'Benutzerdefinierte Stunde',
    ciTimeRangesText7: 'Datumsbereich',
    allConOperationText1: 'Größer als',
    allConOperationText2: 'Weniger als',
    allConOperationText3: 'Reichweite',
    allConOperationText4: 'Nicht in Reichweite',
    amount: '$ Menge',
    budget: '% Prozent des Budgets',
    incBy: 'Erhöhen um',
    decBy: 'Verringern um',
    setToCPA: 'Auf durchschnittlichen CPA einstellen',
    setToCPT: 'Auf durchschnittlichen CPT einstellen',
    value: '$ Wert',
    change: '% Veränderung',
    between: 'Zwischen',
    equalTo: 'Ist gleich',
    appsSelected:'Apps ausgewählt',
    campaignsSelected:'Kampagnen ausgewählt',
    adgroupsSelected:'Anzeigengruppen ausgewählt',
    KeywordsSelected:'Schlüsselwörter ausgewählt',
    selectField:'Wählen Sie Feld aus',
    selectOne:'Wähle eins',
    pleaseSelect:'Bitte auswählen',
    pleaseEnteraValue:'Bitte einen Wert eingeben',
    fromValue:'Von Wert',
    toValue:'Schätzen',
    mustBeLesserThanToValue:'Debe ser mayor que del valor',
    mustBeHigherThanFromValue:'Muss höher als der Wert sein',
    cannotBeZero:'Kann nicht 0 sein',
    ruleAlreadyExist:'Regelname existiert bereits',
    emailNotification:'Email Benachrichtigung',
    slackNotification: 'Slack-Benachrichtigung',
    pause:'Pause',
    resume:'Fortsetzen',
    changeDailyCap:'Tägliche Obergrenze ändern',
    changeBid:'Gebot ändern',
    changeBudget:'Budget ändern',
    addAsNegativeKeyword:'Als ausschließendes Keyword hinzufügen',
    createActionFor: 'Aktion erstellen für',
    selectAction:'Aktion auswählen',
    selectEmail:'Wählen Sie E-Mail aus',
    addOtherEmail:'Andere E-Mail hinzufügen',
    enterAnotherEmail:'Geben Sie eine E-Mail-ID ein und drücken Sie die Eingabetaste, um weitere hinzuzufügen',
    giveAName:'Geben Sie dieser Regel einen Namen',
    searchLogs: 'Filtern Sie die Protokolle nach Regel',
    details: 'Einzelheiten',
    PENDING: 'STEHT AUS',
    SUCCESS: 'ERFOLG',
    FAILED: 'GESCHEITERT',
    CREATED: 'ERSTELLT',
    DELETED: 'GELÖSCHT',
    ENABLED: 'AKTIVIERT',
    DISABLED: 'DEAKTIVIERT',
    REACTIVATED: 'WIEDER AKTIVIERT',
    UPDATED: 'AKTUALISIERT',
    CONDITIONMATCHED : 'ZUSTAND ÜBEREINGEKOMMEN',
    CONDITIONNOTMATCHED:'ZUSTAND NICHT ÜBEREINGEKOMMEN',
    runIndefinite:'Unendlich laufen lassen',
    indefiniteNote:'HINWEIS: Wenn diese Automatisierungsregel ausgewählt ist, wird sie weiterhin im angegebenen Intervall angewendet und bleibt in Kraft, unabhängig davon, wie oft sie ausgelöst wird.',
    sureToSave:'Sind Sie sicher, dass Sie die Automatisierungsregel speichern möchten?',
    update:'Aktualisieren',
    selectChannel:'Kanal auswählen',
    noDataAvailable:'Keine Daten verfügbar',
    sureToDelete:'Sie möchten die Automatisierungsregel löschen?',
  },

   //select Account AR
   selectAccountAR: {
    chooseAcc: 'Wählen Sie Konto',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    clearAll: 'Alles löschen',
    accounts: 'Konten',
    accountSelected: 'Account(s) ausgewählt',
  },

// select Apps AR
  selectAppsAR : {
    chooseApp : 'App auswählen',
    cancel : 'Stornieren',
    apply : 'Anwenden',
    apps : 'Apps',
    appSelected : 'App(s) ausgewählt',
    clearAll : 'Alles löschen',
  },

  //select Keywords AR
  selectKeywordsAR : {
    keepOneAdGroup : 'Bitte lassen Sie nur eine Anzeigengruppe ausgewählt, um fortzufahren.',
    close : 'Schließen',
    chooseKeywords : 'Schlüsselwörter auswählen',
    cancel : 'Stornieren',
    apply : 'Anwenden',
    campaigns : 'Kampagnen',
    expandAll : 'Alle erweitern',
    collapseAll : 'Alles ausblenden',
    adGroups : 'Anzeigengruppen',
    keywords : 'Schlüsselwörter',
    keywordSelected : 'Stichwort(e) ausgewählt',
    clearAll : 'Alles löschen',
    excludePausedKeywords:'Angehaltene Keywords ausschließen',
  },

  //campaign
  //SelectCampaign
  selectCamp :{
    chooseCamp: 'Kampagne auswählen',
    noSelectedCamp : 'Keine ausgewählte Kampagne',
    campaigns : 'Kampagnen',
    expandAll : 'Alle erweitern',
    collapseAll : 'Alles ausblenden',
    selectedCampaigns : 'Ausgewählte Kampagnen',
    clearAll : 'alles löschen',
    cancel : 'Stornieren',
    apply : 'Anwenden',
      },

      //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Kreditkarte bearbeiten',
    cardNumber: 'Kartennummer',
    expiryDate: 'Verfallsdatum',
    cvv: 'CVV',
    saveCard: 'Karte speichern',
    updateExpiryPara: '* Sie können nur das Ablaufdatum aktualisieren',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Creative-Set bearbeiten',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Installieren',
    editCreativePara1:
      'Wählen Sie einen Namen, der das Thema Ihres Kreativsets beschreibt. Wählen Sie die Sprache der Screenshots und Vorschauen aus, die Sie auf Ihrer App Store-Produktseite hinzufügen möchten.',
    creativeSetName: 'Name des Creative-Sets',
    localLang: 'Lokalisierungssprache',
    assetSelection: 'Asset-Auswahl',
    assetSelectionPararaph1:
      'Kreativsets bestehen aus Screenshots und App-Vorschauen, die auf der App Store-Produktseite angezeigt werden.',
    assetSelectionPararaph2: 'Für jede iPhone-Displaygröße',
    assetSelectionPararaph3:
      'Wählen Sie mindestens ein Querformat oder drei Hochformat-Assets aus.',
    assetSelectionPararaph4: 'Für jede iPad-Displaygröße',
    assetSelectionPararaph5:
      'Wählen Sie mindestens ein Querformat oder zwei Hochformat-Assets aus.',
    viewDevice: 'Anzeigen von Gerätedisplaygrößen',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Anzeige',
    default: 'Standard',
    cancel: 'Stornieren',
    save: 'Speichern',
  },

  addIntegration: {
    invalid: 'Ungültige Integration, bitte löschen und erneut hinzufügen.'
  },

  navbar: {
    syncProgress: 'Die Datensynchronisierung wird ausgeführt.'
  },

  messages: {
    onHold: 'Kampagne in Wartestellung.',
    reasons: 'Gründe dafür'
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Integration löschen',
    cardText: 'Möchten Sie die Integration wirklich löschen?',
    cancel: 'Stornieren',
    delete: 'Löschen',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Schlagwörter hinzufügen',
    keywordSuggestionsFor: 'Keyword-Vorschläge für',
    on: 'an',
    addedKeywords: 'Schlagwörter hinzugefügt',
    total: 'Gesamt',
    add: 'Hinzufügen',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    matchExact: 'GENAU',
    matchBroad: 'BREIT',
    searchHere: 'Suche hier',

  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Schlagwörter hinzufügen',
    selectTarget: 'Ziel auswählen',
    h4Camp: 'Kampagnen:',
    selected: 'Ausgewählt',
    change: 'Veränderung',
    h4Adgroups: 'Anzeigengruppen:',
    maxCPTBid: 'Max. CPT-Gebot:',
    keywordSuggestionsFor: 'Keyword-Vorschläge für',
    on: 'an',
    recommendedKeywords: 'Empfohlene Schlüsselwörter',
    addedKeywords: 'Schlagwörter hinzugefügt',
    add: 'Hinzufügen',
    total: 'Gesamt',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    ok: 'OK',
  },
  strategy:{
    descriptionValid:"Beschreibung ist erforderlich",
    alphaNumeric:"Nur alphanumerische Zeichen erlaubt",
    textMax:"Die Beschreibung sollte 250 Zeichen nicht überschreiten"
  },
  //DeleteStrategy
   deleteStrategy: {
    deleteCamp: 'Strategie löschen',
    para: 'Sind Sie sicher, dass Sie die Strategie löschen möchten?',
    cancel: 'Stornieren',
    delete: 'Löschen',
  },
  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Schlagwörter hinzufügen',
    selectTarget: 'Ziel auswählen',
    h4Camp: 'Kampagnen:',
    selected: 'Ausgewählt',
    change: 'Veränderung',
    h4Adgroups: 'Anzeigengruppen:',
    maxCPTBid: 'Max. CPT-Gebot:',
    keywordSuggestionsFor: 'Keyword-Vorschläge für',
    on: 'an',
    recommendedKeywords: 'Empfohlene Schlüsselwörter',
    addedKeywords: 'Schlagwörter hinzugefügt',
    add: 'Hinzufügen',
    total: 'Gesamt',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    ok: 'OK',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Ausschließende Keywords hinzufügen',
    add: 'Hinzufügen',
    total: 'Gesamt',
    cancel: 'Stornieren',
    save: 'Speichern',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Gebotsbetrag',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Bitte lassen Sie nur eine Anzeigengruppe ausgewählt, um fortzufahren.',
    close: 'Schließen',
    chooseAdGroup: 'Anzeigengruppe auswählen',
    cancel: 'Stornieren',
    apply: 'Anwenden',
    campaigns : 'Kampagnen',
    expandAll: 'Alle erweitern',
    collapseAll: 'Alles ausblenden',
    adGroups : 'Anzeigengruppen',
    adGroupSelected : 'Anzeigengruppe(n) ausgewählt',
    clearAll : 'Alles löschen'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Ausschließende Keywords hinzufügen',
    selectTarget : '1. Ziel auswählen',
    addNegKeyword : 'Ausschließendes Keyword hinzufügen',
    paragraph1 : 'Ausschließende Keywords können zu Kampagnen oder Anzeigengruppen hinzugefügt werden. Bitte wählen Sie eine davon aus dem unteren Feld aus.',
    paragraph2 : 'Für welche Kampagne möchten Sie Keywords hochladen?',
    selectCampaign : 'Kampagne auswählen',
    paragraph3 : 'Welche Anzeigengruppe möchten Sie Keywords hochladen?',
    selectAdgroup : 'Wählen Sie Anzeigengruppe',
    cancel: 'Stornieren',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Kampagnen:',
    selected : 'Ausgewählt',
    change: 'Veränderung',
    paragraph4 : ' Ausschließende Keywords für Anzeigengruppen gelten nur für diese Anzeigengruppe. Wenn ausschließende Keywords für alle Anzeigengruppen gelten sollen, legen Sie sie auf Kampagnenebene fest.',
    paragraph5 : ' Erwägen Sie, Kampagnen Zeit zu geben, bevor Sie die Anzeigenleistung analysieren, um herauszufinden, welche Keywords für Sie möglicherweise nicht funktionieren. Das Hinzufügen zu vieler ausschließender Keywords kann Ihre Anzeigenpräsenz einschränken.',
    addedKeywords : 'Schlagwörter hinzugefügt',
    add: 'Hinzufügen',
    total: 'Gesamt',
    save: 'Speichern',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Slack-Channel hinzufügen',
    sno: 'S.Nein'
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Stornieren',
ok : 'OK',
confirm:'Bestätigen'
  },

  //HelloWorld
  helloWorld : {
welcome : 'Willkommen bei Vuetify',
para1 : 'Für Hilfe und Zusammenarbeit mit anderen Vuetify-Entwicklern,',
para2 : 'Bitte treten Sie unserem Online bei',
discord : 'Discord-Community',
h2 : "Was kommt als nächstes?",
impLinks : 'Wichtige Links',
ecosystem : 'Ökosystem',
  },

  //InfoAlert
  infoAlert : {
ok : 'OK',
  },

  successMsg: {
    campaignUpdate: 'Kampagne aktualisiert.',
    campaignMovedMsg: 'Status geändert. Kampagne auf anderen Tab verschoben',
    campaignMoveFailMsg: "Kampagnenstatus konnte nicht geändert werden.",
    adgroupUpdateFailMsg: "Anzeigengruppenstatus konnte nicht aktualisiert werden.",
    addgroupUpdatedMsg: 'Status geändert. Anzeigengruppe auf anderen Tab verschoben',
    keywordMovedMsg: 'Status geändert. Stichwort auf anderen Tab verschoben',
    keywordMoveFailMsg: "Keyword-Status konnte nicht aktualisiert werden.",
    creativeAddedMsg: 'Creative-Set hinzugefügt.',
    campaignSavedMsg: 'Kampagne als Entwurf gespeichert.',
    campaignSaveFailMsg: 'Der Kampagnenentwurf kann derzeit nicht gespeichert werden. Bitte versuche es erneut!',
    keyAddedMsg: 'Schlüsselwörter hinzugefügt.',
    signinFailMsg: 'Anmeldung nicht möglich. Bitte versuchen Sie es erneut!',
    deleteMsg: 'Erfolgreich gelöscht',
    deleteFailMsg: 'Kann nicht gelöscht werden. Bitte versuche es erneut!',
    dataUpdateMsg : 'Daten aktualisiert',
    dataUpdateFailMsg: 'Fehler beim Aktualisieren der Daten',
    AutoDeleteMsg: 'Automatisierungsregel erfolgreich gelöscht',
    requestFailMsg: 'Ihre Anfrage kann nicht bearbeitet werden. Bitte versuch es später',
    AutoRuleDisSuccessMsg: 'Automatisierungsregel erfolgreich deaktiviert',
    AutoRuleEnableMsg: 'Automatisierungsregel erfolgreich aktiviert',
    SCAutoRuleDisSuccessMsg: 'Die Skriptregel wurde erfolgreich deaktiviert',
    SCAutoRuleEnableMsg: 'Skriptregel erfolgreich aktiviert',
    bidChangeSuccessMsg: 'Der Gebotsbetrag wurde erfolgreich geändert.',
    wentWrongMsg: 'Etwas ist schief gelaufen.',
    integrateSuccessMsg : 'Integration erfolgreich hinzugefügt',
    userCreateMsg: 'Benutzer erfolgreich erstellt',
    emailExistMsg: 'E-Mail existiert bereits',
    errorCreateUser: 'Fehler beim Erstellen des Benutzers',
    inviteFailMsg: 'Fehler beim Senden des Einladungslinks an die E-Mail',
    failTryLaterMsg: 'Irgendwas stimmt nicht. Bitte versuch es später',
    adgroupAddedMsg: 'Anzeigengruppe hinzugefügt.',
    adgroupUpdatedMsg: 'Anzeigengruppe aktualisiert.',
    ruleUpdatedMsg: 'Regel erfolgreich aktualisiert',
    ruleUpdateFailMsg: 'Regelaktualisierung fehlgeschlagen. Bitte versuch es später',
    rCreatedMsg: 'Regel erfolgreich erstellt',
    rCreationFailMsg: 'Regelerstellung fehlgeschlagen. Bitte versuch es später',
    assetDataFailMsg: 'Asset-Details können nicht abgerufen werden. Bitte versuche es erneut!',
    deleteCampFailMsg: 'Fehler beim Löschen der Kampagne',
    CampDeletedMsg: 'Kampagne gelöscht',
    creativeUpdated: 'Creative-Set aktualisiert.',
    keyAdded: 'Schlüsselwörter hinzugefügt.',
    negaKeyAddedMsg: 'Ausschließende Keywords zu Anzeigengruppe(n) hinzugefügt.',
    negKeyAddCamp: 'Ausschließende Keywords zur Kampagne hinzugefügt.',
    errorDeleteKey: 'Fehler beim Löschen des Keywords',
    keyDeleteS: 'Stichwort gelöscht. Keyword in gelöschte Keywords verschoben',
    syncStarted: 'Synchronisierung gestartet.',
    firstSyncStarted:'Apple Search Ads-Integration abgeschlossen!<br> <br> <p style="text-align: justify; color: inherit;">Bitte warten Sie einige Minuten, während die ASA-Daten zum ersten Mal synchronisiert werden. Ihre Apps und ASA-Daten werden ausgefüllt, sobald diese erste Synchronisierung abgeschlossen ist. Verfolgen Sie den Synchronisierungsfortschritt am oberen rechten Rand der Seite.<p>',
    integrationWarning:'Vor der Integration mit diesem MMP ist eine synchronisierte Apple Search Ads-Integration erforderlich. Bitte fügen Sie oben eine Apple Search Ads-Integration hinzu und warten Sie, bis die erste Synchronisierung abgeschlossen ist, bevor Sie diese MMP integrieren.',
    AdDeletedMsg:'Anzeige gelöscht',
    adUpdate: 'Anzeige aktualisiert',
    deleteAdFailMsg:'Fehler beim Löschen der Anzeige',
    StrategyDeletedMsg: 'Strategie gelöscht',
    deleteStrategyFailMsg: 'Fehler beim Löschen der Strategie',
  },

  createCampaignRules: {
    selectCampGrp: 'Bitte Kampagnengruppe auswählen!',
    selectApp: 'Bitte wählen Sie eine App aus!',
    selectCountry: 'Bitte Land auswählen!',
    campRequired: 'Kampagnenname erforderlich!',
    campNameSize: 'Ihr Kampagnenname muss weniger als 200 Zeichen lang sein',
    adGroupSize: 'Der Name Ihrer Anzeigengruppe muss weniger als 200 Zeichen lang sein',
    keywordsize: 'Keywords dürfen nicht länger als 80 Zeichen sein',
    budgetRequired: 'Budgetbetrag ist erforderlich!',
    validBudget: 'Bitte geben Sie ein gültiges Budget ein!',
    groupRequired: 'Anzeigengruppenname erforderlich!',
    defMaxCptRequired: 'Standard-Max. CPT-Gebot ist erforderlich!',
    validCptBid: 'Bitte geben Sie ein gültiges CPT-Gebot ein!',
    validDailyCap: 'Bitte geben Sie eine gültige Tagesobergrenze ein!',
    validCpaGoal: 'Bitte geben Sie ein gültiges CPA-Ziel ein!',
    selectDevices: 'Bitte Geräte auswählen!',
    fNamereq: 'Vorname ist erforderlich',
    fNameGrt: 'Der Vorname darf nicht länger als 12 Zeichen sein',
    textMax: 'Darf nicht länger als 50 Zeichen sein',
    textMin: 'Darf nicht weniger als 2 Zeichen lang sein',
    fNameValid: 'Bitte gültigen Vornamen eingeben!',
    lNameGrt: 'Nachname darf nicht länger als 12 Zeichen sein',
    lNameValid: 'Bitte gültigen Namen eingeben!',
    phoneReq: 'Telefon ist erforderlich',
    cmpniNameReq: 'Firmenname ist erforderlich',
    jobTitleReq: 'Berufsbezeichnung ist erforderlich',
    emailReq: 'E-Mail ist erforderlich',
    emailValid: 'Email muss gültig sein',
    selectCamp: 'Kampagne auswählen',
    cardNoReq: 'Kartennummer ist erforderlich',
    cardNo16Digit: 'Die Kartennummer muss numerisch und 16-stellig sein',
    expiryMonthReq: 'Verfallmonat und -jahr sind erforderlich',
    expiryMMYY: 'Das Ablaufdatum muss MM/JJJJ sein - z. 07/2023',
    invalidExpiry: 'Ungültiger Ablaufmonat',
    invalidMonth: 'Ungültiges Ablaufjahr',
    cvvReq: 'CVV-Karte ist erforderlich',
    cvvNum3Digit: 'Karten-CVV muss numerisch und 3 Ziffern lang sein',
    specialCharNotAllowed:'Sonderzeichen sind nicht erlaubt',
    onlySinglespaceAllowed:'Einzelne Leerzeichen sind nur zulässig',
    invalidPhoneFormat:'Ungültiges Rufnummernformat'


  },

  settings: {
    profileInfo: 'Profil Information',
    updateProf: 'Profil aktualisieren',
    payMethod: 'Payment Method',
    addCredit: 'Bezahlverfahren',
    cardNo: 'Kartennummer',
    invoiceHistory: 'Rechnungsverlauf',
    invoiceId: 'Invoice_Id',
    dueDate: 'Geburtstermin',
    paidAt: 'Paid_At',
    recure: 'Wiederkehrend',
    total: 'Gesamt',
    status: 'Status',
    active: 'Handlung',
    cardId: 'Ausweiskarte',
    type: 'Typ',
    expiryMonth: 'Ablaufmonat',
    expiry: 'Ablaufjahr',
    action: 'Handlung',
    addCard: 'Karte hinzufügen',
    settings: 'Einstellungen',
    accountSetting: 'Account Einstellungen',
    payment: 'Zahlung',
    primaryPayment: 'Diese Karte als primäre Zahlungsquelle festlegen',
    successTitle: "Erfolg!!!",
    successMsg:"Änderung an Rechnungsinformationen erfolgreich aktualisiert",
    NoSuccessTitle: "Keine Änderungen festgestellt!!!",
    NoSuccessMsg:"Es wurden keine Änderungen an den vorhandenen Rechnungsinformationen gefunden."
  },

  dataRequest: {
    yourDataRequest: 'Ihre Datenanfragen',
    remain: 'Verblieben',
    complete: 'Vollendet',
    Fetching: 'Holen',
    Fetched: 'Abgeholt',
    appForKeyword: ' Apps für Schlüsselwort - ',
    keywordsForApp: ' Schlüsselwörter für App - ',
    appsForCategory: ' Top-Apps für Kategorie - ',
    competitorsForApp: ' Vorgeschlagene Konkurrenten für App - ',
  },

  users: {
    manageComp: 'Unternehmen verwalten',
    comp: 'Unternehmen',
    addDisManageComp: 'Alle Unternehmen hinzufügen, deaktivieren, verwalten',
    createCompAdmin: 'Neuen Unternehmensadministrator erstellen',
    enterEmail: 'Email eingeben',
    allCompAdmin: 'Alle Firmen Admin',
    rowPerPages:'Schlüsselwörter'
  },

  manageSubscription: {
    trialPeriod: "Sie befinden sich in der Probezeit",
    trialPeriodExpire: "Ihre Testversion läuft am ab",
    updateCard: "Aktualisieren Sie Ihre Karte",
    subscriptionDetail: "Abonnementdetails",
    p1: "Nächste Abrechnung an",
    p2: "Hier können Sie die Rechnungsadresse, den Zahlungsverlauf und andere Details verwalten",
    planListing: 'Auflistung planen',
    furtherPlan: 'Plan auswählen, um fortzufahren',
    generalPlan: 'ALLGEMEINES',
    month: 'Monat',
    tryIt: 'Probieren Sie es aus, solange Sie möchten',
    createCampaign: 'Kampagne erstellen',
    keyAnalysis: 'Keyword-Analyse',
    automationRule: 'Automatisierungsregeln',
    keyOpt: 'Keyword-Optimierung',
  },

  billingDetails: {
    billInfo: 'Abrechnungsdaten',
    compName: 'Name der Firma',
    addLine: 'Adresszeile',
    city: 'Stadt',
    zip: 'Reißverschluss',
    vat: 'MwSt Anzahl',
    updateBillAdd: 'Rechnungsadresse aktualisieren'
  },

  dateRangePresets: {
    today: 'Heute',
    yesterday: 'Gestern',
    weekToDate: 'Woche bis heute',
    lastWeek:'Letzte Woche',
    lastSevenDays:'Letzten 7 Tage',
    monthToDate:'Diesen Monat',
    lastMonth:'Im vergangenen Monat',
    lastThirtyDays:'Letzte 30 Tage'
  },
  chartHeaders: {
    accounts: 'Konten',
    apps: 'Anwendungen',
    campaign: 'Kampagnen',
    adgroup: 'Anzeigengruppen',
    keyword: 'Schlüsselwörter',
    creativeset: 'Kreativsets',
    searchterm: 'Suchbegriffe',
  },
  
  goals: {
    selectGoal: 'Wählen Sie Ziel aus',
    selectEventType: 'Wählen Sie den Ereignistyp aus',
    selectGoalRule: 'Bitte Zieltyp auswählen',
    selectEventRule: 'Bitte Veranstaltungstyp auswählen',
    goalNameRule: 'Bitte geben Sie den Namen des Ziels ein',
    manageGoals: 'Ziele verwalten',
    goalDesc: 'Von hier aus können Sie Ihre Ziele verwalten',
    createGoal: 'Ziel erstellen',
    editGoal: 'Ziel bearbeiten',
    chooseGoal: 'Wählen Sie einen Zieltyp aus',
    chooseEvent: 'Ausgewählte Ereignisse)',
    goalName: 'Name des Ziels',
    close: 'Nah dran',
    update: 'Aktualisieren',
    create: 'Schaffen',
    name: 'Name',
    app: 'App',
    appId: 'App-ID',
    type: "Typ",
    events: "Veranstaltungen",
    createdOn: "Erstellt am",
    createdBy: "Erstellt von",
    action: "Aktion",
    revenue: "Einnahmen",
    purchase: "Kaufen",
    subscribe: "Abonnieren",
    userEngagement: "Benutzerbindung",
    levelComplete: "Level geschafft",
    regComp: "Registrierung abgeschlossen",
    tutComp: "Tutorial abgeschlossen",
    startTrial: "Testversion starten",
    appLaunch: "App-Start",
    userAction: "Benutzeraktion",
    addToCart: "in den Warenkorb legen",
    addToWishlist: "Zur Wunschliste hinzufügen",
    checkoutStart: "Kasse starten"
  },

  campaignGenerator: {
    decisionMessage: 'Möchten Sie die Vorlage wirklich löschen?',
    addKeyPlaceholder: 'Geben Sie durch Kommas getrennten Text ein und drücken Sie die Eingabetaste oder klicken Sie auf Hinzufügen',
    showDraft: 'Entwurf anzeigen',
    pageHeading : 'Kampagnengenerator',
    createCampaignHeading: 'Configuración de campaña automatizada',
    Segmentation: 'Segmentierung',
    Categories: 'Kategorien',
    Brand: 'Marke',
    Category: 'Kategorie',
    Discovery: 'Entdeckung',
    Competitor: 'Wettbewerber',
    Custom: 'Benutzerdefiniert',
    Criteria: 'Kriterien',
    pleaseSelectCriteria: 'Bitte Kriterien auswählen',
    pleaseEnterValue: 'Bitte Wert eingeben',
    Value: 'Wert',
    To: 'An',
    From: 'Von',
    FromTo10: 'Bitte geben Sie einen Wert mit einer Differenz von mehr als 10 vom ersten Wert ein',
    customSlugHeading: 'Benutzerdefinierter Kampagnen-Slug:',
    customSlugPH: 'Geben Sie einen Slug-Namen ein',
    customSlugAdd: 'Hinzufügen',
    customSlugDup: 'Doppelter Slug-Name',
    SegmentationRules: 'Segmentierungsregeln',
    SegmentationDesc: 'Kampagnen haben Budgets und Anzeigengruppen, die sich auf ein gemeinsames Thema oder eine gemeinsame Strategie zur Bewerbung Ihrer App konzentrieren.',
    age: 'Alter',
    geo: 'Standort',
    selectCustomerType: 'Bitte Kundentyp auswählen',
    selectGender: 'Bitte Geschlecht auswählen',
    selectAge: 'Bitte Alter auswählen',
    keyManually: 'Stichworte manuell hinzufügen',
    Summary: 'Zusammenfassung',
    SummaryText: 'Zusammenfassung von Kampagnen und Anzeigengruppen prüfen',
    TotalCampaigns: 'Kampagnen insgesamt',
    TotalAdgroups: 'Adgroups insgesamt',
    CampaignTitle: 'Kampagnentitel',
    keysReady: 'Stichworte sind fertig',
    custom: 'benutzerdefiniert',
    keysFetch: 'Schlüsselwörter abrufen für',
    Campaignsarecreated: 'Kampagnen werden erstellt',
    Creatingcampaigns: 'Kampagnen erstellen',
    Viewallitems: 'Alle Artikel anzeigen',
    Adgroupsarecreated: 'Anzeigengruppen wurden erstellt',
    Creatingadgroups: 'Anzeigengruppen erstellen',
    Keywordsarecreated: 'Stichworte werden erstellt',
    Creatingkeywords: 'Stichworte erstellen',
    ready: 'fertig',
    CreatedBy: 'Erstellt von',
    CreatedOn: 'Erstellt am',
    Campaigngenerationdetails: 'Details zur Kampagnengenerierung',
    footerProps: {
      itemsPerPageText: 'Zeilen pro Seite',
      itemsPerPageAllText: 'Alle',
      showFirstLastPage: true,
    },
    generateCampaigns: 'Kampagnen generieren',
    setDefaultValues: 'Standardwerte setzen',
    setDefaultValuesSub: 'Bitte geben Sie Standard-Budgeteinstellungen für die Zielstandorte an.',
    nokeyPara: 'Für Kategorie-, Entdeckungs- oder Mitbewerberkampagnen ist keine Schlüsselworteingabe erforderlich, da Schlüsselwörter automatisch mit unserer Schlüsselwortanalysetechnologie generiert werden.',
    waitingToSync: 'Warten auf Synchronisierung',
    campSyncHour:'Kampagnen werden innerhalb einer Stunde mit Apple synchronisiert.',
    //syncInProgress: 'Synchronisierung läuft',
    syncInProgress: 'Synchronisieren',
    syncInProgressFewMin: 'Die Synchronisierung läuft und sollte innerhalb weniger Minuten abgeschlossen sein.',
    syncComplete: 'Synchronisierung abgeschlossen',
    allSyncCompleted: 'Alle Kampagnen wurden erfolgreich synchronisiert.',
    campNameUniq: 'Bitte geben Sie eindeutige Kampagnennamen ein',
    campNamExists: 'Eine Kampagne mit demselben Namen existiert bereits',
    adNameUniq: 'Bitte geben Sie eindeutige Anzeigengruppennamen ein',
    saveAsDraft: 'Als Entwurf speichern',
    draftHeading: 'Entwurf einer Kampagnenvorlage',
    draftSearch: 'Suche',
    headerOrgName: 'Organisationsname',
    headerAppName: 'App Name',
    headerResume: 'Fortsetzen'
  },

  appSetting: {
    manage: 'Verwalten',
    setDefaultValues: 'Standardwerte festlegen',
    edit: 'Bearbeiten',
    update:'Aktualisieren',
    noRecords: 'Keine übereinstimmenden Aufzeichnungen gefunden',
    searchByCountry:'Suche nach Land'
  },

  sr: {
    pageHeading: 'Geplante Berichte',
    createScheduleReport: 'Geplanten Bericht erstellen',
    srDesc: 'Mit geplanten Berichten können Sie Berichte zu Ihrer eigenen definierten Zeit und an Ihrem eigenen Tag versenden oder sperren',
    createSR: 'Neuen Bericht planen',
    for: 'Bericht für',
    type: 'Wiederholen',
    dataDays: 'Daten für zuletzt',
    reportNameExists: 'Name existiert bereits!',
    enterReportName: 'Geben Sie einen Namen ein',
    repeatMode: 'Wiederholungstyp',
    pleaseSelectFor: 'Bitte wählen Sie eine Berichtsebene aus',
    pleaseSelectType: 'Bitte wählen Sie einen Wiederholungstyp',
    pleaseSelectDay: 'Bitte Tag(e) auswählen',
    pleaseSelectTime: 'Bitte wählen Sie eine Zeit',
    noRepeat: 'Nicht wiederholen',
    custom: 'Benutzerdefiniert',
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    monthly: 'Monatlich',
    selectOrg: 'Bitte wählen Sie eine Organisation aus',
    selectGoal: 'Bitte wählen Sie ein Ziel aus',
    goalsSelected: 'Ziele ausgewählt',
    orgsSelected: 'Ausgewählte Organisationen',
    scheduleReport: 'Geplanter Bericht?',
    scheduleReports:'Berichte planen',
    reportOf:'Bericht von',
    selectTime:'Zeit auswählen',
    downloadReport:'Bericht herunterladen',
    addMore:'Mehr hinzufügen',
    scheduleReport1:'Zeitplanbericht',
    selectSlackChannel:'Wählen Sie Slack-Channel aus',
    emailNoSpaces:'E-Mail darf keine Leerzeichen enthalten',
    emailWarning:'Bitte wählen Sie mindestens eine E-Mail aus oder geben Sie unten mindestens eine externe E-Mail-ID ein',
    errorDuringSave:'Fehler beim Speichern des geplanten Berichts',
    scheduleSuccess:'Erfolgreich eingeplant',
    scheduleSaveWarning:'Möchten Sie diesen Bericht wirklich planen?',
   },
   //Delete Ad
  deleteAds: {
    deleteAd: 'Anzeige löschen',
    para: 'Möchten Sie die Anzeige wirklich löschen?',
    cancel: 'Absagen',
    delete: 'Löschen',
    ok:'OK'
  },
  //EditAds
  editAd: {
    editAdWizard: 'Anzeigenbearbeitungsassistent',
    adName: 'Anzeigenname',
    cancel: 'Absagen',
    save: 'Speichern'
  },
  createAdRules: {
    adRequired: 'Anzeigenname erforderlich!',
    adNameSize: 'Ihr Anzeigenname muss weniger als 200 Zeichen lang sein'
},
  common : {
    save: 'Speichern',
    cancel: 'Abbrechen',
    noDataText: 'Keine Daten zum Anzeigen',
    days: 'Tag(e)',
    time: 'Zeit',
    na: 'NA',
    goal: 'Ziel(e)',
    deleteConfirmText: 'Möchtest du das wirklich löschen ',
    createConfirmText: 'Fahren Sie fort, dieses neue zu erstellen ',
    updateConfirmText: 'Möchtest du das wirklich aktualisieren ',
    dataTableFooterProps: {
      itemsPerPageText: 'Zeilen pro Seite',
      itemsPerPageAllText: 'Alle',
      showFirstLastPage: true,
    },
  },
  welcomeOnboard: {
    yes: 'Jawohl',
    no: 'nein',
    continue: 'Kontinuierlich',
    welcome: 'WILLKOMMEN BEI SEARCH ADS MAVEN',
    description: 'Willkommen bei Search Ads Maven. Beginnen wir mit der Einrichtung Ihrer Integration. Sie benötigen Ihre Apple-ID und wenn Ihr ASA die Zwei-Faktor-Authentifizierung verwendet, benötigen Sie das zugehörige Gerät.',
    getStart: 'Fangen Sie an',
    setupLater: 'Gehen Sie später zu Anwendung und Setup',
    setup: 'Konfiguration',
    createIntegration: 'Integration schaffen',
    createIntDescription: 'Geben Sie Ihre Apple-ID ein, um die bidirektionale Synchronisierung zwischen Search Ads Maven und Apple Search Ads zu erstellen. Wenn Sie einen MMP-Partner haben, können diese Integrationen auch hier eingerichtet werden. Beginnen wir mit dem Import Ihrer Apple Search Ads-Daten"',
    enterAppleId: 'INTEGRIERE DEINE APPLE-ID',
    success: 'Erfolg',
    stepper: {
      title: 'Synchronisierung läuft!',
      descreption1: 'Wir importieren Ihre Verlaufsdaten aus Apple Search Ads. Je nach Datenmenge kann dies einige Stunden dauern. Sie können die Fortschrittsanzeige oben rechts jederzeit während dieser Synchronisierung überprüfen und nach Abschluss der Synchronisierung die Seite „Integrationen“ besuchen.',   
      descreption2: 'Machen wir uns in der Zwischenzeit mit der Plattform vertraut.',
    },

    stepperTwo: {      
      title: 'MMP-Integration',
      descreption:'MMPs sind Messtools von Drittanbietern, mit denen Sie die Effektivität Ihrer Werbeausgaben über viele verschiedene Marketingkanäle und Partner hinweg anhand von Full-Funnel-App-Engagement-Daten messen und vergleichen können. Die größte MMP-Funktion, die uns hier helfen wird, ist die Analyse und Optimierung unserer ASA-Leistung anhand von Ereignissen, die eintreten, nachdem der Kunde den App Store verlassen und mit der Nutzung der App begonnen hat.',
      successfullIntegrations:'Erfolgreiche Integrationen',
      receivedTypes: 'Empfangene Ereignistypen '
      },
    startGuide: 'Startanleitung',
    goToApplication: 'Gehen Sie zur Anwendung',
    overview: {
      title: 'Willkommen auf der Übersichtsseite von Search Ad Maven!',
      description: 'Hier beginnen Sie jedes Mal, wenn Sie sich auf der Plattform anmelden. Hier finden Sie einen allgemeinen Überblick über Ihre aktuellen Kampagnen, zugehörige Metriken und mehr. Sie können diese Ansicht anpassen, um anzuzeigen, was am hilfreichsten ist und als schnelle Referenz für alle Ihre ASA-Aktivitäten dient',


      popupSecond: {
        title: 'Thank you for quickly exploring Search Ads Maven with us',
        description: ' If you have any remaining questions or problems with integration, please reach out to your Search Ads Maven support team by emailing ' 
      }
    },
    adsManager: {
      title: 'Vielen Dank, dass Sie Search Ads Maven schnell mit uns erkundet haben',
      description: 'Hier können Sie detaillierte Analysen und Berichte für Ihre Anwendungen, Kampagnen, Anzeigengruppen, Keywords und mehr anzeigen. Erstellen Sie Kampagnen auch manuell, um das Rätselraten aus dem Prozess zu nehmen.  Im Bereich Werbeanzeigenmanager hast du auch die Möglichkeit, Kampagnen, Anzeigengruppen, Keywords und auszuschließende Keywords zu erstellen.'
    },
    keywordAnalysis: {
      title: 'Dies ist die Registerkarte Keyword-Analyse',
      description: 'Hier können Sie die Gesamteffektivität Ihrer Kampagnen-Keywords messen und vorgeschlagene Konkurrenten für Ihre App sehen und die Keywords Ihrer Konkurrenten erobern. Sie können Ihrer Kampagne auch schnell zusätzliche Keywords hinzufügen'
    },
    keywordOptimization:{
      title: 'Dies ist die Registerkarte zur Keyword-Optimierung',
      description: 'Hier können Sie schnell und effektiv Ihre Gebotsbeträge für Keywords optimieren, die nicht im Bereich der von ASA empfohlenen Mindest- und Höchstgebote liegen. Finden Sie Zielmetriken basierend auf den Ereignissen Ihrer App, die neben den ASA-Empfehlungen enthalten sind'
    },
    integrations: {
      title: 'Dies ist die Integrationsseite',
      description: 'In die Integration können Sie das ASA-, das Attribution-Konto und das Slack-Konto integrieren.'
    },
    automation: {
      title: 'Dies ist die Automatisierungsseite',
      description: 'Hier können Sie Automatisierungsregeln für Ihre Organisationen, Anwendungen, Kampagnen, Anzeigengruppen und Schlüsselwörter erstellen'
    },
    goals: {
      title: 'Dies ist die Seite zum Erstellen von Zielen',
      description: 'Hier können Sie App-basierte Kampagnenziele rund um Umsatzmetriken, Benutzerengagement und Benutzeraktionsereignisse erstellen'
    },
    campaignGenerator: {
      title: ' Dies ist die Registerkarte des Kampagnengenerators',
      description: 'Hier können Sie ASA-Kampagnen entweder manuell erstellen oder den automatischen Kampagnengenerator von Search Ads Maven verwenden. Legen Sie Ihre Kampagnenbudgets fest und passen Sie Ihre Kampagne an Ihre Vorgaben an'
    },
    strategicView: {
      title: 'Willkommen bei der Strategischen Ansicht',
      description: 'Erobern Sie die Komplexität von Apple Search Ads mit Search Ads Maven Strategies. Gruppieren Sie Kampagnen, Anzeigengruppen und Keywords in gestraffte Einheiten, unterstützt durch Automatisierung und klare Meilensteine. Bauen Sie, führen Sie aus und messen Sie den Erfolg – alles in Ihrer Reichweite. Vereinfachen, optimieren, gewinnen.'
    },
    thirdParty: {
      popupOne: {
        title: 'Möchten Sie Ihre Veranstaltungsdaten von Drittanbietern integrieren?',
        description: ''
      },
      popupTwo: {
        title: '',
        description: 'Ereignisdaten von Drittanbietern können erst importiert werden, nachdem Ihre ASA-Synchronisierung abgeschlossen ist. Bitte kehren Sie zu dieser Seite zurück, wenn Ihre Synchronisierung 100 % erreicht hat.'
      },
      popupThree: {
        title: '',
        description: 'Es wird einige Zeit dauern, bis Ihre ASA-Informationen in Search Ads Maven ausgefüllt sind. Machen Sie sich in der Zwischenzeit mit der Plattform vertraut'
      },    
    }
  }



}
